import srchlogo from "../../assets/images/ic-search.png";
// import profile from "../../assets/images/author2.jpg";
// import checkimg from "../../assets/images/icons-16-check.png";
import moredots from "../../assets/images/ic-more.png";
import sentBtn from "../../assets/images/ic-send.png";
import { useEffect, useRef, useState } from "react";
// import { IoCloseOutline } from "react-icons/io5";
import Loader2 from "../Loader/Loader2";
import { MReq_MessageSend } from "../../utils/managerequest/ReqMesssageSend";
import {
  GetMessage,
  ManageRequestDeleteChat,
} from "../../utils/managerequest/GetMessage";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../Loader/Loader";
import {
  Get_ManageRequest,
  Set_Chat_Archive,
} from "../../utils/managerequest/GetManageRequest";
import { Link } from "react-router-dom";
import { BsCheck, BsCheckAll } from "react-icons/bs";
import { IoArrowBack, IoCloseOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import DeleteChatWarningModal from "../Common/DeleteChatWarningModal";
import userProfile from "../../assets/images/user avatar.png";

const ManageChatSection = ({
  getMesage,
  setGetMessage,
  messageListInfo,
  setmessageListInfo,
  tabValue,
  requestData,
  loading,
  chatSection,
  setChatSection,
  setRequestData,
  searchValue,
  setSearchValue,
  dateFilterValue,
  setDateFilterValue,
  userId,
  setUserId,
  baseUrls,
  isArchiveChecked,
  GetManageRequest,
}) => {
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchError, setSearchError] = useState("");
  const [chatLoading, setChatLoading] = useState(false);
  const [msgBtnLoading, setMsgBtnLoading] = useState(false);
  const [goBackChat, setGoBackChat] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [warnigChatMdl, setWarninChatMdl] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const chatContainerRef = useRef(null);

  const handleMessageSend = async (e, id, baseUrls) => {
    e.preventDefault();
    setMsgBtnLoading(true);
    if (message?.length >= 1) {
      setErrorMessage("");
      try {
        const msg = {
          message: message,
        };
        const msg_responce = await MReq_MessageSend(id, msg);
        setMessage("");
        const updateMsg = await GetMessage(id);
        setGetMessage(updateMsg?.data?.data?.send);
      } catch (error) {
        console.log(error);
      } finally {
        setMsgBtnLoading(false);
      }
    } else {
      setErrorMessage("Message must be at least 1 characters long.");
      setMsgBtnLoading(false);
    }
  };

  const Get_RequestData = async () => {
    const checkIsArchive = isArchiveChecked ? 1 : 0;
    try {
      const GetRequestData = await Get_ManageRequest(
        tabValue,
        searchValue,
        checkIsArchive
      );
      if (tabValue == "Host") {
        setRequestData(GetRequestData?.data?.data);
      } else {
        setRequestData(GetRequestData?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterMsg = async () => {
    if (userId) {
      try {
        setChatLoading(true);
        const getMsg = await GetMessage(userId, dateFilterValue);
        // console.log("gttt",getMsg)
        setGetMessage(getMsg?.data?.data?.send);
      } catch (error) {
        console.log(error);
      } finally {
        setChatLoading(false);
      }
    }
  };

  useEffect(() => {
    filterMsg();
  }, [dateFilterValue]);

  const GetMsg = async (id, info) => {
    setUserId(id);
    setChatLoading(true);
    setmessageListInfo(info);
    setChatSection(true);
    try {
      const getMsg = await GetMessage(id, dateFilterValue);
      setGetMessage(getMsg?.data?.data?.send);
    } catch (error) {
      console.log(error);
    } finally {
      setChatLoading(false);
    }
    //List Api
    Get_RequestData();
  };
  // dateFilterValue

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight -
        chatContainerRef.current.clientHeight;
    }
  }, [getMesage]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchValue || !searchValue.trim() === "") {
      setSearchError("Invalid search input");
    } else {
      await Get_RequestData();
      setSearchError("");
      setSearchValue("");
    }
  };

  const handleArchive = async (is_archive) => {
    const fdata = {
      id: messageListInfo?.id,
      is_archive: is_archive,
    };
    try {
      const response = await Set_Chat_Archive(fdata);
      console.log("reee", response);
      setIsOpen(false);
      GetManageRequest();
    } catch (err) {
      console.log("errr", err);
    }
  };

  const HandleChatDelete = async () => {
    try {
      const response = await ManageRequestDeleteChat(messageListInfo?.id);
      setWarninChatMdl(false);
      if (response?.data?.success) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        });
        GetManageRequest();
        setIsOpen(false);
      }
      console.log("response", response?.data?.message);
    } catch (err) {
      console.log(err);
    }
  };

  const userProfileImageError = (event) => {
    event.target.src = userProfile;
    event.target.onError = null;
  };

  return (
    <>
      <ToastContainer />
      {warnigChatMdl && (
        <DeleteChatWarningModal
          msg={"Do You Want to Delete This Chat"}
          handleDeleteChat={HandleChatDelete}
          setDeleteChatMdl={setWarninChatMdl}
        />
      )}
      {goBackChat && (
        <button
          className="back-btn d-md-none"
          onClick={() => setGoBackChat(false)}>
          <IoArrowBack /> Back
        </button>
      )}

      <div className="row d-flex mt-2">
        <div className={`${goBackChat ? "d-none d-md-block" : "d-block"
            } d-md-block col-12 col-md-5 col-xl-4 p-0`}>
          <div className="chat-list ">
            <div className="chat-field ">
              <div className="pb-3">
                <form onSubmit={handleSearch}>
                  <img src={srchlogo} className="ic_search" />
                  <input
                    type="search"
                    placeholder="Search..."
                    className="input-chat-list"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </form>
                {searchError && (
                  <p className="error-text text-danger ms-3">{searchError}</p>
                )}
              </div>
            </div>
            {loading ? (
              <Loader2 />
            ) : (
              <>
                {requestData?.length <= 0 || requestData == undefined ? (
                  <p className="text-center mt-2 error-text">
                    Request Data Not Found
                  </p>
                ) : (
                  <>
                    {requestData?.map((val) => (
                      <>
                        <div
                          className={
                            messageListInfo?.id == val.id
                              ? "active-user-list mt-0"
                              : "user-list mt-0"
                          }
                          onClick={() => {
                            GetMsg(val?.id, val);
                            setGoBackChat(true);
                          }}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  val?.users?.image_type == "browse"
                                    ? `${baseUrls?.customer_image_url}/${val?.users?.image}`
                                    : `${baseUrls?.avatar_image_url}/${val?.users?.predefine_image}`
                                  // ? `https://ringus.idea2reality.tech/storage/app/public/profile/${val?.users?.image}`
                                  // : `https://ringus.idea2reality.tech/storage/app/public/avatar/${val?.users?.predefine_image}`
                                }
                                className="user-img"
                                onError={userProfileImageError}
                              />
                              <div>
                                <span className="userName">
                                  {val?.users?.f_name} {val?.users?.l_name}
                                </span>

                                <p className="manage-request-p m-0">
                                  {val?.events?.event_title}
                                </p>

                                {/* <p className="user-msg">{val?.event_package?.amount}</p> */}
                                <p className="manage-request-p m-0">
                                  {val?.event_package?.title}
                                </p>
                              </div>
                            </div>

                            <div className="mt-auto">
                              {val?.id == messageListInfo.id ? (
                                ""
                              ) : (
                                <>
                                  {val?.unread_counts > 0 && (
                                    <div className="msg-count">
                                      <span className="msg-text-count">
                                        {val?.unread_counts}
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                              <div>
                                <div className="text-end">
                                  <i className="Timestamp">
                                    {moment(val?.created_at).format(
                                      "DD MMM hh:mm A"
                                    )}
                                  </i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className={`${goBackChat ? "d-block" : "d-none d-md-block"} col-md-7 col-lg-7 col-xl-8 p-0`}>
          {chatLoading ? (
            <div className="ms-1 d-flex justify-content-center">
              <Loader2 />
            </div>
          ) : (
            <>
              <>
                <div className="chat-view">
                  <div className="single-user-head d-flex justify-content-between">
                    <div className="d-flex">
                      <img
                        src={
                          messageListInfo?.users?.image_type == "browse"
                            ? `${baseUrls?.customer_image_url}/${messageListInfo?.users?.image}`
                            : `${baseUrls?.avatar_image_url}/${messageListInfo?.users?.predefine_image}`
                        }
                        onError={userProfileImageError}
                        className="user-img"/>

                      <div className="d-flex gap-4">
                        <div>
                          <span className="chat-view-user">
                            {messageListInfo?.users?.f_name} {messageListInfo?.users?.l_name}
                          </span>
                          <p className="d-flex align-items-center text-dark gap-1 last-seen">
                            <Link
                              className="text-dark"
                              to={`/eventdetail/${messageListInfo?.events?.id}`}
                              state={{ type: "ManageRequest" }}
                            >
                              {messageListInfo?.events?.event_title}
                            </Link>
                            <span>{`(${messageListInfo?.event_package?.title} INR-${messageListInfo?.event_package?.amount} )`}</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center ">
                      <div className="position-relative">
                        <button
                          className="chat-close-btn"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <img src={moredots} className="moredots" />
                        </button>
                        <div
                          className={`dropdown-content ${isOpen ? "open" : "closed"
                            }`}
                        >
                          {messageListInfo?.archive ? (
                            <button
                              className="list-drop-button"
                              onClick={() => {
                                handleArchive(0);
                              }}
                            >
                              UnArchive
                            </button>
                          ) : (
                            <button
                              className="list-drop-button"
                              onClick={() => {
                                handleArchive(1);
                              }}
                            >
                              Archive
                            </button>
                          )}
                          <button
                            className="list-drop-button"
                            onClick={() => setWarninChatMdl(!warnigChatMdl)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {getMesage?.length <= 0 || getMesage == undefined ? (
                    <p className="text-center mt-5 error-text">
                      Message Not Found
                    </p>
                  ) : (
                    getMesage &&
                    getMesage?.map((val) => {
                      return (
                        <>
                          <div
                            className="chat-message-section"
                            ref={chatContainerRef}
                          >
                            <div className="msger-chat">
                              <div className="d-flex flex-column">
                                {/* <div
                                  className={
                                    tabValue == "Host" || "Champ"
                                      ? "msg left-msg mt-4"
                                      : "msg right-msg mt-4" 
                                      >*/}
                                <div
                                  className={
                                    tabValue === "Host"
                                      ? "msg left-msg mt-4"
                                      : tabValue === "Sponsor"
                                        ? "msg right-msg mt-4"
                                        : tabValue === "Champ"
                                          ? "msg champ-msg mt-4"
                                          : ""
                                  }
                                >
                                  <div className="msg-bubble-left">
                                    <div>
                                      <div className="msg-text-left">
                                        {val?.discription}
                                      </div>
                                    </div>
                                    <div className="msg-info-time-left">
                                      <i className="">
                                        {moment(val?.created_at).format(
                                          "DD MMM hh:mm A"
                                        )}
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {val?.request_conversation?.map((msg) => {
                                return (
                                  <>
                                    {/* {console.log("prid",profileinfo?.id)} */}
                                    <div className="d-flex flex-column">
                                      <div
                                        className={
                                          profileinfo?.id == msg?.sender_id ||
                                            (tabValue != "Sponsor" &&
                                              val?.reply_by_champ_id ==
                                              msg?.sender_id) ||
                                            (tabValue != "Sponsor" &&
                                              val?.host_id == msg?.sender_id)
                                            ? "msg right-msg mt-4"
                                            : "msg left-msg mt-4"
                                        }
                                      >
                                        <div className="msg-bubble-left">
                                          <div>
                                            <div className="msg-text-left">
                                              {msg?.message}
                                            </div>
                                          </div>

                                          <div className="d-flex gap-2">
                                            {(profileinfo?.id ==
                                              msg?.sender_id &&
                                              tabValue != "Sponsor") ||
                                              (tabValue != "Sponsor" &&
                                                val?.reply_by_champ_id ==
                                                msg?.sender_id) ||
                                              (tabValue != "Sponsor" &&
                                                val?.host_id ==
                                                msg?.sender_id) ? (
                                              <div className="d-flex gap-1 msg-info-time-left">
                                                <div>
                                                  {msg?.is_read ? (
                                                    <BsCheckAll
                                                      color="#2e73b9"
                                                      size={18}
                                                    />
                                                  ) : (
                                                    <BsCheck size={18} />
                                                  )}
                                                </div>
                                                <i className="">
                                                  By:{msg?.users?.f_name}
                                                </i>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {/* {
                                           tabValue != "Sponsor" && msg?.reply_by_champ_id ? 
                                              <div className="msg-info-time-left">
                                                <i className="">by champ</i>
                                              </div>
                                              :
                                             tabValue != "Sponsor" && val?.host_id == msg?.sender_id ?
                                              <div className="msg-info-time-left">
                                              <i className="">by host</i>
                                            </div>                                           
                                              :""
                                          } */}
                                            {/* <i className="">{moment(msg?.created_at).format('DD MMM hh:mm A')}</i> */}

                                            <div className="msg-info-time-left">
                                              <i className="">
                                                {moment(msg?.created_at).format(
                                                  "DD MMM hh:mm A"
                                                )}
                                              </i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>

                          <div className="type-msg-bar">
                            {/* <div className="hr-line"></div> */}
                            <div className="msg-input-btn d-flex flex-column justify-content-center">
                              <form
                                onSubmit={(e) =>
                                  handleMessageSend(e, messageListInfo?.id)
                                }
                              >
                                <div className="p-2 d-flex gap-2 align-items-center">
                                  <textarea
                                    type="text"
                                    className="send-msg-input"
                                    placeholder="Send A Message"
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    rows="5"
                                    cols="50"
                                    style={{ resize: "none" }}
                                  />
                                  {msgBtnLoading ? (
                                    <button className="input-sent-button">
                                      <div
                                        className=" spinner-border text-light"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button className="msg-sent-button" type="submit">
                                      <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor"
                                              d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                            ></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <span>Send</span>
                                    </button>
                                    // <button
                                    //   className="input-sent-button"
                                    //   type="submit">
                                    //   <img src={sentBtn} className="send-btn" />
                                    // </button>
                                  )}
                                </div>
                                <p className="error-text ps-2">
                                  {errorMessage}
                                </p>
                              </form>
                            </div>
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ManageChatSection;
