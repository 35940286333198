import { useState, useEffect } from "react";
import preview from "../../assets/images/preview.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PastEventBasicInformation from "../../components/CreatePastEvent/PastEventBasicInformation";
import PastEventPitch from "../../components/CreatePastEvent/PastEventPitch";
import PastEventOrganizer from "../../components/CreatePastEvent/PastEventOrganizer";
import PastEventEssentials from "../../components/CreatePastEvent/PastEventEssentials";
import PastEventGuestForm from "../../components/CreatePastEvent/PastEventGuestForm";
import PastEventPitchForm from "../../components/CreatePastEvent/PastEventPitchForm";
import PastEventHost from "../../components/CreatePastEvent/PastEventHost";
import PastEventPartnerShip from "../../components/CreatePastEvent/PastEventPartnerShip";
import PastEventSponsorPackage from "../../components/CreatePastEvent/PastEventSponsorPackage";
import PastEventSocialMediaHub from "../../components/CreatePastEvent/PastEventSocialHub";
import PastEventMediaHub from "../../components/CreatePastEvent/PastEventMediaHub";
import { GetPastEventEssentials } from "../../utils/createPastEventForm/createpasteventform";
import { RiArrowRightSFill } from "react-icons/ri";
import { TiArrowLeft } from "react-icons/ti";

const CreatePastEvent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState("Event-Essentials");
    const [eventData, setEventData] = useState({});
    const [EditData, setEditData] = useState({})
    const params = useParams();
    const Eid = params?.id;
    const [isEdit, setIsEdit] = useState(false);

    const Messages = (val) => {
        toast.success(val, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
        });
    };

    useEffect(() => {
        if (Eid == "create") {
            setIsEdit(false)
        } else {
            // console.log("check")
            // EditEvents()
            setIsEdit(true);
        }
    }, [tabValue]);

    const tabNames = {
        "Event-Essentials": "Event Essentials",
        "Event-Guest": "Event Guests",
        "Event-Pitch": "Event Pitch",
        "Event-Host": "Event Host",
        "Event-Partnerships": "Event Partnerships",
        "Sponsor-Packages": "Sponsor Packages",
        "coupons": "Coupons",
        "Event-Media-Hub": "Event Media Hub",
        "Social-Hub": "Social Hub",
        "Cancellation-Refund": "Cancellation / Refund",
        "References": "References",
      };

    return (
        <>
            <ToastContainer />
            <div className="newevent container fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 d-flex align-items-center gap-1">
                     <h5 className="create-event-heading m-0">Create Past Event</h5> 
                       <RiArrowRightSFill size={20}/>
                     <h5 className="create-event-heading m-0">{tabNames[tabValue]}</h5>
                    </div>
                    <div className="col-12 col-lg-6 mt-2 mt-lg-0">
                        <div className="d-flex justify-content-lg-end gap-2">
                            {Object.keys(eventData)?.length === 0 && eventData?.constructor === Object ?
                                ""
                                :
                                <button className="btn d-flex align-items-center gap-1 preview-btn px-0"
                                    onClick={() =>
                                   navigate(`/pasteventpreview/${eventData?.id}`, { state: "Create Past Event" })
                                    }>
                                    <img src={preview} alt="pre" />
                                    Preview
                                </button>
                            }
                            <button className="go-back-btn d-flex align-items-center ps-0" onClick={() => navigate(-1)} type="button">
                             <TiArrowLeft size={20}/> Go Back
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mb-5 mt-4">
                    <div className="col-lg-3 d-flex flex-column gap-2 create-main-tab">
                        <button
                            onClick={() => setTabValue("Event-Essentials")}
                            className={`${tabValue == "Event-Essentials"
                                ? "active-create-event-tab"
                                : "create-event-tab"} `}>
                            Event Essentials
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Guest")}
                            className={`${tabValue == "Event-Guest" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Event Guests
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Pitch")}
                            className={`${tabValue == "Event-Pitch" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Event Pitch
                        </button>
                        <button
                            onClick={() => setTabValue("Event-Host")}
                            className={`${tabValue == "Event-Host" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Event Host
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Partnerships")}
                            className={`${tabValue == "Event-Partnerships" ? "active-create-event-tab" : "create-event-tab"} `}>
                            Event Partnerships
                        </button>


                        <button
                            onClick={() => setTabValue("Sponsor-Packages")}
                            className={`${tabValue == "Sponsor-Packages" ? "active-create-event-tab" : "create-event-tab"} `}>
                            Sponsor Packages
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Media-Hub")}
                            className={`${tabValue == "Event-Media-Hub" ? "active-create-event-tab" : "create-event-tab"} `}>
                            Event Media Hub
                        </button>

                        <button
                            onClick={() => setTabValue("Social-Hub")}
                            className={`${tabValue == "Social-Hub" ? "active-create-event-tab" : "create-event-tab"} `}>
                            Social Hub
                        </button>
                    </div>

                    <div className="col-lg-9">
                        {tabValue == "Event-Essentials" ? (
                            <div className="">
                                <PastEventEssentials setEventData={setEventData} setTabValue={setTabValue} Messages={Messages} isEdit={isEdit} tabValue={tabValue} Eid={Eid}/>
                            </div>
                        ) : tabValue == "Event-Guest" ? (
                            <div className="">
                                <PastEventGuestForm event_id={eventData.id} setTabValue={setTabValue} Messages={Messages} isEdit={isEdit} tabValue={tabValue} Eid={Eid} />
                            </div>
                        ) : tabValue == "Event-Pitch" ? (
                            <div className="">
                                <PastEventPitchForm event_data={eventData} setTabValue={setTabValue} Messages={Messages} isEdit={isEdit} tabValue={tabValue} Eid={Eid} />
                            </div>
                        ) : tabValue == "Event-Host" ? (
                            <div className="">
                                <PastEventHost event_id={eventData.id} setTabValue={setTabValue} Messages={Messages} isEdit={isEdit} tabValue={tabValue} Eid={Eid} />
                            </div>
                        ) : tabValue == "Event-Partnerships" ? (
                            <div className="">
                                <PastEventPartnerShip event_data={eventData} setTabValue={setTabValue} Messages={Messages} isEdit={isEdit} tabValue={tabValue} Eid={Eid} />
                            </div>
                        ) : tabValue == "Sponsor-Packages" ? (
                            <div className="">
                                <PastEventSponsorPackage event_id={eventData.id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Event-Media-Hub" ? (
                            <div className="">
                                <PastEventMediaHub event_id={eventData.id} setTabValue={setTabValue} Messages={Messages} isEdit={isEdit} tabValue={tabValue} Eid={Eid} />
                            </div>
                        ) : tabValue == "Social-Hub" ? (
                            <div className="">
                                <PastEventSocialMediaHub event_id={eventData.id} setTabValue={setTabValue} Messages={Messages} isEdit={isEdit} tabValue={tabValue} Eid={Eid} />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div >
        </>
    );
};

export default CreatePastEvent;
