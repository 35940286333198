import { Create_Event_Schedule, DltConfirmedSponsor, Event_Host, Event_Media_Hub, Event_References, Event_Schedule_Image_Delete, Event_Schedule_Upload_Image, Event_Sponsor_Package, GetConfirmedSponsor, Get_Event_Budget, Get_Event_Schedule, Get_Service_provider, Get_Sponsor_Users, Get_Transfer_Users, Refund_Charge, UpdateConfirmedSponsor, Update_Sponsor_Package, createEventessentials, createEventguests, createEventpartnership, createEventpitch, createEventsocialmedialinks, get_guest_data, get_online_mode, get_type_eventessentials } from "../../Services/Apis"

// create event essentials form
export const CreateEventEssentials = async (item) => {
    try {
        return await createEventessentials(item).then((data) => data)
    } catch (err) {
        throw err
    }
}

// get online mode list
export const GetOnlineMode = async () => {
    try {
        return await get_online_mode().then((data) => data)
    } catch (err) {
        throw err
    }
}

// get type event essentials
export const GetTypeEventEssentials = async () => {
    try {
        return await get_type_eventessentials().then((data) => data)
    } catch (err) {
        throw err
    }
}


// guest tab 
// guest static list data
export const GetGuestData = async () => {
    try {
        return await get_guest_data().then((data) => data)
    } catch (err) {
        throw err
    }
}

export const EventHost = async (id, fdata) => {
    try {
        return await Event_Host(id, fdata).then((data) => data)
    } catch (error) {
        console.log(error)
    }
}

// create sponsor packages
export const Event_SponsorPackage = async (id, fdata) => {
    try {
        return await Event_Sponsor_Package(id, fdata).then((data) => data)
    } catch (error) {
        console.log(error)
    }
}

// update sponsor packages
export const Update_SponsorPackage = async (id, fdata) => {
    try {
        return await Update_Sponsor_Package(id, fdata).then((data) => data)
    } catch (error) {
        console.log(error)
    }
}


//create event guest
export const CreateEventGuests = async (item, event_id) => {
    try {
        return await createEventguests(item, event_id).then((data) => data)
    } catch (err) {
        throw err
    }
}

// Pitch tab
// create event Pitch 
export const CreateEventPitch = async (item, event_id) => {
    try {
        return await createEventpitch(item, event_id).then((data) => data)
    } catch (err) {
        throw err
    }
}

// Refund_Charge 
export const RefundCharge = async (id, fData) => {
    try {
        return await Refund_Charge(id, fData).then((data) => data)
    } catch (err) {
        throw err
    }
}

// create event social Hub 
export const CreateEventSocialMediaLinks = async (item, event_id) => {
    try {
        return await createEventsocialmedialinks(item, event_id).then((data) => data)
    } catch (err) {
        throw err
    }
}

// Event partnerShips Tab 
//get looking for Service Provider 
export const GetServiceProvider = async () => {
    try {
        return await Get_Service_provider().then((data) => data)
    } catch (err) {
        throw err
    }
}

//create partnerShipForm tab
export const CreateEventPartnerShip = async (item, id) => {
    try {
        return await createEventpartnership(item, id).then((data) => data)
    } catch (err) {
        throw err
    }
}

// my Work
//create partnerShipForm tab
export const EventReference = async (event_id, fData) => {
    try {
        return await Event_References(event_id, fData).then((data) => data)
    } catch (err) {
        throw err
    }
}

// Get_Sponsor_Users
export const Get_SponsorUsers = async () => {
    try {
        return await Get_Sponsor_Users().then((data) => data)
    } catch (err) {
        throw err
    }
}


//create Event_MediaHub
export const Event_MediaHub = async (event_id, fData) => {
    try {
        return await Event_Media_Hub(event_id, fData).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const GetEventBudget = async () => {
    try {
        return await Get_Event_Budget().then((data) => data)
    } catch (err) {
        console.log(err)
    }
}


// confiremd sponsor delete on pitch

export const Confirmed_Sponsor_Dlt = async (id) => {
    try {
        return await DltConfirmedSponsor(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const Confirmed_Sponsor_Update = async (id, val) => {
    try {
        return await UpdateConfirmedSponsor(id, val).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}


export const GetSingleConfirmedSponsor = async (id) => {
    try {
        return await GetConfirmedSponsor(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const Create_EventSchedule = async (id,val) => {
    try {
        return await Create_Event_Schedule(id,val).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const Get_EventSchedule = async (id) => {
    try {
        return await Get_Event_Schedule(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

// Event_Schedule_Upload_Image
export const Event_Schedule_UploadImage = async (id,fData) => {
    try {
        return await Event_Schedule_Upload_Image(id,fData).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

// Event_Schedule_Image_Delete
export const Event_Schedule_ImageDelete = async (id) => {
    try {
        return await Event_Schedule_Image_Delete(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

// Get_Transfer_Users
export const Get_TransferUsers = async () => {
    try {
        return await Get_Transfer_Users().then((data) => data)
    } catch (err) {
        console.log(err)
    }
}