// import eventimg from "../../assets/images/event3.jpg"
import { FaLocationDot, FaUser } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useState, useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import DeleteWarningModal from "../Common/DeleteWarningModal";
import defaultimage from "../../assets/images/default-image.jpg";
import { Get_Markas_Complete, UnPublishEvent } from "../../utils/myevent/MyEvent";
import { ToastContainer, toast } from "react-toastify";
import PublishEventPopUp from "../Common/PublishEventPopUp";
import MarkUsForm from "../Common/MyEvent/MarkAsForm";
import { useSelector } from "react-redux";
import MarkAsWarningMdl from "../Common/MyEvent/MarkasWarningmdl";
import { BsFillTicketDetailedFill } from "react-icons/bs";
import expressIcon from "../../assets/images/express-icon2.png";
import { BiTransfer, BiTransferAlt } from "react-icons/bi";

const MyEventCard = ({ baseUrls, val, someFilterHide, handleDeleteEvent, getMyEvent, archive_Type,profileData }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState("");
  const [unPublishBtnLoading, setUnPublishBtnLoading] = useState(false);
  const [markasFormMdl, setMarkasFormMdl] = useState(false);
  const [markasCompleteData, setMarkasCompleteData] = useState([])
  const [markasLoading, setMarkasLoading] = useState(false);
  const [event_Data, setEvent_Data] = useState({
    id: "",
    title: "",
    event_status: "",
    publish_type: ""
  });
  const [markasEventData, setMarkAsEventDetail] = useState({
    id: "",
    title: ""
  });
  const [markasWarningMdl, setMarkasWarningMdl] = useState(false);
  const [publishMdl, setPublishMdl] = useState(false);
  const [currencyIcn, setCurrencyIcn] = useState('');
  const currency = useSelector((val) => val?.CurrencyConvert);

  const eventEndDate = new Date(`${val?.end_date} ${val?.end_time}`);
  const currentDate = new Date();
  const isEventExpired = currentDate > eventEndDate;

  const event_status = val?.event_status?.replace('_', ' ');
  const event_mode = val?.event_mode?.replace('_', ' ')
  const cardImageError = (event) => {
    event.target.src = defaultimage;
    event.target.onError = null;
  };

  const handleUnPublish = async (EId) => {
    const fData = {
      id: EId
    }
    setUnPublishBtnLoading(true)
    try {
      const response = await UnPublishEvent(fData);
      //  if(response?.data?.success){
      toast.success(response?.data?.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      getMyEvent()
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setUnPublishBtnLoading(false);
    }
  }

  const handleModify = (val) => {
    setEvent_Data({ id: val?.id, title: val?.event_title, event_status: "Published", publish_type: "modify" });
    setPublishMdl(!publishMdl);
  }

  // publish date check 
  const now = new Date();
  const publishedDate = val?.published_date;
  const publishedStartTime = val?.published_start_time;
  const publishedDateTime = new Date(`${publishedDate}T${publishedStartTime}`);

  // Check if the current date and time is not greater than the published date and time
  const NotGreaterThanPublished = now <= publishedDateTime;

  const handlemarkascomplete = async (val) => {
    setMarkAsEventDetail({
      id: val?.id,
      title: val?.event_title
    });
    setMarkasLoading(true)
    setMarkasFormMdl(!markasFormMdl);
    try {
      const response = await Get_Markas_Complete('417');
      //console.log("ress",response?.data?.packages)
      setMarkasCompleteData(response?.data?.packages)
    } catch (err) {
      console.log(err)
    } finally {
      setMarkasLoading(false);
    }
  }

  useEffect(() => {
    if (val?.currency_code == "INR") {
      setCurrencyIcn("₹");
    } else if (val?.currency_code == "USD") {
      setCurrencyIcn("$");
    } else if (val?.currency_code == "EUR") {
      setCurrencyIcn("€");
    } else if (val?.currency_code == "GBP") {
      setCurrencyIcn("€");
    }
  }, [val?.currency_code]);

  const minAmount = val?.min_amount ? parseFloat(val?.min_amount).toFixed(0) : null;
  const maxAmount = val?.max_amount ? parseFloat(val?.max_amount).toFixed(0) : null;

  //console.log("city_name",val?.city?.city_name)

  return (
    <>
      {markasWarningMdl && <MarkAsWarningMdl setMarkasWarningMdl={setMarkasWarningMdl} />}

      {publishMdl && <PublishEventPopUp event_Data={event_Data} getMyEvent={getMyEvent} setPublishMdl={setPublishMdl} />}

      {markasFormMdl && <MarkUsForm currency={currency} markasEventData={markasEventData} markasLoading={markasLoading}
       setMarkasLoading={setMarkasLoading} setMarkasFormMdl={setMarkasFormMdl} markasCompleteData={markasCompleteData}
       setMarkasCompleteData={setMarkasCompleteData} />}

      <ToastContainer />
      {eventIdToDelete && (
        <DeleteWarningModal
          msg={"Do You Want to Delete This Event"}
          eventIdToDelete={eventIdToDelete}
          handleDeleteEvent={handleDeleteEvent}
          setEventIdToDelete={setEventIdToDelete}
        />
      )}

      <div className="eventcard">
        <div className="card-image-div"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Link
            className="navigate-detail-btn"
            to={someFilterHide ? `/pasteventdetail/${val?.id}` : val?.is_express_event ? `/expresseventdetail/${val?.id}` 
             : `/eventdetail/${val?.id}`}
            state={{ type: someFilterHide ? "Past Events" : "My Events" }}>
            <div className={`${val?.event_status == "Published"
              ? "event-status-published"
              : val?.event_status == "Approved"
              ? "event-status-approved"
              : val?.event_status == "Draft"
              ? "event-status-pending"
              : val?.event_status == "Rejected"
              ? "event-status-rejected"
              : val?.event_status == "Past_Event"
              ? "event-status-pastevent"
              : val?.event_status == "Under_Review"
              ? "event-status-underreview"
              : val?.event_status == "Archived"
              ? "event-status-archived"
              : ""
              }
               event-status-div`}>
              {event_status}
            </div>
              
            <div>
             {val?.is_express_event ? <img className="express-icon" src={expressIcon} alt="expressicon"/> :""}
            </div>
            
            {
              // val?.event_mode ? <div className="event-online-div">Online</div> : <div className="event-in_person-div">In Person</div>
              val?.event_mode && (<div className="event-in_person-div">{event_mode}</div>)
            }
            <img className={isEventExpired ? "myevent-past-img" : "myevent-card-img"}
              // src={val?.logo ? `${baseUrls?.past_event_logo_image_url}/${val?.logo}` : defaultimage}
              src={someFilterHide && val?.logo
                ? `${baseUrls?.past_event_logo_image_url}/${val?.logo}`
                :  val?.logo
                ? `${baseUrls?.logo_image_url}/${val?.logo}` : defaultimage
              }
              onError={cardImageError} alt="card_img" />
          </Link>
          <div className="myevent-rectangle">
            <FaRegCalendarAlt className="d-none d-sm-flex" size={18} />

            <p className="date-text">
              {moment(val?.start_date).format("ddd, DD MMM")}
            </p>
            <p className="date-text">To</p>
            <p className="date-text">
              {moment(val?.end_date).format("ddd, DD MMM")}
            </p>
          </div>

          { isHovered &&
            val?.event_status == "Draft" || val?.event_status == "Rejected" &&
            val?.created_by == val?.referral_id ? (
            isEventExpired && val?.event_status !== "Draft"  ? (
             <button className="past-edit-button" disabled>
               <MdEdit size={20} />
             </button>
             ) : (
             <Link className="edit-button" to={someFilterHide ? `/EditPastEvent/${val?.id}`
               : val?.is_express_event ? `/createexpressevent/${val?.id}` : `/EditEvent/${val?.id}`}>
               <MdEdit size={20} />
              </Link>
            )
          ) : (
            ""
          )}

          {
            isHovered &&
              val?.event_status == "Draft" || val?.event_status == "Rejected" &&
              val?.created_by == val?.referral_id ? (
              isEventExpired && val?.event_status !== "Draft" ? (
                <button className="past-delete-button" disabled>
                  <MdDelete size={20} />
                </button>
              ) : (
               <button
                 className="delete-button"
                 onClick={() => setEventIdToDelete(val?.id)}>
                 <MdDelete size={20} />
               </button>
              )
            ) : (
              ""
          )}

        {someFilterHide ? (
           ""
          ) : (
            <>
              {
                isHovered && val?.event_status == "Published" && profileData?.i_want_to == "is_host" ?
                  <div className="d-flex">
                    <button className="myevent-unpublish-btn" onClick={() => handleUnPublish(val?.id)}>
                      {unPublishBtnLoading && (
                        <div className="spinner-border text-light " role="status">
                          <span className="sr-only">Loading...</span>
                        </div>)}
                      UnPublish
                    </button>
                    {/*<button className="myevent-Modify-btn" onClick={()=>handleModify(val)}>
                       Modify
                     </button>*/}
                  </div>
                : ""
              }
               
              {
                isHovered && val?.event_status == "Archived" ?
                  archive_Type &&
                  <div className="d-flex">
                    {
                      isEventExpired ?
                        <button className="myevent-markas-btn" onClick={() => handlemarkascomplete(val)}>
                          Mark as Complete
                        </button> :
                        <button className="myevent-markas-disable-btn" onClick={() => setMarkasWarningMdl(!markasWarningMdl)}>
                          Mark as Complete
                        </button>
                    }
                  </div> :
                  ""
              }
            </>
          )}
        </div>

        <div className="mt-2">
          <Link className="navigate-detail-btn"
            to={someFilterHide ? `/pasteventdetail/${val?.id}` : `/eventdetail/${val?.id}`}
            state={{ type: someFilterHide ? "Past Events" : "My Events" }}>
            <h5 className="feature-card-heading mb-0" title={val?.event_title}>{val?.event_title}</h5>
            {/* {val?.users && (
              <div className="d-flex gap-1">
                <p className="mb-0 user-title-text">
                  <FaUser />
                </p>
                <p className="mb-0 user-title-text">
                  {val?.users?.f_name} {val?.users?.l_name}
                </p>
              </div>
            )} */}

            {val?.address ? (
              <div className="d-flex align-items-center gap-1 mt-0">
                <div className="">
                  <FaLocationDot className="" color="black" size={15} />
                </div>
                <p className="feature-card-address" title={`${val?.city?.city_name}, ${val?.state?.short_name}, ${val?.country?.short_name}`}>
                  {/*{val?.address}, {val?.city?.city_name}, {val?.state?.short_name}, {val?.country?.short_name} */}
                  {val?.city?.city_name ? `${val?.city?.city_name},` : "" } {val?.state?.short_name ? `${val?.state?.short_name},` : ""} {val?.country?.short_name}
                </p>
              </div>
              ) : (
              <div className="d-flex align-items-center gap-1 mt-0">
                <div className="">
                  <FaLocationDot className="" color="black" size={15} />
                </div>
                <p className="feature-card-address" title={`${val?.city?.city_name}, ${val?.state?.short_name}, ${val?.country?.short_name}`}>
                  {val?.city?.city_name ? `${val?.city?.city_name},` : ""} {val?.state?.short_name ? `${val?.state?.short_name},` : ""} {val?.country?.short_name}
                </p>
              </div>
            )}
          </Link>

        {val?.published_end_date == null ||
          val?.published_end_date == "0000-00-00" ? (
          ""
         ) : (
          <div className="d-flex align-items-center gap-1 ">
            <FaRegCalendarAlt size={15} color="black" className="d-none d-md-flex" />
            <p className="feature-card-cutoff-p " title={moment(val?.published_end_date).format("ddd, DD MMM YYYY")}>
              CutOff Date : {moment(val?.published_end_date).format("ddd, DD MMM YYYY")}
            </p>
          </div>
          )}

          {
            maxAmount !== null &&
            <div className="d-flex align-items-center gap-1">
              <BsFillTicketDetailedFill color="black" size={15} />
              <p className="feature-card-cutoff-p">
                {/* { minAmount == maxAmount ? `${currencyIcn}${maxAmount}` : `${currencyIcn}${minAmount} - ${currencyIcn}${maxAmount}`}   */}
                {`From ${currencyIcn}${minAmount}`}
              </p>
            </div>
          }

          {
           val?.transfer_user == null ? "" :
           <div className="d-flex align-items-center gap-1" title={`This Event Transfer From ${val?.users?.name}`}>
           <BiTransferAlt  color="black" size={15}/> <p className="feature-card-address m-0 p-0">{val?.users?.name}</p>
           </div>
          }

          {val?.tags?.length > 0 &&
            <div className='Card-IntrestD'>
              {val?.tags?.length > 0 && val?.tags?.map((item) => {
                return (
                  <Link className="" to={`/events/${item?.interest_id}`} state={{ type: 'Interest' }}>
                    <p key={item?.id} className="Card-IntrestP">
                      #{item?.user_interests?.name}
                    </p>
                  </Link>
                )
              })}
            </div>
          }

          {/* {!isEventExpired && (
            <Link to={`/EditEvent/${val?.id}`} className="edit-event-btn mt-1">
              Edit
            </Link>
          )} */}
        </div>
      </div>
    </>
  );
};

export default MyEventCard;
