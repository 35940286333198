import { Create_PastEventPartnerShip, GetPastEventPartnerships } from "../../utils/createPastEventForm/createpasteventform";
import React, { useState, useMemo, useCallback, useRef, useEffect } from "react";
import Select from "react-select";
import arrow from "../../assets/images/arrow2.svg";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PhoneInput from "react-phone-input-2";
import { GetServiceProvider } from "../../utils/CreateEvents/createEvent";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { IoMdInformationCircleOutline } from "react-icons/io";

const EditPastEventPartnerShip = ({ id, setTabValue, Messages }) => {
    const [nearbySponsors, setNearbySponsors] = useState("0");
    const [partnerWithEvent, setPartnerWithEvent] = useState("0");
    const [eventTitle, setEventTitle] = useState('')
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [isEditForm, setIsEditForm] = useState(false);
    const [isHovered, setIsHovered] = useState(false)
    const [loading, setLoading] = useState(false);

    const [providerList, setProviderList] = useState([]);
    const [showProviders, setShowProviders] = useState([]);
    const [input, setInput] = useState({
        id: new Date().getTime().toString(),
        partner_name: "",
        partner_number: "",
        country_code: "",
        partner_email: "",
        company_name: "",
        website_name: "",
        // potential_nearby_sponsors: "",
    });

    const [formData, setFormData] = useState({
        // event_name: "",
        broad_scope: "",
        providers: "",
        service_provider_others: "",
        event_related_service_provider: "",
        potential_nearby_sponsors: ""
    })
    const [errors, setErrors] = useState({});
    const gridRef = useRef();

    const onGridReady = (params) => {
        gridRef.current = params.api;
    };


    useEffect(() => {
        setFormData({
            ...formData,
            potential_nearby_sponsors: nearbySponsors,
            event_related_service_provider: partnerWithEvent
        });
    }, [partnerWithEvent, nearbySponsors])



    const handledeletePartnerShip = (val, data, rowIndex) => {
        const deletedRow = rowData.filter((item, index) => index !== rowIndex)
        setRowData(deletedRow);
    }

    const handleEditPartnerShip = (val, data, rowIndex) => {
        setInput(data)
        setIsEditForm(true)
    }


    useEffect(() => {
        setColumnDefs([
            {
                headerName: "Name",
                field: "partner_name",
                // cellRenderer: AvatarRenderer,
                // filter: false,
            },
            {
                headerName: "Mobile no.",
                field: "partner_number",
                // cellRenderer: AvatarRenderer,
                // filter: params => params.data.editable,
            },
            {
                headerName: "Email",
                field: "partner_email",
                // cellRenderer: AvatarRenderer,
                // filter: params => params.data.editable,
            },
            {
                headerName: "Company Name",
                field: "company_name",
                // cellRenderer: AvatarRenderer,
                // filter: params => params.data.editable,
            },
            {
                headerName: "Website Name",
                field: "website_name",
                // cellRenderer: AvatarRenderer,
                // filter: params => params.data.editable,
            },
            {
                headerName: "Action",
                field: "id",
                cellRenderer: ({ value, data, rowIndex }) => {
                    return (
                        <div className="d-flex gap-2">
                            <button
                                className="create-Date-Time"
                                type="button"
                                onClick={() => handleEditPartnerShip(value, data, rowIndex)}
                            >
                                Edit
                            </button>
                            <button
                                className="create-Date-Time"
                                type="button"
                                onClick={() => handledeletePartnerShip(value, data, rowIndex)}
                            >
                                Remove
                            </button>
                        </div>
                    );
                },
            },

        ]);
    }, []);

    const onBtExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel();
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            filter: "agTextColumnFilter",
            floatingFilter: true,
        };
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    }

    const handleBroadScopeSummary = (value) => {
        const text = value.replace(/<[^>]*>?/gm, '');
        if (text.length <= 500) {
            setFormData((prevState) => ({
                ...prevState,
                broad_scope: value
            }));
            setErrors({
                ...errors,
                broad_scope: ""
            });
        } else {
            setErrors({
                ...errors,
                broad_scope: "Broad Scope cannot exceed 500 characters."
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const handlePhoneNumberChange = (value, country) => {
        const phoneNum = value?.slice(2);
        const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
        setInput({
            ...input,
            partner_number: `${phoneNum}`,
            country_code: `${d_code}`
        });
    };

    const validation = () => {
        let tempErrors = {};
        if (!input.partner_name) tempErrors.partner_name = "Partner Name is required.";

        if (!input.partner_number) {
            tempErrors.partner_number = "Partner Mobile No. is required.";
        } else if (!/^\d{10}$/.test(input.partner_number)) {
            tempErrors.partner_number = "Invalid mobile number.";
        }

        if (input.partner_email !== "") {
            // if (!input.partner_email) tempErrors.partner_email = "Partner Email is required.";
            if (!/\S+@\S+\.\S+/.test(input.partner_email)) tempErrors.partner_email = "Invalid Email address.";
        }
        if (!input.company_name) tempErrors.company_name = "Company Name is required.";
        if (!input.website_name) tempErrors.website_name = "Website Name is required.";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    const validation2 = () => {
        let Errors = {};
        if (!formData.broad_scope) Errors.broad_scope = "Broad Scope of Work is required"
        setErrors(Errors);
        return Object.keys(Errors).length === 0;
    }

    const handleAddRow = (e) => {
        e.preventDefault();
        if (validation()) {
            const index = rowData.findIndex(row => row.id === input.id);
            if (isEditForm) {
                // Update existing item
                const updatedRowData = [...rowData];
                updatedRowData[index] = input;
                setRowData(updatedRowData);
                setInput({
                    id: "",
                    partner_name: "",
                    partner_number: "",
                    country_code: +91,
                    partner_email: "",
                    company_name: "",
                    website_name: "",
                })
                setErrors({})
                setIsEditForm(false);
            } else {
                const newItem = input
                gridRef.current.applyTransaction({ add: [newItem] });
                setRowData(prevData => [...prevData, newItem]);
                setInput({
                    id: "",
                    partner_name: "",
                    partner_number: "",
                    country_code: +91,
                    partner_email: "",
                    company_name: "",
                    website_name: "",
                })
                setErrors({})
            }
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const data = ({ ...formData, new_partners: rowData })
        const DataStringfy = JSON.stringify(data)

        if (nearbySponsors == '0') {
            setLoading(true)
            try {
                await Create_PastEventPartnerShip(DataStringfy, id).then((results) => {
                    if (results?.data?.success) {
                        setTabValue("Sponsor-Packages");
                        Messages(results?.data?.message);
                    }
                });
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        } else {
            if (rowData?.length >= 1) {
                setLoading(true)
                try {
                    await Create_PastEventPartnerShip(DataStringfy, id).then((results) => {
                        if (results?.data?.success) {
                            setTabValue("Sponsor-Packages");
                            Messages(results?.data?.message);
                        }
                    });
                } catch (error) {
                    console.log(error)
                }
                setLoading(false)
            } else {
                toast.warn("Add at least one Potential Nearby Sponsor.", {
                    position: 'top-center',
                    autoClose: 3000
                })
            }
        }

    }

    const handleAddProvider = (selectedOption) => {
        if (selectedOption.some(option => option.label === 'All')) {
            // Check if "All" is selected
            const allSelected = selectedOption.find(option => option.label === 'All');
            if (allSelected) {
                // If "All" is selected, select all options except "All"
                const allOptions = providerList.filter(option => option.label !== 'All');
                setShowProviders(allOptions);
                const allValues = allOptions.map(option => option.value);
                setFormData({ ...formData, providers: allValues });
            } else {
                // If "All" is deselected, remove all options
                setShowProviders([]);
                setFormData({ ...formData, providers: [] });
            }
        } else {
            // Normal behavior
            setShowProviders(selectedOption);
            const list = selectedOption.map((item) => item.value);
            setFormData({ ...formData, providers: list });
        }
    }

    const ServiceProvider = async () => {
        try {
            await GetServiceProvider().then((response) => {
                const val = response?.data?.data;
                setProviderList(
                    val.map((item) => {
                        return {
                            value: item?.id,
                            label: item?.service_type,
                        };
                    })
                );
            });
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        ServiceProvider()
    }, [])


    const getData = async () => {
        try {
            await GetPastEventPartnerships(id)
                .then((response) => {
                    const data = response?.data?.data
                    setEventTitle(data?.event_title)
                    const Intrest = data?.providers?.map((item) => ({
                        value: item?.providers?.id,
                        label: item?.providers?.service_type,
                    }));
                    setShowProviders(Intrest);
                    const listIntrest = Intrest?.map((item) => item.value);
                    setFormData(currentInput => ({
                        ...currentInput, // preserve old data
                        ...data, // spread new data
                        providers: listIntrest
                    }));

                    setRowData(data?.past_event_partnership)
                    setPartnerWithEvent(data?.event_related_service_provider)
                    setNearbySponsors(data?.potential_nearby_sponsors)
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData();
    }, [id])


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // rect quill length count
    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
    
        let textWithNewlines = div.innerHTML
    
        if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
          textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
        } else {
          // Otherwise, apply replacements
          textWithNewlines = textWithNewlines
            .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
        }
    
        div.innerHTML = textWithNewlines;
        return div.textContent || div.innerText || "";
      };

    const getDescriptionLength = () => {
        const cleanDescription = stripHtmlTags(formData.broad_scope);
        return cleanDescription.length;
    };

    const maxLength = 500;
    const currentLength = getDescriptionLength();
    const remainingLength = maxLength - currentLength;
    // 
    return (
        <>
            <div className="basicInfo">
                {/* <h2 className="basicinfo-head">Event PartnerShip</h2> */}
                <div className="d-flex align-items-center gap-1 position-relative">
                    <p className="createevent-head-p">Leverage our platform to establish connections with local vendors, event organizers, planners, and service providers tailored to your event needs. Provide detailed information to facilitate effective partnerships, enhancing your event's success and ensuring seamless coordination through our support on our platform.</p>
                    {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Event PartnerShip</h2>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <IoMdInformationCircleOutline size={23} />
                    </div>
                    {isHovered && (
                        <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <p className="info-popup-text">Leverage our platform to establish connections with local vendors, event organizers, planners, and service providers tailored to your event needs. Provide detailed information to facilitate effective partnerships, enhancing your event's success and ensuring seamless coordination through our support on our platform.</p>
                        </div>
                    )} */}
                </div>
                <form onSubmit={onSubmit}>
                    <div>
                        <div className="row mt-3">
                            <div className="d-flex align-items-center gap-3">
                                <label className="mb-0">
                                    Do you want to connect with your potential nearby sponsors for this event?
                                </label>
                                <div className="d-flex gap-3">
                                    <div className="form-check">
                                        <label className="form-check-label ">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="1"
                                                checked={nearbySponsors == "1"}
                                                name="nearbySponsors"
                                                onChange={(e) => setNearbySponsors(e?.target?.value)}
                                            />
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label ">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="0"
                                                checked={nearbySponsors == "0"}
                                                name="nearbySponsors"
                                                onChange={(e) => setNearbySponsors(e?.target?.value)}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {nearbySponsors == "1" && (
                            <>
                                <div className="row ">
                                    <label className="head-label mt-1">Potential Nearby Sponsors
                                        <IoMdInformationCircleOutline size={16} color="black" title="Name local potential sponsor you wish to collaborate with, highlighting any existing relationships. We would connect with them on your behalf for this event and our platform capabilities" />
                                    </label>
                                    <div className="col-md-12 ">
                                        <form className="event-partnerShip-form">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="form-label">Partner Name<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="partner_name"
                                                        value={input?.partner_name}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        placeholder="Partner Name" />
                                                    {errors.partner_name && (
                                                        <span className="error">{errors.partner_name}</span>
                                                    )}
                                                </div>

                                                <div className="form-group col-6 col-md-4">
                                                    <label className="form-label">
                                                        Partner Mobile no<span className="asterisk">*</span>
                                                    </label>
                                                    <PhoneInput
                                                        className=""
                                                        country={"in"}
                                                        name="partner_number"
                                                        value={`${input?.country_code}${input?.partner_number}`}
                                                        onChange={handlePhoneNumberChange}
                                                    />
                                                    {errors.partner_number && (
                                                        <span className="error">{errors.partner_number}</span>
                                                    )}
                                                </div>

                                                <div className="col-md-4">
                                                    <label className="form-label">Partner Email</label>
                                                    <input
                                                        type="email"
                                                        name="partner_email"
                                                        value={input.partner_email}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        placeholder="Partner Email" />
                                                    {errors.partner_email && (
                                                        <span className="error">{errors.partner_email}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="form-label">Company Name<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        value={input.company_name}
                                                        name="company_name"
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        placeholder="Company Name" />

                                                    {errors.company_name && (
                                                        <span className="error">{errors.company_name}</span>
                                                    )}
                                                </div>

                                                <div className="col-md-4">
                                                    <label className="form-label">Website Name<span className="asterisk">*</span></label>
                                                    <input
                                                        type="text"
                                                        value={input.website_name}
                                                        name="website_name"
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        placeholder="Website Name" />

                                                    {errors.website_name && (
                                                        <span className="error">{errors.website_name}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <button className="clear-filter-button" type="button" onClick={handleAddRow}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {/* {rowData?.length > 0 && */}
                                <div className="row mt-4">
                                    <div className="ag-theme-alpine" style={{ height: 300, width: "100%" }}>
                                        <AgGridReact
                                            ref={gridRef}
                                            onGridReady={onGridReady}
                                            columnDefs={columnDefs}
                                            rowData={rowData}
                                            defaultColDef={defaultColDef}
                                            rowSelection="multiple"
                                        // Include group footer at the bottom
                                        // pagination={true}
                                        // paginationPageSize={10}
                                        // paginationPageSizeSelector={[10, 25, 50]}
                                        ></AgGridReact>
                                    </div>
                                </div>
                                {/* } */}
                            </>
                        )}
                    </div>

                    <div>
                        <div className="row mt-3">
                            <div className="d-flex gap-3">
                                <label className="mb-0">
                                    Do you want to Partner with event organisers, event planners or
                                    other event related service providers. with them for organising
                                    your events?
                                </label>
                                <div className="d-flex gap-3">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="1"
                                                checked={partnerWithEvent == "1"}
                                                name="partnerWithEvent"
                                                onChange={(e) => setPartnerWithEvent(e?.target?.value)}
                                            />
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="0"
                                                checked={partnerWithEvent == "0"}
                                                name="partnerWithEvent"
                                                onChange={(e) => setPartnerWithEvent(e?.target?.value)}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                {/* <button className="create-event-btn" onClick={()=>setPromotionalPlan(!promotionalPlan)}>
                            {promotionalPlan ? "No" :"Yes"} </button> */}
                            </div>
                        </div>
                        {partnerWithEvent == "1" && (
                            <div className="row mt-2">
                                <label className="head-label">Event Partners</label>

                                <div className="col-md-6">
                                    <label className="form-label ">Event Name</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        disabled
                                        name="event_name"
                                        placeholder="Event Name"
                                        value={eventTitle}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">
                                        I am looking for Service Provider
                                    </label>
                                    <Select
                                        className=""
                                        name="providers"
                                        defaultValue={[]}
                                        onChange={handleAddProvider}
                                        value={showProviders}
                                        options={providerList}
                                        isMulti
                                        placeholder="Select Service Provider"
                                    />
                                </div>


                                <div className="col-md-12 mt-3">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label mb-0">
                                            Broad Scope of work<span className="asterisk"></span>
                                            {/* <IoMdInformationCircleOutline size={16} color="black" title="Provide a concise overview of the primary objectives and key deliverables for the project" /> */}
                                        </label>
                                        <p className="Character-0500">
                                            Character:<span className="text-style-1">
                                                {/* remainingLength */}
                                                {currentLength}/{maxLength}
                                            </span>
                                        </p>
                                    </div>
                                    <p className="reactquill-tooltip-p mb-1">(Note: Provide a concise overview of the primary objectives and key deliverables for the project)</p>
                                    <div style={{ height: '220px' }}>
                                        <ReactQuill
                                            value={formData?.broad_scope}
                                            onChange={handleBroadScopeSummary}
                                            theme='snow'
                                            style={{ height: '150px' }}
                                            placeholder="Enter Broad Scope of work"
                                        />
                                    </div>
                                    {errors.broad_scope && (
                                        <span className="error">{errors.broad_scope}</span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-md-end align-items-center mt-3 mt-sm-0">
                        <div className="d-flex flex-column flex-md-row gap-2">
                            <button
                                className="d-flex align-items-center gap-1 saveandcontinue-btn"
                                type="submit" >
                                {loading ? (
                                    <div
                                        className="spinner-border text-light "
                                        role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    ""
                                )}
                                Save and Continue
                                <img className="arrow-img" src={arrow} alt="arrow" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default EditPastEventPartnerShip;

