

export const BASE_URL = 'https://sponsorringus.com/admin/api/v1';
// export const BASE_URL = "https://www.sponsorringus.com/backend/api/v1";
// export const BASE_URL = 'https://ringus.idea2reality.tech/api/v1';

export const BANNER_URL = '/banners?banner_type=all';
// login register
export const USER_LOGIN_URL = '/auth/login';

// signup 
export const USER_REGISTER_URL = '/auth/register';
export const CHAMP_REGISTER_URL = '/auth/champ_register';

// basic information
//get_event_type
export const GET_EVENT_TYPE = "/get_event_type";

export const GET_EVENT_TOPIC = "/get_categories";
export const GET_EVENT_SUB_TOPIC = "/events/get_sub_categories/3";
export const GET_EVENT_PEOPLE_RANGE = "/events/get_people_range";
export const GET_EVENTS_CREATE = "/events/create";

// packages form
export const GET_PREDEFINE_PACKAGE = "/events/get_predefine_package";

export const CREATE_EVENT_PACKAGES = "/packages/create_event_package";

// pitch form 
export const PITCH_FORM = "/events/create_event_pitch_data";

// organizer pitch form
export const ORGANIZER_PITCH_FORM = "/events/create_event_organizer_data"

// my event
export const GET_MY_EVENT = "/events/get_my_event";

// delete_event
export const DELETE_EVENT = "/events/delete_event";

// event detail 
export const EVENT_DETAIL = "/event_details";

// packages create list on packages form
export const GET_CREATE_PACKAGES_LIST = "/events/get_packages";

// sponsor user or comapny 
export const GET_SPONSOR = "/get_sponsor";

//config all data
export const GET_CONFIG_DATA = '/config';

// user profile
export const GET_PROFILE = "/customer/info";

// event list 
export const GET_EVENT_LIST = "/event_list";

// archive_event
export const GET_ARCHIVE_EVENT_LIST = "/archive_event";

//edit event
export const EDIT_EVENT = "/events/edit"

// update event 
export const UPDATE_EVENT = "/events/update"

// home events
export const GET_HOME_EVENT = "/home"

// get Interests
export const GET_HOME_AUTH_EVENT = "/events/home";

// profile page event type
export const MY_EVENT_TYPES = "/customer/get_my_event_types";

// Edit interest type 
export const EDIT_INTEREST_EVENT_TYPES = "/customer/edit_interest";

// get Testimonial conditons
export const GET_TESTIMONIAL = "/events/check_testimonials";

// post Testimonial 
export const POST_TESTIMONIAL = "/events/create_testimonials";

//get testimonial data with rating or testimoial name
export const GET_TESTIMONIAL_LIST_RATING_NAME = "/get_testimonials";

//get only testimonial data
export const GET_ONLY_TESTIMONIAL_LIST = "/home_get_testimoials"

//Add Query
export const POST_QUERY = "/sponsor/request";

// get request 
export const GET_MANAGE_REQUEST = "/sponsor/GetRequestList";

//get message
export const GET_MESSAGE = "/sponsor/GetRequestConversations"

// manage request message send

export const MANAGE_REQ_MESAGE_SEND = "/sponsor/SendMessage";

// review event post
export const EVENT_REVIEW = "/events/event_review";

// review event get 
export const GET_EVENT_REVIEW = "/events/get_review";

//profile predefine get list 
export const GET_PREDEFINE_LIST = "/profile_predefine";

//get profile editDAtalist
export const GET_EDIT_PROFILE_LIST = '/customer/edit_profile';

//post profile update 
export const POST_UPDATE_PROFILE = "/customer/update-profile";

// get city state country
export const GET_CITY_STATE_COUNTRY = '/GetcountryStatesCity';

// get coupon
export const GET_COUPONS = '/coupons/get_coupons';

//apply coupon
export const APPLY_COUPON = "/coupons/apply_event_coupons";

//event_transaction create order id
export const EVENT_TRANSACTION = "/event_transaction/create_payment_order";

// create coupon 
export const CREATE_COUNPON = "/events/create_coupons";

// get packages List
export const GET_PACKAGES_LIST = "/events/get_event_packages"

// get apply counpons
export const GET_APPLY_COUPON = "/coupons/get_apply_coupons";

// update_payment_response
export const UPDATE_PAYMENT = '/event_transaction/update_payment_response';

// packages form get list api for edit 
export const GET_PACKAGES_FORM_EDIT = "/events/edit_event_package_details";

// packages form post list api for edit 
export const GET_POST_PACKAGES_FORM_EDIT = "/events/update_event_package";

// get pitch form edit list
export const GET_PITCH_FORM_EDIT = "/events/edit_pitch_event";

// post pitch form edit data 
export const GET_POST_PITCH_FORM_EDIT = "/events/update_event_pitch_data";

//get organizer data list 
export const GET_ORGANIZER_FORM_EDIT = "/events/edit_organizer_data";

// post organizer data list
export const GET_POST_ORGANIZER_FORM_EDIT = "/events/update_event_organizer_data";

// get order detail
export const GET_ORDER_DETAIL = "/event_transaction/get_orders_details";

// get my transaction 
export const GET_MYEVENT_TTRANSACTION = "/event_transaction/get_my_event_transaction";

// event packages delete api 
export const EVENT_PACKAGE_DELETE = "/events/delete_package"

// https://ringus.idea2reality.tech/api/v1/champs/champ_dashboard
export const GET_CHAMP_DASHBOARD = "/champs/champ_dashboard";

// get Champ list Data
export const GET_CHAMP_REFER_USER_LIST = "/champs/get_user_list_for_champ";

// get Champ list Data
export const GET_CHAMP_DATA_LIST = "/champs/get_host_list_for_champ";

// // get champs host Events List
export const GET_CHAMP_EVENTS_LIST = "/champs/get_host_events_for_champ";

// Create Past Event Gallery
export const CREATE_PAST_EVENT_GALLERY = '/events/create_past_event_gallery';

// get champs
export const GET_CHAMPS_LIST = '/champs/get_champs';

// change_current_champ
export const CHANGE_CHAMP = '/champs/change_current_champ';

// genrate email otp
export const EMAIL_VERIFY_OTP = "/auth/generate_email_otp";

// email verification
export const VERIFY_EMAIL_OTP = "/auth/verify_email_otp";

// genrate number otp 
export const NUMBER_GENERATE_OTP = "/auth/generate_number_otp";

// phone verification
export const NUMBER_OTP_VERIFY = "/auth/verify_number_otp";

// user SociaL MEDIA login
export const USER_SOCIAL_MEDIA_LOGIN = "/auth/social_media_singUp";

// USER_SOCIAL_MEDIA_LOGIN_CHAMP
export const USER_SOCIAL_MEDIA_LOGIN_CHAMP = "/auth/social_media_singup_champ";

// update email
export const UPDATE_EMAIL = "/customer/change_email_otp";

// verify change email otp
export const VERIFY_CHANGE_EMAIL = "/customer/verify_change_email_otp";

// change_number_otp
export const CHANGE_NUMBER = "/customer/change_number_otp";

// verify_change_number_otp
export const VERIFY_CHANGE_NUMBER = "/customer/verify_change_number_otp";

// change_username_otp
export const CHANGE_USERNAME = "/customer/change_username_otp";

// verify_change_username_otp
export const VERIFY_CHANGE_USERNAME = "/customer/verify_change_username_otp";

// get_sponsor_list_for_champ
export const GET_SPONSOR_LIST_FOR_CHAMP = "/champs/get_sponsor_list_for_champ";

// get_event_transctions_list_for_champ;
export const GET_EVENT_TRANSACTIONS_LIST_FOR_CHAMP = "/champs/get_event_transctions_list_for_champ";

// get_sponsor_events_for_champ
export const GET_SPONSOR_EVENTS_FOR_CHAMP = "/champs/get_sponsor_events_for_champ";

//get_avatar
export const GET_AVATAR = "/customer/get_avatar";

// generate_singup_number_otp
export const GENERATE_SIGNUP_NUMBER_OTP = "/auth/generate_singup_number_otp";

// verify_singup_number_otp
export const VERIFY_SIGNUP_NUMBER_OTP = "/auth/verify_singup_number_otp";

// generate_singup_number_otp
export const GENERATE_SIGNUP_EMAIL_OTP = "/auth/generate_singup_email_otp";

// verify_singup_number_otp
export const VERIFY_SIGNUP_EMAIL_OTP = "/auth/verify_singup_email_otp";

// log activity local api
export const LOG_ACTIVITY = "/customer/log_activity"

// get_user_interests
export const GET_USER_INTERESTS = "/get_user_interests";

//get Created Coupon_list 
export const GET_CREATED_COUPONS = "/events/get_coupons"

// Delete Coupon
export const DELETE_COUPON = "coupons/delete"

// get single Edit Coupon
export const EDIT_COUPON = "coupons/edit";

// Update coupon Data
export const UPDATE_COUPON = "/events/update_coupons"

// create past event basic info
export const CREATE_PAST_EVENT_BASIC_INFO = "/past_event/create";

// create past event pitch  
export const CREATE_PAST_EVENT_PITCH = "/past_event/create_pitch"

// create past event orgaanizer 
export const CREATE_PAST_EVENT_ORGANIZER = "/past_event/create_past_event_organizer_data"

// get created my pastevent 
export const GET_MY_PAST_EVENTS = "/past_event/get_my_past_event"

//past event detail page 
export const PAST_EVENT_DETAIL = "/past_event_details";

// get_membership_plans
export const GET_MEMBERSHIP_PLANS = "/membership_plans/get_membership_plans";

// published_event
export const PUBLISHED_EVENT = "/events/published_event";

// champs/get_users_for_champ
export const GET_USERS_FOR_CHAMP = "/champs/get_users_for_champ";

//user logout api USER_LOGOUT_API
export const USER_LOGOUT_API = "/customer/logout";

// membership_plans/premire_plan
export const MEMBERSHIP_PLAN = "/membership_plans/premire_plan";

//update_membership_payment_response
export const UPDATE_MEMBERSHIP_PAYMENT_RESPONSE = "/membership_plans/update_membership_payment_response";

// featured_event
export const FEATURED_EVENT = "/events/featured_event";

// events/featured_event_list
export const FEATURED_EVENT_PRICE = "/events/featured_event_list";

// events/update_featured_payment_response
export const FEATURED_PAYMENT_RESPONSE = "/events/update_featured_payment_response";

// event pitch image docs delete  PITCH_IMAGE_DOCUMENT_DLT
export const PITCH_IMAGE_DOCUMENT_DLT = "/events/image_deleted?ids=";

// membership_plans/get_membership_transactions
export const MEMBERSHIP_TRANSACTIONS = "/membership_plans/get_membership_transactions";

// GET_NOTIFICATION_LIST 
export const GET_NOTIFICATION_LIST = "/event_notifications/notifications"

// get languages list multi select 
export const GET_LANGUAGES_LIST = "/customer/get_languages";

// GET_CITY_LIST
export const GET_CITY_LIST = "/cities"

// GET_LANGUAGE_LIST
export const GET_LANGUAGE_LIST = "/get_languages"

//get rejected data
export const GET_REJECT_DATA = "/rejected/get_send_messages"

// send rejected msg
// export const SEND_REJECTED_MSG = '/rejected/rejected_send_message';
// rejected/rejected_send_message/421
export const SEND_REJECTED_MSG = '/rejected/rejected_send_message';

// check_social_media_user
export const CHECK_SOCIAL_MEDIA_USER = "/auth/check_social_media_user";

// testimonial delete
export const TESTIMONIAL_DELETE = "/events/delete_testimonials";

// get_event_interest
export const GET_INTERESTS = "/get_event_interest";

// create_interest
export const CREATE_INTERESTS = "/create_interest";

//support_ticket/initiate_chat_support
export const SUPPORT_TICKET = "/support_ticket/initiate_chat_support";

// /support_ticket/get_request_messages
export const GET_REQUEST_MESSAGES = "/support_ticket/get_request_messages";

// /support_ticket/get_messages
export const GET_MESSAGES = "/support_ticket/get_messages";

// /support_ticket/send_message
export const SEND_MESSAGE = "/support_ticket/send_message";

//user change password 
export const USER_CHANGE_PASSWORD = "/customer/change_password"

///support_ticket/delete/2
export const DELETE_SUPPORT_TICKET = "/support_ticket/delete";

// support_ticket/get_request_messages?search=
export const SEARCH_REQUEST = "/support_ticket/get_request_messages";

export const SET_ARCHiVE = "/sponsor/archive";

// https://ringus.idea2reality.tech/api/v1/sponsor/delete?id=1
export const MANAGE_REQUEST_DELETE_CHAT = "/sponsor/delete";

// events/get_about_me/405
export const GET_ABOUT_ME = "/events/get_about_me";

// events/event_host/405
export const EVENT_HOST = "/events/event_host";

// create event essentials form
export const CREATE_EVENT_ESSENTIALS = "/events/event_essentials";

// get online mode list
export const GET_ONLINE_MODE_LIST = '/events/get_online_mode';

//guest tab statis dropdown list
export const GET_GUEST_DATA_LIST = '/get_dropdwon_for_guest';

// /events/sponsor_package/405
export const EVENT_SPONSOR_PACKAGE = "/events/sponsor_package";

export const UPDATE_SPONSOR_PACKAGE = "/events/update_sponsor_package"

// get event type essentials
export const GET_EVENT_ESSENTIALS_TYPE = '/get_type'

//create event guest tab form
export const CREATE_EVENT_GUEST_TAB = "/events/event_guest"

// Pitch tab
// create event Pitch 
export const CREATE_EVENT_PITCH_TAB = "/events/event_pitch";

//events/refund_charge/405
export const REFUND_CHARGE = "events/refund_charge";

//Social hub tab 
export const CREATE_EVENT_SOCIAL_HUB_TAB = "/events/social_hub";

// Event partnerShips Tab 
//get looking for Service Provider 
export const GET_PARTNERSHIPS_SERVICE_PROVIDER = "/events/get_service_provider";


// My Work 
// /events/event_references/405
export const EVENT_REFERENCE = "/events/event_references";

// events/get_sponsor
export const GET_SPONSOR_USERS = "/events/get_sponsor";

// /events/create_coupons
export const CREATE_COUNPONS = "/events/create_coupons";

// /events/event_media_hub/405
export const EVENT_MEDIA_HUB = "/events/event_media_hub";
export const CREATE_EVENT_PARTNERSHIP_TAB = "/events/event_partnership";

//Edit apis event ---------------------------------------------------------
//get event essentials
export const GET_EDIT_ESSENTIALS = "/events/get_event_essentials";

//get event guest
export const GET_EDIT_GUEST = "/events/get_event_guests";

//get event pitch
export const GET_EDIT_PITCH = "/events/get_event_pitch";

//get event host
export const GET_EDIT_HOST = "/events/get_event_host";

//get event partnership
export const GET_EDIT_PARTERSHIP = "/events/get_event_partnership";

//get event sponsor package
export const GET_EDIT_SPONSOR_PACKAGE = "/events/get_sponsor_package";

//get event media hub

//get event social hub
export const GET_EDIT_SOCIAL_HUB = "/events/get_event_social_hub";

// get event media hub
export const GET_EDIT_MEDIA_HUB = "/events/get_event_media_hub"

// get event cancel refund
export const GET_EDIT_CANCEL_REFUND = "/events/get_refund_charge";

//get event reference 
export const GET_EVENT_REFERENCE = "/events/get_event_references";


//updates event
export const EDIT_EVENT_ESSENTIALS = "/events/update_event_essentials";

//events/published_event
export const PUBLISH_EVENT = "/events/published_event";

//events/unpublished_event
export const UNPUBLISH_EVENT = "/events/unpublished_event";

// events/published/410
export const GET_EDIT_PUBLISHED_DATA = "/events/published"

//get single partership 
export const GET_PARTERSHIP_PARTNERS = "/events/get_partners";

// update partnership partner
export const UPDATE_PARTNERSHIP_PARTNER = "/events/update_partners"

export const DELETE_PARTERSHIP_PARTNERS = "/events/partner_delete";


export const EMAIL_FORGOT_PASSWORD = "/auth/email_forget_password";

export const EMAIL_FORGOT_VERIFY_OTP = "/auth/forget_verify_otp";

// /auth/forget_password
export const FORGOT_PASSWORD = "/auth/forget_password";

export const MOBILE_FORGOT_PASSWORD = "/auth/generate_forget_number_otp";

export const VERIFY_FORGOT_NUMBER_OTP = "/auth/verify_forget_number_otp";

export const PROFILE_DETAIL = "/champs/get_profile_details";

export const GET_HOUSEHOLD_INCOME = "/get_household_income";

export const GET_EDUCATION_LIST = "/get_education";

// /champs/event_sponsor_transanction_for_champ/417
export const EVENT_SPONSOR_TRANSACTION = "/champs/event_sponsor_transanction_for_champ";

//events/mark_as_complate/417
export const GET_MARK_AS_COMPLETE = "/events/mark_as_complete";

// events/mark_as_complete_details/417
export const MARK_AS_COMPLETE_DETAIL = "/events/mark_as_complete_details";

// create past event event esstials
export const CREATE_PASTEVENT_ESSENTIALS = "/past_event/create"

//create past event guest
export const CREATE_PASTEVENT_GUEST = "/past_event/create_guests"

export const PASTEVENT_PITCH = "/past_event/create_pitch"

export const CREATE_PASTEVENT_HOST = "/past_event/create_host"

export const CREATE_PASTEVENT_PARTNERSHIP = "/past_event/create_partnership"

export const CREATE_PASTEVENT_SPONSORPACKAGE = "/past_event/create_sposnor_package"

export const CREATE_PASTEVENT_SOCIALHUB = "/past_event/create_social_hub"

export const CREATE_PASTEVENT_MEDIAHUB = "/past_event/create_media_hub"

// v1/create_news_letters
export const CREATE_NEWS_LETTER = "/create_news_letters";


// update past Event essentials 
export const PASTEVENT_UPDATE_ESSENTIALS = "/past_event/update"

// get Edit Past Event ----------------------------
export const GET_PASTEVENT_ESSENTIALS = "/past_event/get_past_event_essentials"

export const GET_PASTEVENT_GUEST = "/past_event/get_past_event_guests"

export const GET_PASTEVENT_PITCH = "/past_event/get_past_event_pitch"

export const GET_PASTEVENT_HOST = "/past_event/get_past_event_host"

export const GET_PASTEVENT_PARTNERSHIPS = "/past_event/get_past_event_partnership"

export const GET_PASTEVENT_SPONSORPACKAGE = "/past_event/get_past_event_sponsor_package"

export const GET_PASTEVENT_MEDIAHUB = "/past_event/get_past_event_media_hub"

export const PASTEVENT_BANNER_DELETE = "/past_event/delete_banner"

export const GET_PASTEVENT_SOCIALHUB = "/past_event/get_past_event_social_hub"

export const PASTEVENT_MEDIA_IMAGE_DOCUMENT_DLT = "/past_event/image_deleted?ids="

export const GET_PASTEVENT_SPONSOR_PACKAGE = "/past_event/edit_past_event_sponsor_package"

export const UPDATE_PASTEVENT_SPONSOR_PACKAGE = "/past_event/update_sposnor_package"

export const PAST_EVENT_UPDATE_SPONSOR_PACKAGE = "/past_event/update_sposnor_package"

export const GET_EVENT_BUDGET = "/get_budgets"

export const PASTEVENT_PACKAGE_DELETE = "/past_event/delete_sposnor_package"

// events/delete_banner/1
export const DELETE_BANNER = "/events/delete_banner";

export const GET_LOGIN_ACTIVITY_MSG = "/customer/get_log_activity";

// get_professions
export const GET_PROFESSIONS = '/get_professions';

//CONFIRMED_SPONSOR_DELETE pitch tab 
export const CONFIRMED_SPONSOR_DELETE = "/events/delete_sponsor"

// CONFIRMED_SPONSOR_UPDATE pitch tab
export const CONFIRMED_SPONSOR_UPDATE = "/events/update_sponsor"

export const GET_SINGLE_CONFIRMED_SPONSOR = "/events/get_sponsors"

export const PAST_EVENT_CONFIRMED_SPONSOR_DELETE = "/past_event/delete_sponsor"

export const PAST_EVENT_GET_SINGLE_CONFIRMED_SPONSOR = "/past_event/get_sponsors"

export const PAST_EVENT_CONFIRMED_SPONSOR_UPDATE = "/past_event/update_sponsor"

export const CREATE_EVENT_SCHEDULE = "/events/create_event_schedule";

export const PARTNER_WITH_HOST = '/event_partners/event_partner_with_host';

export const PARTNER_WITH_SPONSOR = "/event_partners/event_partner_with_sponsor";

export const GET_EVENT_SCHEDULE = "/events/get_event_schedule";

export const EVENT_SCHEDULE_UPLOAD_IMAGE = "/events/event_schedule_upload_image";

export const EVENT_SCHEDULE_IMAGE_DELETE = "/events/get_document_delete_schedule";

export const GET_HOST_ALL_EVENTS = '/users_all_event';

export const GET_HOST_ALL_PAST_EVENTS = "/users_all_past_event";

export const FEATURED_EVENT_LIST = "/events/featured_event_list";

export const FEATURED_SELECTED_DATE = "/events/featured_selected_date";

export const CHECK_ADMIN = "/admins/check_admin";

export const ADD_REASON_FOR_REJECTION = "/admins/add_reason_for_rejection";

export const EXPRESS_EVENT_ESSENTIALS = "/events/express_event_essentials";

export const GET_EXPRESS_EVENT_ESSENTIALS = "/events/get_express_event_essentials";

export const EXPRESS_EVENT_ESSENTIALS_UPDATE = "/events/express_event_essentials_update";

export const EXPRESS_EVENT_GUEST = "/events/express_event_guest";

export const GET_EXPRESS_SPONSOR_PACKAGE = "/events/get_express_sponsor_package";

export const EXPRESS_EVENT_PITCH = "/events/express_event_pitch";

export const EXPRESS_SPONSOR_PACKAGE = "/events/express_sponsor_package";

export const EXPRESS_EVENT_MEDIA_HUB = "/events/express_event_media_hub";

export const GET_EXPRESS_EVENT_MEDIA_HUB = "/events/get_express_media_hub";

export const EXPRESS_SPONSOR_DETAILS_UPDATE = "/events/express_sponsor_details_update";

export const EXPRESS_SPONSOR_PACKAGE_UPDATE = "/events/express_sponsor_package_update";

export const GET_EVENT_BENEFIT = "/events/get_event_benefit";

export const EXPRESS_EVENT_ADD_REASON_FOR_REJECTION = "/admins/express_event_add_reason_for_rejection";

export const NEAR_BY_CUTTOFF_DATE = "/events/near_by_cuttoff_date";

export const DELETE_PAST_EVENT = "/past_event/delete_past_event";

export const PAST_EVENT_ADD_REASON_FOR_REJECTION = "/admins/past_event_add_reason_for_rejection";

export const GET_TRANSFER_USERS = "/events/transfer_user";