import { Delete_PastEvent_ImgDoc } from "../../../utils/createPastEventForm/createpasteventform";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import imageicon from "../../../assets/images/image-upload-icon.svg";
import Documenticon from "../../../assets/images/document-upload-icon.svg";
import pdfimg from "../../../assets/images/pdf.png";
import DeleteImageDocWarningModal from "../CreateEvent/DeleteImageDocWarningModal";

const PastEventMediaHubImageDoc=(props)=>{
    const {
        handleFileChangeDocument,
        handleFileChangeImage,
        images,
        setImages,
        showImages,
        EventmediaData,
        Messages,
        baseUrls,
        documents,
        setDocuments,
        showPdf
        }=props;

        const [showDeleteImageDocModal,setShowDeleteImageDocModal]=useState(false);  
        const [deleteImageDocModal,setDeleteImageDocModal]=useState({id:"",type:"",file:""});

        const handleRemoveImage = (idx) => {
          const val = images?.filter((item, index) => index !== idx);
          setImages(val);
        };

        const handleRemoveDocument = (idx) => {
          const val = documents.filter((item, index) => index !== idx);
          setDocuments(val);
        };

        const handleDltImageDocs = async (id) => {
            await Delete_PastEvent_ImgDoc(id).then((response) => {
              if (response?.data?.success) {
                 EventmediaData();
                 Messages(response?.data?.message);
                 setShowDeleteImageDocModal(false);
              }
           });
        };

        const handleDeleteImgDoc=(id,type,file)=>{
            setShowDeleteImageDocModal(true);
            setDeleteImageDocModal({id:id, type:type, file:file})
          }

        const deleteImgDoc=()=>{
            if(deleteImageDocModal?.file == 'image'){
              if(deleteImageDocModal?.type == 'local'){
                handleRemoveImage(deleteImageDocModal?.id)
                setShowDeleteImageDocModal(false)
              }else{
                handleDltImageDocs(deleteImageDocModal?.id)
              }
            }else{
              if(deleteImageDocModal?.type == 'local'){
                handleRemoveDocument(deleteImageDocModal?.id) 
                setShowDeleteImageDocModal(false)
              }else{
                handleDltImageDocs(deleteImageDocModal?.id)
              }
            }
          }
    

    return(<>

       {
        showDeleteImageDocModal && <DeleteImageDocWarningModal msg={deleteImageDocModal?.file} setShowDeleteImageDocModal={setShowDeleteImageDocModal}
        deleteImgDoc={deleteImgDoc}/>
       }
        <div className="mt-4">
          <div className="row">
            <h4 className="form-heading">
              Images (Maximum 6)
              <IoMdInformationCircleOutline
                size={16}
                color="black"
                title="Add additional images to showcase different aspects of your event."
              />
            </h4>

            <div className="d-flex flex-column align-items-center col-md-12">
              <label>
                <img className="upload-icon" src={imageicon} alt="img-upload" />
                <input
                  className="d-none"
                  type="file"
                   accept="image/*"
                  multiple
                  onChange={handleFileChangeImage}
                />
              </label>
              <div className="">The maximunm size of an image can be 1 MB</div>
              {/* <div className="pt-3">
                <button className="btn Addlink-btn ms-4">Add Images</button>
              </div> */}
            </div>
            <div className="row mt-3">
              {images?.map((file,index) => (
                <div key={index} className="col-4 col-md-2 position-relative py-1 px-1">
                  <button
                    className="image-remove-icon"
                    onClick={() => handleDeleteImgDoc(index,"local","image")}>
                    <MdDelete size={20} color="white" />
                  </button>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`selected-${index}`}
                    className="event-gellary-img"
                  />
                </div>
              ))}
              {showImages?.map((val, index) => {
                return (
                  <>
                    <div className="col-4 col-md-2 position-relative px-1 py-1" key={index}>
                      <button className="image-remove-icon"
                        onClick={()=>handleDeleteImgDoc(val?.id,"dynamic","image")}>
                        <MdDelete size={20} color="white" />
                      </button>
                      <img
                        className="event-gellary-img"
                        src={`${baseUrls?.event_image_url}/${val?.filename}`}
                        alt="img1"
                      />
                    </div>
                  </>
                );
              })}
            </div>
            {/* {errors.imagess && <span className="error">{errors.imagess}</span>} */}
          </div>
        </div>

        <div className="">
          <div className="row mt-4">
            <h4 className="form-heading">
              Documents (Maximum 3)
              <IoMdInformationCircleOutline
                size={16}
                color="black"
                title="Upload any documents related to the event."
              />
            </h4>

            <div className="d-flex flex-column align-items-center col-md-12">
              <label className="">
                <img
                  className="upload-icon"
                  src={Documenticon}
                  alt="img-upload"
                />
                <input
                  className="d-none"
                  type="file"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                  multiple
                  onChange={handleFileChangeDocument}
                />
              </label>
              <div className="">The maximunm size of a file can be 4 MB</div>
              {/* <div className="pt-3">
                          <button className="btn Addlink-btn ms-4">Add Documents</button>
              </div> */}
            </div>
            <div className="row mt-3 mb-3">
              {documents?.map((file, index) => (
                <div className="px-1 py-1 col-4 col-md-2 position-relative" key={index}>
                  <button
                    className="image-remove-icon"
                    onClick={() => handleDeleteImgDoc(index,"local","document")}>
                    <MdDelete size={20} color="white" />
                  </button>
                  <div className="pdf-div">
                    <div className="d-flex justify-content-center">
                    <img src={pdfimg} alt="pdf" sizes={{height:60 }} />
                    </div>
                    <p className="pdf-title">{file?.name}</p>
                  </div>
                </div>
              ))}
              {showPdf?.map((val, index) => {
                return (
                  <div
                    className="px-1 position-relative py-1 col-4 col-md-2"
                    key={index}>
                    <button
                      className="image-remove-icon"
                      onClick={()=>handleDeleteImgDoc(val?.id,"dynamic","document")}>
                      <MdDelete size={20} color="white" />
                    </button>
                    <div className="pdf-div">
                      <div className="d-flex justify-content-center">
                      <img src={pdfimg} alt="pdf" sizes={{height:60 }} />
                      </div>
                      <p className="pdf-title">{val?.filename}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* {errors.documentss && (
              <span className="error">{errors.documentss}</span>
            )} */}
          </div>
        </div>
  </>)
}

export default PastEventMediaHubImageDoc;