import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import facebook from "../../assets/images/facebook-icon.png";
import linkedin from "../../assets/images/linkedin-icon.png";
import google from "../../assets/images/google-icon.png";
import instagram from "../../assets/images/icons8-instagram-48.png";
import twitter from "../../assets/images/icons8-twitter-50 (1).png";
import { logActivityCheck, pass_systemIpAddress, registerUserData, systemIpAddress } from "../../utils/user/UserManager";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginmessage } from "../../redux/Login";
import PhoneInput from "react-phone-input-2";
import OTPInput from "react-otp-input";
import { IoIosCheckmarkCircle, IoMdSend } from "react-icons/io";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Email_Otp_Verify_Final,
  Email_Verify_Otp,
  Generate_Number_Otp,
  Number_Otp_Verify_Final,
  Signup_GenerateEmailOtp,
  Signup_GenerateNumberOtp,
} from "../../utils/Otpverify/OtpVerify";
import ReCAPTCHA from "react-google-recaptcha";
import { MdOutlineVerified } from "react-icons/md";
import { SignupEmailOtpVerifyFinal, SignupNumberOtpVerifyFinal } from "../../Services/Apis";
import TermsAndPrivacyPolicy from "../Common/TermsAndPrivacyPopUp";
import PrivacyPolicyPopup from "../Common/PrivacyPolicyPopup";
import {
  isMobile,
  isTablet,
  isDesktop,
  isAndroid,
  isIOS,
} from 'react-device-detect';
import { Tooltip } from "react-tooltip";

const UserSignUpForm = () => {

  const [signup, setSignup] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    referral_code: "",
    country_code: "",
    i_want_to: ""
  });

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileForm, setMobileForm] = useState({
    dialingcode: "",
    mobilenumber: "",
  });
  const [showSendBtn, setShowSendBtn] = useState(false);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showEmailOtpSection, setShowEmailOtpSection] = useState(false);
  const [showEmailBtn, setShowEmailBtn] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState("");

  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [checkPhone, setCheckPhone] = useState("");

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [termsPolicy, setTermsPolicy] = useState(false)
  const [sendEmailOtpBtnLoading, setSendEmailOtpBtnLoading] = useState(false);
  const [sendPhoneOtpBtnLoading, setSendPhoneOtpBtnLoading] = useState(false);
  const [verifiedEmailOtp, setVerifiedEmailOtp] = useState(false);
  const [verifiedPhoneOtp, setVerifiedPhoneOtp] = useState(false);

  const [TermsPolicyPopUp, setTermsPolicyPopUp] = useState(false);
  const [privacyPolicyPopUp, setPrivacyPolicyPopUp] = useState(false);

  const [emailCountdown, setEmailCountdown] = useState(0);
  const [reEmailSendOtp, setReEmailSendOtp] = useState(false);

  const [phoneCountdown, setPhoneCountdown] = useState(0);
  const [rePhoneSendOtp, setRePhoneSendOtp] = useState(false)

  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading } = useAuth0();

  const TermsAndPrivacyPolicyData = useSelector(state => state?.config_data?.configData)
  const termsData = TermsAndPrivacyPolicyData['terms_&_conditions']
  const privacypolicy = TermsAndPrivacyPolicyData['privacy_policy']

  const [informationIp, setInformationIp] = useState({});
  let otpTimer = null;

  const deviceType = {
    type: isMobile ? "Mobile" : isTablet ? "Tablet" : isDesktop ? "Desktop" : "Unknown",
    os: isAndroid ? "Android" : isIOS ? "iOS" : "Other"
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setSignup({
      ...signup,
      [name]: value,
    });
  };

  // console.log("signup",signup)

  const handleUserSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await registerUserData(signup);
      const token = response?.data?.token;
      const message = response?.data?.message;

      dispatch(loginmessage({ userType: 'user', message: message }));
      // dispatch(loginmessage(message));
      if (token) {
        localStorage.setItem("LoginToken", token);
        navigate(`/editprofile/${response?.data?.user_id}`);
        setPrivacyPolicyPopUp(false)
        loggedActivity();
      }
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!signup.f_name || !signup.f_name?.trim() === "") {
      newErrors.f_name = "First name is required";
    }

    if (!signup.l_name || !signup.l_name?.trim() === "") {
      newErrors.l_name = "Last name is required";
    }

    if (!signup.email || !signup.email?.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(signup.email)) {
      newErrors.email = "Email is invalid";
    } else {
      newErrors.email = "";
    }

    if (!signup.phone || !signup.phone?.trim() === "") {
      newErrors.phone = "Mobile is required";
    } else if (signup.phone.trim().length < 10) {
      newErrors.phone = "Mobile should be at least 10 characters long";
    } else {
      newErrors.phone = "";
    }

    if (signup.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    } else if (!/[A-Z]/.test(signup.password)) {
      newErrors.password = "Password must contain at least one uppercase letter.";
    } else if (!/[a-z]/.test(signup.password)) {
      newErrors.password = "Password must contain at least one lowercase letter.";
    } else if (!/[0-9]/.test(signup.password)) {
      newErrors.password = "Password must contain at least one number.";
    } else if (!/[!@#$%^&*]/.test(signup.password)) {
      newErrors.password = "Password must contain at least one special character.";
    }
    // else if (/(.)\1{2,}/.test(signup.password)) {
    //   newErrors.password = "Your password must not contain more than 2 consecutive identical characters.";
    // } 
    else {
      newErrors.password = "";
    }


    if (!signup.confirm_password || !signup.confirm_password.trim() === "") {
      newErrors.confirm_password = "Confirm password is required";
    } else if (signup.password !== signup?.confirm_password) {
      newErrors.confirm_password = "Passwords do not match.";
    } else {
      newErrors.confirm_password = "";
    }

    if (!signup.i_want_to || !signup.i_want_to?.trim() === "") {
      newErrors.i_want_to = "User Type is required";
    }


    // if (!signup.password || !signup.password.trim() === "") {
    //   newErrors.password = "Password is required";
    // } else if (signup.password.trim().length < 8) {
    //   newErrors.password = "Password should be at least 8 characters long";
    // } else {
    //   newErrors.password = "";
    // }

    // if (!signup.confirm_password || !signup.confirm_password.trim() === "") {
    //   newErrors.confirm_password = "Confirm password is required";
    // } else if (signup.confirm_password !== signup.password) {
    //   newErrors.confirm_password = "Passwords do not match";
    // } else {
    //   newErrors.confirm_password = "";
    // }


    // if (!signup.password || !signup.password.trim() === "") {
    //   newErrors.password = "Password is required";
    // } else if (signup.password.trim().length < 8) {
    //   newErrors.password = "Password should be at least 8 characters long";
    // } else {
    //   newErrors.password = "";
    // }

    // if (!signup.confirm_password || !signup.confirm_password.trim() === "") {
    //   newErrors.confirm_password = "Confirm password is required";
    // } else if (signup.confirm_password !== signup.password) {
    //   newErrors.confirm_password = "Passwords do not match";
    // } else {
    //   newErrors.confirm_password = "";
    // }
    setErrors(newErrors);

    if (
      newErrors.email ||
      newErrors.phone ||
      newErrors.f_name ||
      newErrors.l_name ||
      newErrors.password ||
      newErrors.confirm_password
    ) {
      return;
    } else {
      if (!isCaptchaVerified) {
        toast.error(
          "reCAPTCHA not verified. Please verify before signing up.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        }
        );
        return;
      }
      else if (!verifiedEmailOtp) {
        toast.error(
          "Email not verified. Please verify before signing up", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        })
      } else if (!verifiedPhoneOtp) {
        toast.error(
          "Mobile number not verified. Please verify before signing up", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        })
      }
      else {
        toast.dismiss();
        setTermsPolicyPopUp(!TermsPolicyPopUp);
      }
    }
  };

  const handleOnChange = (value, country) => {
    const phoneNum = value?.slice(2);
    setSignup({
      ...signup,
      country_code: `${country.format.charAt(0)}${country?.dialCode}`,
      phone: phoneNum,
    });
    setMobileForm({
      dialingcode: `${country.format.charAt(0)}${country?.dialCode}`,
      mobilenumber: phoneNum,
    });
    if (value.length >= 10 && checkPhone !== phoneNum) {
      setShowSendBtn(true);
    } else {
      setShowSendBtn(false);
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setSignup({
      ...signup,
      email: value,
    });

    setInputEmail(value);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(value);

    if (isValidEmail && value !== checkEmail) {
      setShowEmailBtn(true);
    } else {
      setShowEmailBtn(false);
    }
  };

  // Email genrate otp
  const handleSendEmailOtp = async () => {
    const data = {
      email: inputEmail,
    };
    setCheckEmail(signup?.email)
    try {
      setSendEmailOtpBtnLoading(true);
      await Signup_GenerateEmailOtp(data).then((response) => {
        if (response.data.success) {
          // setEmailOtp(response?.data?.otp);
          setShowEmailOtpSection(true);
          setVerifiedEmailOtp(false);
          setShowEmailBtn(false);
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
          });
          setEmailCountdown(300); // 5 minutes countdown
          setReEmailSendOtp(false);
          // Start countdown
          const timer = setInterval(() => {
            setEmailCountdown((prevCountdown) => {
              if (prevCountdown <= 1) {
                clearInterval(timer);
                setEmailOtp(null);
                setVerifiedEmailOtp(true);
                setShowEmailOtpSection(false);
                setReEmailSendOtp(true)
                toast.info("OTP has expired. Please request a new one.", {
                  position: "top-center",
                  autoClose: 3000,
                });
                return 0;
              }
              return prevCountdown - 1;
            });
          }, 1000);
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
    } finally {
      setSendEmailOtpBtnLoading(false);
    }
  };

  //email verify otp
  const VerifyEmail = async () => {
    const data = {
      email: inputEmail,
      otp: emailOtp,
    };

    try {
      SignupEmailOtpVerifyFinal(data).then((response) => {
        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          setVerifiedEmailOtp(true)
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
    }
  };


  // number genrate otp
  const handleSendPhoneOtp = async () => {
    const data = {
      phone: mobileForm?.mobilenumber,
      country_code: mobileForm?.dialingcode,
    };
    setCheckPhone(signup.phone)
    try {
      setSendPhoneOtpBtnLoading(true)
      await Signup_GenerateNumberOtp(data).then((response) => {
        if (response.data.success) {
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          setPhoneOtp(response?.data?.otp);
          setShowOtpSection(true);
          setVerifiedPhoneOtp(false)
          setShowSendBtn(false);

          setRePhoneSendOtp(false)
          setPhoneCountdown(300); // 5 minutes countdown
          // Start countdown
        otpTimer = setInterval(() => {
            setPhoneCountdown((prevCountdown) => {
              if (prevCountdown <= 1) {
                clearInterval(otpTimer);
                setPhoneOtp(null);
                setVerifiedPhoneOtp(false);
                setShowOtpSection(false);
                toast.info("OTP has expired. Please request a new one.", {
                  position: "top-center",
                  autoClose: 3000,
                });
                return 0;
              }
              return prevCountdown - 1;
            });
          }, 1000);
        
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
    } finally {
      setSendPhoneOtpBtnLoading(false)
    }
  };

  // number verify otp
  const VerifyNumber = async () => {
    const data = {
      phone: mobileForm?.mobilenumber,
      country_code: mobileForm?.dialingcode,
      otp: phoneOtp,
    };

    try {
      SignupNumberOtpVerifyFinal(data).then((response) => {
        if (response?.data?.success) {
          setVerifiedPhoneOtp(true);
          clearInterval(otpTimer);
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
    }
  };
  // log activity-------------------------

  const SystemIp = async () => {
    try {
      await systemIpAddress()
        .then((response) => {
          passIpAddress(response?.data?.ip)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const passIpAddress = async (ipv4) => {
    try {
      await pass_systemIpAddress(ipv4)
        .then((response) => {
          setInformationIp(response?.data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    SystemIp();
  }, [])

  const loggedActivity = async () => {

    const data = {
      ip_address: informationIp?.ip,
      lat: informationIp?.latitude,
      long: informationIp?.longitude,
      log_type: isAuthenticated ? "socialLogin" : 'login',
      device_type: JSON.stringify(deviceType),
      city: informationIp?.city,
      country_code: informationIp?.country_code,
      region_code: informationIp?.region_code
    }
    try {
      await logActivityCheck(data)
    } catch (err) {
      console.log(err)
    }
  }

  // log activity-------------------------

  const handleGoogleLogin = useCallback(() => {
    loginWithRedirect();
    localStorage.setItem('signUpUserType', 'user')
  }, [loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      loggedActivity(informationIp);
    }
  }, [isAuthenticated, isLoading, informationIp]);


  const handleRecaptchaChange = (value) => {
    setIsCaptchaVerified(true);
  };

  const handleCheckboxChange = () => {
    setTermsPolicy(!termsPolicy)
  }

  // console.log("signup",signup)

  return (
    <>
      {/* <div className=" d-flex flex-column align-items-md-center">
            <div className="d-flex flex-column gap-4 mt-4">
              <button className="login-with-google" onClick={handleGoogleLogin}>
                <img className="login-btn-icon" src={google} alt="linkedin" />
                Continue with Google
              </button>
              <button className="login-with-twitter">
                <img className="login-btn-icon" src={twitter} alt="twitter" />
                Continue with X
              </button>
              <button className="login-with-instagram">
                <img
                  className="login-btn-icon"
                  src={instagram}
                  alt="instagram"
                />
                Continue with Instagram
              </button>
              <button className="login-with-linkedin" onClick={handleGoogleLogin}>
                <img className="login-btn-icon" src={linkedin} alt="linkedin" />
                Continue with Linkedin
              </button>
              <button className="login-with-facebook" onClick={handleGoogleLogin}>
                <img className="login-btn-icon" src={facebook} alt="linkedin" />
                Continue with Facebook
              </button>
            </div>
          </div> */}
      <Tooltip id="password-example-tooltip" className="tooltip_style" >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <span className="tooltip-password-head">Password must contain:</span>
          <span className="mt-2">-At least one uppercase letter </span>
          <span>-At least one lowercase letter</span>
          <span>-At least one special character</span>
          <span>-At least one number</span>
          <span>-Be at least 8 characters</span>
          {/* <span>-Your password must not contain more than 2 consecutive identical characters.</span> */}
        </div>
      </Tooltip>

      {
        TermsPolicyPopUp && <TermsAndPrivacyPolicy pageData={termsData} usertype="user" loading={loading}
          setTermsPolicyPopUp={setTermsPolicyPopUp} setPrivacyPolicyPopUp={setPrivacyPolicyPopUp} />
      }

      {
        privacyPolicyPopUp && <PrivacyPolicyPopup usertype="user" loading={loading} privacypolicy={privacypolicy}
          handleUserSignUp={handleUserSignUp} setPrivacyPolicyPopUp={setPrivacyPolicyPopUp} />
      }

      <div className="row mt-2">
        {/* <div className="col-12 col-sm-6 col-lg-4 order-2 order-sm-1">
          <hr className="d-sm-none" />
          <p className="login-p">Sign Up With Social Account</p>
          
          <div className=" d-flex flex-column align-items-md-center">
            <div className="d-flex flex-column gap-4 mt-4">
              <button className="login-with-google" onClick={handleGoogleLogin}>
                <img className="login-btn-icon" src={google} alt="linkedin" />
                Social Authentication
              </button>
            </div>
          </div>
        </div> */}

        {/* <div className="d-none d-lg-flex col-1 justify-content-center order-sm-2">
          <div className="row justify-content-center">
            <div className="center-portion d-flex justify-content-center">
              <div className="center-abs center-line-1 "></div>
              <div className="center-abs or-div">
                <span className="or-text">OR</span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-12">
          <div className="">
            {/* <div className="">
              <p className="login-p m-0">Sign Up</p>
            </div> */}
          </div>
          <form onSubmit={onSubmit}>
            <div className="pt-3">
              <div className="row">
                <div className="col-12 col-lg-6 form-group ">
                  <label className="login-label">
                    First Name<span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-3"
                    placeholder="Enter First Name"
                    name="f_name"
                    value={signup.f_name}
                    onChange={handleInputChange}
                  />
                  {errors.f_name && (
                    <span
                      className=""
                      style={{ color: "red", fontSize: "13px" }}
                    >
                      {errors.f_name}
                    </span>
                  )}
                </div>
                <div className="col-12 col-lg-6 form-group ">
                  <label className="login-label">
                    Last Name<span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-3"
                    placeholder="Enter Last Name"
                    name="l_name"
                    value={signup.l_name}
                    onChange={handleInputChange}
                  />
                  {errors.l_name && (
                    <span
                      className=""
                      style={{ color: "red", fontSize: "13px" }} >
                      {errors.l_name}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 form-group ">
                  <label className="login-label">
                    Email Address<span className="asterisk">*</span>
                  </label>
                  <div className="position-relative d-flex align-items-center">
                    <input
                      type="email"
                      className="form-control rounded-3"
                      placeholder="Enter Email Address"
                      name="email"
                      value={signup.email}
                      // onChange={handleInputChange}
                      onChange={handleEmailChange}
                    />
                    {showEmailBtn && (
                      <button
                        className="otp-send-btn"
                        type="button"
                        onClick={handleSendEmailOtp}
                      >
                        {
                          sendEmailOtpBtnLoading ?
                            <div className="spinner-border text-danger" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <IoMdSend size={24} color="#FF2D55" />
                        }
                      </button>
                    )}


                    {reEmailSendOtp && (
                      <button
                        className="verify-otp"
                        type="button"
                        onClick={handleSendEmailOtp} >
                        {
                          sendEmailOtpBtnLoading &&
                          <div className="spinner-border text-light" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        }
                        Resend OTP
                      </button>
                    )}
                  </div>

                  {errors.email && (
                    <span
                      className=""
                      style={{ color: "red", fontSize: "13px" }}>
                      {errors.email}
                    </span>
                  )}

                  <div>
                    {showEmailOtpSection && (
                      <div className="d-flex gap-2 align-items-center position-relative mt-2">
                        <div className="d-flex gap-1">
                          <label className="login-label mb-0">
                            OTP<span className="asterisk">*</span>
                          </label>
                          <label className="login-label mb-0">:</label>
                        </div>
                        <input
                          type="text"
                          className="otp-input form-control rounded-3"
                          placeholder="Enter OTP"
                          //name="otp"
                          value={emailOtp}
                          onChange={(e) => setEmailOtp(e.target.value)}
                        />
                        <div className="">
                          {
                            verifiedEmailOtp ?
                              <div><MdOutlineVerified size={24} color="green" className="signup-verified mt-1" /></div> :
                              <button className="signup-verify-otp" type="button" onClick={VerifyEmail}>
                                Verify
                              </button>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                  {emailCountdown > 0 && !verifiedEmailOtp && (
                    <p className="mt-2">OTP expires in: {Math.floor(emailCountdown / 60)}:{emailCountdown % 60 < 10 ? `0${emailCountdown % 60}` : emailCountdown % 60} minutes</p>
                  )}
                </div>

                <div className="col-12 col-lg-6 form-group">
                  <label className="login-label">
                    Mobile<span className="asterisk">*</span>
                  </label>
                  <div className="">
                    <div className="position-relative d-flex align-items-center">
                      <div className="col-12">
                        <PhoneInput
                          className=""
                          country={"in"}
                          onChange={handleOnChange}
                        />
                      </div>
                      {showSendBtn && (
                        <button
                          className="otp-send-btn"
                          type="button"
                          onClick={handleSendPhoneOtp}
                        >
                          {
                            sendPhoneOtpBtnLoading ?
                              <div className="spinner-border text-danger" role="status">
                                <span className="sr-only">Loading...</span>
                              </div> :
                              <IoMdSend size={24} color="#FF2D55" />
                          }
                        </button>
                      )}

                      {rePhoneSendOtp && (
                        <button
                          className="verify-otp"
                          type="button"
                          onClick={handleSendPhoneOtp}
                        >
                          {
                            sendPhoneOtpBtnLoading &&
                            <div className="spinner-border text-light" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          }
                          Resend OTP
                        </button>
                      )}
                    </div>
                    {errors.phone && (
                      <span
                        className=""
                        style={{ color: "red", fontSize: "13px" }} >
                        {errors.phone}
                      </span>
                    )}
                  </div>

                  <div>
                    {showOtpSection && (
                      <div className="d-flex gap-2 align-items-center position-relative mt-2">
                        <div className="d-flex gap-1">
                          <label className="login-label mb-0">
                            OTP<span className="asterisk">*</span>
                          </label>
                          <label className="login-label mb-0">:</label>
                        </div>
                        <input
                          type="text"
                          className="otp-input rounded-3 form-control"
                          placeholder="Enter OTP"
                          // name="otp"
                          value={phoneOtp}
                          onChange={(e) => setPhoneOtp(e.target.value)}
                        />
                        <div className="">
                          {/* <IoIosCheckmarkCircle color="green" size={18} /> */}
                          {
                            verifiedPhoneOtp ?
                              <div><MdOutlineVerified size={24} color="green" className="signup-verified mt-1" /></div>
                              :
                              <button className="signup-verify-otp" type="button" onClick={VerifyNumber}>
                                Verify
                              </button>
                          }

                        </div>
                      </div>
                    )}
                  </div>
                  <div>
      
      {phoneCountdown > 0 && !verifiedPhoneOtp && (
        <p className="mt-2">
          OTP expires in: {Math.floor(phoneCountdown / 60)}:
          {phoneCountdown % 60 < 10 ? `0${phoneCountdown % 60}` : phoneCountdown % 60} minutes
        </p>
      )}

      {/* Other sections, including OTP input and verify button */}
    </div>
                </div>

              </div>

              <div className="row">
                <div className="col-12 col-lg-6 form-group ">
                  <label className="login-label">
                    Password<span className="asterisk">*</span>
                  </label>
                  <input
                    data-tooltip-id="password-example-tooltip"
                    type="password"
                    className="form-control rounded-3"
                    placeholder="Enter Password"
                    name="password"
                    value={signup.password}
                    onChange={handleInputChange}
                  />
                  {errors.password && (
                    <span className=""
                      style={{ color: "red", fontSize: "13px" }}>
                      {errors.password}
                    </span>
                  )}
                </div>
                <div className="col-12 col-lg-6 form-group ">
                  <label className="login-label">
                    Confirm Password<span className="asterisk">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control rounded-3"
                    placeholder="Enter Confirm Password"
                    name="confirm_password"
                    value={signup.confirm_password}
                    onChange={handleInputChange}
                  />
                  {errors.confirm_password && (
                    <span
                      className=""
                      style={{ color: "red", fontSize: "13px" }}
                    >
                      {errors.confirm_password}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 form-group">
                  <label className="login-label">Referral Code (optional)</label>
                  <input
                    type="text"
                    className="form-control rounded-3"
                    placeholder="Enter Referral Code"
                    name="referral_code"
                    value={signup.referral_code}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-12 col-md-3">
                  <label className="login-label m-0">I Want To Become a : <span className="asterisk">*</span></label>
                  <div className="d-flex gap-3 mt-2">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="i_want_to"
                          value="is_host"
                          checked={signup?.i_want_to == 'is_host'}
                          onChange={handleInputChange}
                        />
                        Host
                      </label>
                    </div>
                    <div class="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="i_want_to"
                          value="is_sponsor"
                          checked={signup?.i_want_to == 'is_sponsor'}
                          onChange={handleInputChange}
                        />
                        Sponsor
                      </label>
                    </div>
                  </div>

                  {errors.i_want_to && (
                    <span
                      className=""
                      style={{ color: "red", fontSize: "13px" }} >
                      {errors.i_want_to}
                    </span>
                  )}
                </div>

              </div>

              <div className="col-12 col-lg-6 form-group">
                <ReCAPTCHA
                  sitekey="6Ld7FrkpAAAAAA5J3w-GrdiyOxLOypYIXLcypa1L"
                  onChange={handleRecaptchaChange}
                  onExpired={() => setIsCaptchaVerified(false)}
                />
              </div>

              {/* <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  checked={termsPolicy}
                  onChange={handleCheckboxChange} className="mt-2" />
                <p className="By-signing-up-you-ag d-flex gap-1">
                  By signing up you agree to the
                  <span className="text-style-1">
                    <Link to={`/${"terms_&_conditions"}`} className="span-link">
                      Terms of Use
                    </Link>
                  </span>
                  and
                  <span className="text-style-1">
                    <Link to={`/${"privacy_policy"}`} className="span-link">
                      Privacy Policy
                    </Link>
                  </span>
                </p>
              </div> */}

              <div className="col-6">
                <div className="form-group">
                  <button type="submit" className="btn login-btn col-12 mt-2">
                    {loading ? (
                      <div className="spinner-border  text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "")}
                    Sign Up
                  </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default UserSignUpForm;
