import { useEffect, useRef, useState } from "react";
import FilterIcon from "../../assets/images/filter-icon.svg";
import sorticon from "../../assets/images/sort-icon.svg";
import EventsCard from "../../components/Events/EventsCard";
import { Get_CityList, Get_EducationList, Get_HouseholdIncome, Get_LanguageList, Getinterests, get_events_list } from "../../utils/event/GetEventList";
import Loader from "../../components/Loader/Loader";
import { BsFillGridFill } from "react-icons/bs";
import { FaListUl, FaLongArrowAltDown, FaLongArrowAltUp, FaRegCalendarAlt } from "react-icons/fa";
import EventsListCard from "../../components/Events/EventsListCard";
import {
  Get_UserInterest,
  getEventTopicData,
  getEventTypeData,
} from "../../utils/basicInformation/basicinformationManager";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import ReactPaginate from "react-paginate";
import Loader2 from "../../components/Loader/Loader2";
import downarrrow from "../../assets/images/ic-downarrow.png";
import uparrow from "../../assets/images/ic-uparrow.png";
import { isTokenValid } from "../../AuthbyToken";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import EventSortSection from "../../components/Events/EventSortSection";
import EventFilterSection from "../../components/Events/EventFilterSection";
import { CiFilter } from "react-icons/ci";
import { MdFilterListAlt, MdOutlineSort } from "react-icons/md";
import { useSelector } from "react-redux";
import { GetEventBudget } from "../../utils/CreateEvents/createEvent";

const Events = () => {
  const [cardView, setCardView] = useState("grid");
  const [eventList, setEventList] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [search_event, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [type, setType] = useState([]);
  const [eventRange, setEventRange] = useState({ min: "", max: "" });
  const [eventType, setEventType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSortVal] = useState("");
  const [eventMode, setEventMode] = useState("");
  const [eventCategory, setEventCategory] = useState("")
  const [status, setStatus] = useState("")
  const [no_Participants, setNo_Participants] = useState({ min: '', max: '' })
  const [srt_Evt_Date, setSrt_Evt_Date] = useState({ start: '', end: '' })
  const [minMaxAge, setMinMaxAge] = useState({ min: '', max: '' })
  const [distanceRange, setDistanceRange] = useState("")
  const [prime_host, setPrime_host] = useState('')
  const [premier, setPremier] = useState('')


  const [eventLoading, setEventLoading] = useState(true);
  // FilterDrop 
  const location = useLocation();
  const filterType = location?.state?.type;

  const [rangeDrop, setRangeDrop] = useState(true);
  const [typeDrop, setTypeDrop] = useState(true);
  const [categoriesDrop, setCategoriesDrop] = useState(true);
  const [loadingCard, setLoadingCard] = useState(false);
  const [eventModeDrop, setEventModeDrop] = useState(true);
  const [openFilterBar, setOpenFilterBar] = useState(false);
  const [openSortBar, setOpenSortBar] = useState(false);
  const [eventCategoryDrop, setEventCategoryDrop] = useState(true);
  const [statusDrop, setStatusDrop] = useState(true);
  const [no_ParticipantsDrop, setNo_ParticipantsDrop] = useState(true);
  const [srt_Evt_DateDrop, setSrt_Evt_DateDrop] = useState(true)
  const [genderDrop, setGenderDrop] = useState(true);
  const [GuestMaritalStatusDrop, setGuestMaritalStatusDrop] = useState(true);
  const [minAgeDrop, setMinAgeDrop] = useState(true);
  const [distanceDrop, setDistanceDrop] = useState(true);
  const [primeHostDrop, setPrimeHostDrop] = useState(true);
  const [premierDrop, setPremierDrop] = useState(true);
  const [cityDrop, setCityDrop] = useState(true);
  const [guestProfessionDrop, setGuestProfessionDrop] = useState(true);
  const [guestIndustryDrop, setGuestIndustryDrop] = useState(true);
  const [guestTechnologyProficiencyDrop, setGuestTechnologyProficiencyDrop] = useState(true);
  const [GuestSocialMediaUsageDrop, setGuestsSocialMediaUsageDrop] = useState(true);

  const [selectedGenders, setSelectedGenders] = useState('');

  const isValid = isTokenValid();
  const navigate = useNavigate();
  const params = useParams();
  const category = params?.category;

  const [interestDrop, setInterestDrop] = useState(filterType == "Interest" ? true : true);
  const [hastag, setHastag] = useState(filterType == "Interest" ? [category] : [])

  const [categories, setCategories] = useState(category);
  const [predefineHastag, setPredefineHastag] = useState([]);
  const [selectedHastags, setSelectedHastag] = useState([]);

  const [predefineCities, setPredefineCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [archiveValue, setArchiveValue] = useState(false)
  const [archiveDrop, setArchiveDrop] = useState(true);

  const [languageDrop, setLanguageDrop] = useState(true);
  const [predefineLanguage, setPredefineLanguage] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [SponsorshipDrop, setSponsorshipDrop] = useState(true);
  const [SponsorshipDate, setSponsorshipDate] = useState({ start: '', end: '' });
  const [SponsorshipClosingDate, setSponsorshipClosingDate] = useState({ start: '', end: '' });
  const [durationDrop, setDurationDrop] = useState(true);
  const [durationValue, setDurationValue] = useState('');
  const [house_Hold_IncomeDrop, setHouse_Hold_IncomeDrop] = useState(true);
  const [house_Hold_Income, setHouse_Hold_Income] = useState([]);
  const [selectHouse_Hold_Income, setSelectHouse_Hold_Income] = useState("");
  const [educationDrop, setEducationDrop] = useState(true);
  const [educationValue, setEducationValue] = useState([]);
  const [selectEducationValue, setSelectEducationValue] = useState("");
  const [eventBudget, setEventBudget] = useState([]);
  const [selectEventBudget, setSelectEventBudget] = useState("");
  const [eventBudgetValue, setEventBudgetValue] = useState("");
  const [rating, setRating] = useState(1);
  const [starRating, setStarRating] = useState('');
  const [ratingDrop, setRatingDrop] = useState(true);

  const [selectedGuestAge, setSelectedGuestAge] = useState([]);
  const [selectedGenderGroup, setSelectedGenderGroup] = useState([]);
  const [selectedGuestHouseIncome, setSelectedGuestHouseIncome] = useState([]);
  const [selectedGuestEducation, setSelectedGuestEducation] = useState([]);
  const [selectedGuestMaritalStatus, setSelectedGuestMaritalStatus] = useState([]);
  const [selectedGuestProfession, setSelectedGuestProfession] = useState([]);
  const [selectedGuestIndustry, setSelectedGuestIndustry] = useState([]);
  const [selectedGuestTechnologyProficiency, setSelectedGuestTechnologyProficiency] = useState([]);
  const [selectedGuestSocialMediaUsage, setSelectedGuestsSocialMediaUsage] = useState([]);

  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const [host_id, setHost_id] = useState("")

  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const GetEvent = async () => {
    const get_let_long = localStorage.getItem('let_long');
    const let_log = JSON.parse(get_let_long)
    const hastgg = JSON.stringify(hastag);
    const cityS = JSON.stringify(cities);
    const languages = JSON.stringify(language);

    const guestAge = JSON.stringify(selectedGuestAge);
    const genderGroup = JSON.stringify(selectedGenderGroup);
    const guestHouseIncome = JSON.stringify(selectedGuestHouseIncome);
    const guestEducation = JSON.stringify(selectedGuestEducation);
    const guestMaritalStatus = JSON.stringify(selectedGuestMaritalStatus);
    const guestProfession = JSON?.stringify(selectedGuestProfession);
    const guestIndustry = JSON?.stringify(selectedGuestIndustry);
    const guestTechnologyProficiency = JSON?.stringify(selectedGuestTechnologyProficiency);
    const guestSocialMediaUsage = JSON?.stringify(selectedGuestSocialMediaUsage);
    setLoadingCard(true);
    try {
      const list = await get_events_list(
        categories,
        eventMode,
        search_event,
        sortVal,
        eventRange,
        eventType,
        currentPage,
        let_log,
        eventCategory,
        status,
        hastgg,
        no_Participants,
        srt_Evt_Date,
        minMaxAge,
        distanceRange,
        prime_host,
        cityS,
        selectedGenders,
        languages,
        SponsorshipClosingDate,
        durationValue,
        premier,
        selectHouse_Hold_Income,
        selectEducationValue,
        category,
        eventBudgetValue,
        profileinfo?.id,
        starRating,
        guestAge,
        genderGroup,
        guestHouseIncome,
        guestEducation,
        guestMaritalStatus,
        guestProfession,
        guestIndustry,
        guestTechnologyProficiency,
        guestSocialMediaUsage,
        host_id
      )
        .then((data) => data?.data)
        .catch((error) => console.log(error));
      setEventList(list?.data);
      setLoading(false);
      setLoadingCard(false);
    } catch (error) {
      console.log(error);
    } finally {
      setEventLoading(false);
    }
  };

  useEffect(() => {
    GetEvent();
  }, [categories, eventMode, sortVal, eventRange, eventType, currentPage, eventCategory,
    status, hastag, no_Participants, srt_Evt_Date, minMaxAge, distanceRange, prime_host, cities, selectedGenders, language
    , SponsorshipClosingDate, durationValue, premier, selectHouse_Hold_Income, selectEducationValue, category, eventBudgetValue, profileinfo,
    starRating, selectedGuestAge, selectedGenderGroup, selectedGuestHouseIncome, selectedGuestEducation, selectedGuestMaritalStatus, selectedGuestProfession,
    selectedGuestIndustry, selectedGuestTechnologyProficiency, selectedGuestSocialMediaUsage, host_id]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (isSearch) {
      GetEvent();
      setIsSearch(false)
    } else {
      if (!search_event || !search_event.trim() === "") {
        setSearchError("Invalid search input");
      } else {
        setSearchError("");
        GetEvent();
        setIsSearch(true)
        setSearch("");
      }
    }

  };

  const selectedLanguageFilter = () => {
    const langNames = language?.map(name => name.toLowerCase());
    const filterCities = predefineLanguage?.filter(lag => langNames?.includes(lag.toLowerCase()));
    setSelectedLanguage(filterCities);
  }

  useEffect(() => {
    if (predefineLanguage) {
      selectedLanguageFilter()
    }
  }, [language, predefineLanguage]);


  useEffect(() => {
    if (archiveValue) {
      navigate("/events/archivedevents");
      setHost_id("");
    } else if (categories == "all") {
      navigate(`/events/${categories}`);
      setHost_id("");
    } else {
      // setCategories("all")
      navigate(`/events/${categories}`);
      setHost_id(categories)
    }
  }, [archiveValue,categories]);


  const selectedCityFilter = () => {
    const cityNames = cities?.map(id => id);
    const filterCities = predefineCities?.filter(tag => cityNames?.includes(tag?.city_id));
    setSelectedCities(filterCities);
  }

  useEffect(() => {
    if (predefineCities) {
      selectedCityFilter();
    }
  }, [cities, predefineCities]);

  const selectedHastagFilter = () => {
    const hastagIDs = hastag?.map(id => parseInt(id));
    const filterHas = predefineHastag?.filter(tag => hastagIDs?.includes(tag.id));
    setSelectedHastag(filterHas)
  }

  useEffect(() => {
    if (predefineHastag) {
      selectedHastagFilter()
    }
  }, [hastag, predefineHastag]);

  const getEventType = async () => {
    try {
      await getEventTypeData().then((response) => {
        setType(response?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getHastag = async () => {
    try {
      await Getinterests().then((response) => {
        const getInterest = response?.data?.data;
        setPredefineHastag(getInterest);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const GetLanguage = async () => {
    try {
      const response = await Get_LanguageList();
      setPredefineLanguage(response?.data?.data)
    } catch (err) {
      console.log("err", err)
    }
  }

  const getCity = async () => {
    try {
      const response = await Get_CityList();
      setPredefineCities(response?.data?.data)
    } catch (err) {
      console.log("err", err)
    }
  }

  const GetHouseholdIncome = async () => {
    try {
      const response = await Get_HouseholdIncome();
      setHouse_Hold_Income(response?.data?.data);
    } catch (err) {
      console.log(err)
    }
  }

  const GetEducationList = async () => {
    try {
      const response = await Get_EducationList();
      setEducationValue(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  const getEventBudgetList = async () => {
    try {
      await GetEventBudget().then((response) => {
        setEventBudget(response?.data?.data)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getEventType();
    getHastag();
    getCity();
    selectedHastagFilter();
    GetLanguage();
    GetHouseholdIncome();
    GetEducationList();
    getEventBudgetList()
  }, []);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearchChange = (e) => {
    const SVal = e.target.value;
    if (SVal?.length > 0) {
      setIsSearch(false)
    }
    setSearch(e.target.value)
  }

  useEffect(() => {
    // setCurrentPage(1);
    if (cardView == "list") {
      setOpenFilterBar(false)
    } else {
      setOpenFilterBar(true)
    }
  }, [cardView]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setOpenFilterBar(false);
      } else {
        setOpenFilterBar(true);
      }
    };

    // Call the function initially to set the state based on the current window width
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  return (<>
    {Loading ? (
      <Loader />
    ) : (
      <div className="events container-lg">
        <div className="row">
          <div className="col-9 col-sm-3 col-md-3">
            <h2 className="events-heading">{category == 'archivedevents' ? "Archived Events" : "Events"}</h2>
          </div>

          <div className="d-none d-sm-block col-sm-6 col-md-5">
            <form onSubmit={handleSearch}>
              <div className="d-flex align-items-center search-div position-relative">
                <input
                  className="event-search-input search-event-input"
                  name="search_event"
                  value={search_event}
                  placeholder="Search Events"
                  onChange={handleSearchChange} />
                {/* <button
                    className="d-flex align-items-center gap-1 event-search-btn"
                    type="submit">
                    Search
                  </button> */}
                <button className={isSearch ? "event-search-clear-button" : "event-search-button"} type="submit">
                  {isSearch ? 'Clear' : `Search`}
                  <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
                    <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                  </svg>
                </button>

              </div>
            </form>
            {searchError && (
              <p className="error-message text-danger ms-1">{searchError}</p>
            )}
          </div>

          {/* <div className="col-md-3 d-flex justify-content-xl-center justify-content-xxl-end pe-4">
              <div>
                {
                  category == 'archivedevents' ?
                    <Link
                      to={"/events/all"}
                      className="active-archived-btn">Archived
                    </Link>
                    :
                    <Link
                      to={"/events/archivedevents"}
                      className="archived-btn">Archived
                    </Link>
                }
              </div>
            </div> */}

          <div className="gap-2 col-3 col-md-4 d-flex justify-content-end gap-2 align-items-center">
            <div className="d-flex">
              <button
                className={
                  cardView == "grid"
                    ? "event-grid-btn-actv"
                    : "event-grid-btn"
                }
                onClick={() => { setCardView("grid"); setOpenFilterBar(true) }}>
                <BsFillGridFill size={24} />
              </button>
              <button
                className={
                  `${cardView == "list"
                    ? "event-list-btn-actv" : "event-list-btn"} d-none d-lg-block`}
                onClick={() => { setCardView("list"); setOpenFilterBar(false) }}>
                <FaListUl size={24} />
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-center d-sm-none col-md-6 mt-4 col-md-5">
            <div className="d-flex search-div">
              <input
                className="form-control search-event-input"
                name="search"
                value={search_event}
                placeholder="Search Events"
                onChange={(e) => setSearch(e.target.value)} />
              <button className="d-flex align-items-center gap-1 event-search-btn" onClick={handleSearch}>Search</button>
            </div>
            {searchError && (
              <p className="error-message text-danger ms-1">{searchError}</p>
            )}
          </div>
        </div>

        {/* Filter  Section */}
        <div className="mt-4">
          <hr className="w-100" />
          <div className="position-relative">
            <div className="row ">
              <div className={openFilterBar && cardView == "list" ? "col-md-4 col-xl-3" : "col-3"}>
                <div className="d-flex gap-2 mt-2">
                  <button className="side-filterbar-button d-block d-xl-none" onClick={() => { setOpenFilterBar(!openFilterBar); setOpenSortBar(false) }}>Filter <MdFilterListAlt /></button>
                  <button className="side-filterbar-button d-block d-xl-none" onClick={() => { setOpenSortBar(!openSortBar); setOpenFilterBar(false) }}>Sort by <MdOutlineSort /></button>
                </div>

                <div className="">
                  <button className={cardView == "list" ? `${openFilterBar ? "active-side-filter-button" : "side-filter-button"} d-none d-xl-block mt-2 d-block` : "d-none"}
                    onClick={() => setOpenFilterBar(!openFilterBar)}>Filter <MdFilterListAlt /></button>
                </div>

                <div className={`event-filter-sidebar ${openFilterBar ? 'open' : 'd-none'} ${cardView == "list" ? "mt-5" : "mt-0"}`}>
                  <aside className="d-lg-block">
                    <EventFilterSection
                      setCategoriesDrop={setCategoriesDrop}
                      categoriesDrop={categoriesDrop}
                      setCategories={setCategories}
                      categories={categories}
                      setEventModeDrop={setEventModeDrop}
                      eventMode={eventMode}
                      setEventMode={setEventMode}
                      eventModeDrop={eventModeDrop}
                      setTypeDrop={setTypeDrop}
                      typeDrop={typeDrop}
                      eventType={eventType}
                      setEventType={setEventType}
                      type={type}
                      setEventRange={setEventRange}
                      eventRange={eventRange}
                      GetEvent={GetEvent}
                      rangeDrop={rangeDrop}
                      setRangeDrop={setRangeDrop}
                      setSortVal={setSortVal}
                      setEventCategory={setEventCategory}
                      eventCategory={eventCategory}
                      eventCategoryDrop={eventCategoryDrop}
                      setEventCategoryDrop={setEventCategoryDrop}
                      status={status}
                      setStatus={setStatus}
                      statusDrop={statusDrop}
                      setStatusDrop={setStatusDrop}

                      interestDrop={interestDrop}
                      setInterestDrop={setInterestDrop}
                      category={categories}
                      setHastag={setHastag}
                      predefineHastag={predefineHastag}
                      hastag={hastag}
                      selectedHastags={selectedHastags}
                      no_Participants={no_Participants}
                      setNo_Participants={setNo_Participants}
                      no_ParticipantsDrop={no_ParticipantsDrop}
                      setNo_ParticipantsDrop={setNo_ParticipantsDrop}
                      srt_Evt_Date={srt_Evt_Date}
                      setSrt_Evt_Date={setSrt_Evt_Date}
                      srt_Evt_DateDrop={srt_Evt_DateDrop}
                      setSrt_Evt_DateDrop={setSrt_Evt_DateDrop}
                      minMaxAge={minMaxAge}
                      setMinMaxAge={setMinMaxAge}
                      minAgeDrop={minAgeDrop}
                      setMinAgeDrop={setMinAgeDrop}
                      distanceRange={distanceRange}
                      setDistanceRange={setDistanceRange}
                      distanceDrop={distanceDrop}
                      setDistanceDrop={setDistanceDrop}
                      prime_host={prime_host}
                      setPrime_host={setPrime_host}
                      primeHostDrop={primeHostDrop}
                      setPrimeHostDrop={setPrimeHostDrop}

                      premier={premier}
                      setPremier={setPremier}
                      premierDrop={premierDrop}
                      setPremierDrop={setPremierDrop}

                      //
                      genderDrop={genderDrop}
                      setGenderDrop={setGenderDrop}
                      GuestMaritalStatusDrop={GuestMaritalStatusDrop}
                      setGuestMaritalStatusDrop={setGuestMaritalStatusDrop}

                      cityDrop={cityDrop}
                      setCityDrop={setCityDrop}
                      predefineCities={predefineCities}
                      cities={cities}
                      setCities={setCities}
                      selectedCities={selectedCities}
                      selectedGenders={selectedGenders}
                      setSelectedGenders={setSelectedGenders}

                      predefineLanguage={predefineLanguage}
                      languageDrop={languageDrop}
                      setLanguageDrop={setLanguageDrop}
                      language={language}
                      setLanguage={setLanguage}
                      selectedLanguage={selectedLanguage}
                      SponsorshipDrop={SponsorshipDrop}
                      setSponsorshipDrop={setSponsorshipDrop}
                      SponsorshipDate={SponsorshipDate}
                      setSponsorshipDate={setSponsorshipDate}
                      setSponsorshipClosingDate={setSponsorshipClosingDate}
                      durationDrop={durationDrop}
                      setDurationDrop={setDurationDrop}
                      setDurationValue={setDurationValue}
                      durationValue={durationValue}
                      house_Hold_IncomeDrop={house_Hold_IncomeDrop}
                      setHouse_Hold_IncomeDrop={setHouse_Hold_IncomeDrop}
                      house_Hold_Income={house_Hold_Income}
                      selectHouse_Hold_Income={selectHouse_Hold_Income}
                      setSelectHouse_Hold_Income={setSelectHouse_Hold_Income}
                      educationDrop={educationDrop}
                      setEducationDrop={setEducationDrop}
                      educationValue={educationValue}
                      selectEducationValue={selectEducationValue}
                      setSelectEducationValue={setSelectEducationValue}
                      eventBudget={eventBudget}
                      selectEventBudget={selectEventBudget}
                      setSelectEventBudget={setSelectEventBudget}
                      eventBudgetValue={eventBudgetValue}
                      setEventBudgetValue={setEventBudgetValue}
                      ratingDrop={ratingDrop}
                      setRatingDrop={setRatingDrop}
                      starRating={starRating}
                      setStarRating={setStarRating}

                      archiveDrop={archiveDrop}
                      setArchiveDrop={setArchiveDrop}
                      archiveValue={archiveValue}
                      setArchiveValue={setArchiveValue}

                      selectedGuestAge={selectedGuestAge}
                      setSelectedGuestAge={setSelectedGuestAge}
                      selectedGenderGroup={selectedGenderGroup}
                      setSelectedGenderGroup={setSelectedGenderGroup}
                      selectedGuestHouseIncome={selectedGuestHouseIncome}
                      setSelectedGuestHouseIncome={setSelectedGuestHouseIncome}
                      selectedGuestEducation={selectedGuestEducation}
                      setSelectedGuestEducation={setSelectedGuestEducation}
                      selectedGuestMaritalStatus={selectedGuestMaritalStatus}
                      setSelectedGuestMaritalStatus={setSelectedGuestMaritalStatus}
                      guestProfessionDrop={guestProfessionDrop}
                      setGuestProfessionDrop={setGuestProfessionDrop}
                      selectedGuestProfession={selectedGuestProfession}
                      setSelectedGuestProfession={setSelectedGuestProfession}
                      guestIndustryDrop={guestIndustryDrop}
                      setGuestIndustryDrop={setGuestIndustryDrop}
                      selectedGuestIndustry={selectedGuestIndustry}
                      setSelectedGuestIndustry={setSelectedGuestIndustry}
                      guestTechnologyProficiencyDrop={guestTechnologyProficiencyDrop}
                      setGuestTechnologyProficiencyDrop={setGuestTechnologyProficiencyDrop}
                      selectedGuestTechnologyProficiency={selectedGuestTechnologyProficiency}
                      setSelectedGuestTechnologyProficiency={setSelectedGuestTechnologyProficiency}
                      GuestSocialMediaUsageDrop={GuestSocialMediaUsageDrop}
                      setGuestsSocialMediaUsageDrop={setGuestsSocialMediaUsageDrop}
                      selectedGuestSocialMediaUsage={selectedGuestSocialMediaUsage}
                      setSelectedGuestsSocialMediaUsage={setSelectedGuestsSocialMediaUsage}
                      setHost_id={setHost_id}
                    />
                  </aside>
                </div>

                <div className={`d-xl-none event-filter-sidebar  ${openSortBar ? "open" : "d-none"}`}>
                  <div className="filter-sidebar bg-white">
                    <div>
                      <div className="filter-content-div d-flex justify-content-between align-items-center">
                        <p className="filter-heading-text mb-0">Sort by</p>
                      </div>
                      <hr className="m-0" />
                      <EventSortSection sortVal={sortVal} setSortVal={setSortVal} />
                    </div>
                  </div>
                </div>
                {/* mobileSortbar */}
              </div>

              <div className={cardView == "grid" || openFilterBar ? "col-md-12 col-xl-9 event-card-div" : "col-md-12"}>
                <div>
                  {/* Sort Section*/}
                  <div className="d-none d-xl-flex gap-3 align-items-center justify-content-end">

                    <EventSortSection
                      sortVal={sortVal}
                      setSortVal={setSortVal}
                    />
                  </div>
                  <hr className="mt-4 d-none d-xl-none" />

                </div>
                {eventList?.data.length <= 0 && (
                  <h3 className="mt-3">No Data Found</h3>
                )}
                <div className="row">
                  {cardView == "list" ? (
                    <>
                      {/* <div>
                        <div className="list-head-div mt-4">
                          <div>
                            <div className="row justify-content-center align-items-center">
                              <div className="col-md-2">
                                <p className="listcard-head-title">Image</p>
                              </div>

                              <div className="col-md-2">
                                <p className="listcard-head-title">Title</p>
                              </div>

                              <div className="col-md-2">
                                <p className="listcard-head-title">Date</p>
                              </div>

                              <div className="col-md-3">
                                <p className="listcard-head-title">Location</p>
                              </div>

                              <div className="col-md-2">
                                <p className="listcard-head-title">Status</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {eventLoading ? (
                          <Loader2 />
                         ) : (
                          eventList?.data &&
                          eventList?.data?.map((val) => {
                            return (
                              <div className="col-md-12 mt-4" key={val?.id}>
                                <EventsListCard baseUrls={baseUrls} val={val}
                                  isValid={isValid}
                                />
                              </div>
                            );
                          }))}
                      </div> */}
                      <div className="col-12 mt-2">
                        <EventsListCard rowData={eventList?.data} baseUrls={baseUrls} setHastag={setHastag} setInterestDrop={setInterestDrop} />
                      </div>
                    </>
                  ) : (
                    <>
                      {loadingCard ? (
                        <Loader2 />
                      ) : (
                        eventList?.data &&
                        eventList?.data?.map((val) => {
                          return (
                            <div key={val?.id} className="d-flex justify-content-center col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mt-4">
                              <EventsCard baseUrls={baseUrls} val={val} isValid={isValid} setHastag={setHastag} setInterestDrop={setInterestDrop} />
                            </div>
                          );
                        })
                      )}
                    </>
                  )}

                  <div>
                    {eventList?.data?.length < eventList?.total && (
                      <div className="pagination-container">
                        <ReactPaginate
                          pageCount={Math.ceil(eventList?.total / eventList?.per_page)}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="selected"
                          disabledClassName="disabled"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
  );
};
export default Events;
