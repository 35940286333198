import { useState, useEffect, useRef } from 'react'
import arrow from "../../assets/images/arrow2.svg";
import { useSelector } from "react-redux";
import { Event_SponsorPackage, GetTypeEventEssentials, Update_SponsorPackage } from '../../utils/CreateEvents/createEvent';
import { Get_Edit_Event_SponsorPackage } from '../../utils/EditEvent/editEvent';
import { predefinePackage } from '../../utils/packagesform/packagesFormManager';
import { MdDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import { Packages_get_event } from '../../utils/editevents/EditEvent';
import DeleteConfirmation from '../CreateEvents/DeleteConfirmation';
import ReactQuill from 'react-quill';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import CreatableSelect from 'react-select/creatable';
import Loader2 from '../Loader/Loader2';

const EventSponsorPackage = ({ setTabValue, event_data, Messages }) => {
    const [early_bird, setEarly_bird] = useState('0');
    const [last_Minute, setLast_Minute] = useState('0');
    const [guestCoverType, setGuestCoverType] = useState('0');
    const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(3);
    const [selectedOption, setSelectedOption] = useState(null);
    const [predefinePackageList, setPredefinePackageList] = useState([]);
    const [createdPackages, setCreatePackages] = useState({});
    const [showDltModal, setShowDltModal] = useState(false);
    const [package_id, setPackage_id] = useState('');
    const [updateCheck, setUpdateCheck] = useState(false);
    const [eventType, setEventType] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [packagelistLoading,setPackageListLoading] = useState(false);
    const today = new Date().toISOString().split("T")[0];

    const allConfingData = useSelector((state) => state.config_data.configData);
    const [formData, setFormData] = useState({
        title: "",
        amount: "",
        cut_off_date: "",
        total_guests: "",
        guests_covered: "",
        guests_count: "",
        description: "",
        currency_id: "",
        available_package: "",
        package_inclusions: "",

        early_bird_offer: "",
        early_bird_offer_amount: "",
        early_bird_offer_currency: 3,
        early_bird_offer_end_date: "",

        // early_bird_offer_start_date: "",
        // early_bird_offer_end_date: "",
        // early_bird_offer_start_time: "",
        // early_bird_offer_end_time: "",

        last_minute_offer: "",
        last_minute_offer_amount: "",
        last_minute_offer_currency: 3,
        last_minute_offer_start_date: "",

        // last_minute_offer_end_date: "",
        // last_minute_offer_start_time: "",
        // last_minute_offer_end_time: "",
    });

    const refs = {
        title: useRef(null),
        amount: useRef(null),
        cut_off_date: useRef(null),
        total_guests: useRef(null),
        guests_covered: useRef(null),
        guests_count: useRef(null),
        description: useRef(null),
        currency_id: useRef(null),
        available_package: useRef(null),
        package_inclusions: useRef(null),

        early_bird_offer: useRef(null),
        early_bird_offer_amount: useRef(null),
        // early_bird_offer_start_date: useRef(null),
        early_bird_offer_end_date: useRef(null),
        // early_bird_offer_start_time: useRef(null),
        // early_bird_offer_end_time: useRef(null),

        last_minute_offer: useRef(null),
        last_minute_offer_amount: useRef(null),
        last_minute_offer_start_date: useRef(null),
        // last_minute_offer_end_date: useRef(null),
        // last_minute_offer_start_time: useRef(null),
        // last_minute_offer_end_time: useRef(null),
    };

    const [errors, setErrors] = useState({});

    const handleDescription = (value) => {
        const text = value?.replace(/<[^>]*>?/gm, '');
        if (text.length <= 300) {
            setFormData((prevState) => ({
                ...prevState,
                description: value
            }));
            setErrors({
                ...errors,
                description: ""
            });
        } else {
            setErrors({
                ...errors,
                description: "Description cannot exceed 300 characters."
            });
        }
    }

    const handlePackageInclusions = (value) => {
        const text = value?.replace(/<[^>]*>?/gm, '');
        if (text.length <= 500) {
            setFormData((prevState) => ({
                ...prevState,
                package_inclusions: value
            }));
            setErrors({
                ...errors,
                package_inclusions: ""
            });
        } else {
            setErrors({
                ...errors,
                package_inclusions: "Package Inclusions cannot exceed 500 characters."
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'amount' || name === 'early_bird_offer_amount' || name === 'last_minute_offer_amount') {
            const unformattedValue = value?.replace(/[^\d]/g, '');
            setFormData({
                ...formData,
                [name]: unformattedValue
            });
        }
        else if (name === "guests_count") {
            const guestsCount = parseInt(value, 10);
            const maxGuestsCount = createdPackages?.event?.guests_count_max;

            if (guestsCount > maxGuestsCount) {
                setErrors({
                    ...errors,
                    guests_count: `Guests count cannot exceed ${maxGuestsCount}.`
                });
                return;
            }

            setFormData({
                ...formData,
                [name]: value
            });
        }
        else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    }

    const formatNumber = (value) => {
        return (value?.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    };

    const handleChangeCurrencySymbol = (e) => {
        const item = e.target.value
        setSelectedCurrencySymbol(item)
    }

    const handleEarlybirdCurrencyChange = (e) => {
        const curr = e.target.value
        setFormData({
         ...formData,
         early_bird_offer_currency: curr
        })
    }

    const handleLastMinuteCurrencyChange = (e) => {
        const curr = e.target.value
        setFormData({
            ...formData,
            last_minute_offer_currency: curr
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            const fData = {
                ...formData,
                guests_covered: guestCoverType,
                currency_id: selectedCurrencySymbol,
                early_bird_offer: early_bird,
                last_minute_offer: last_Minute
            }
            if (updateCheck) {
                setSaveBtnLoading(true);
                try {
                    const response = await Update_SponsorPackage(package_id, fData);
                    if (response?.data?.success) {
                        Messages(response?.data?.message);
                        EmptyState()
                        setUpdateCheck(false)
                        getPredefineData()
                    }
                } catch (err) {
                    console.log("err", err);
                }finally{
                  setSaveBtnLoading(false);
                }
            } else {
                setSaveBtnLoading(true);
                try {
                    const response = await Event_SponsorPackage(event_data?.id, fData);
                    if (response?.data?.success) {
                        Messages(response?.data?.message);
                        EmptyState();
                        getPredefineData()
                    }
                } catch (err) {
                    console.log("err", err);
                }finally{
                    setSaveBtnLoading(false);
                }
            }
        }
    }

    const getPredefineData = async () => {
        try {
            await predefinePackage().then((response) => {
                setPredefinePackageList(
                    response?.data?.map((item) => {
                        return {
                            value: item?.id,
                            label: item?.name,
                        };
                    }))
            });
        } catch (err) {
          console.log(err);
        }
        setPackageListLoading(true);
        try {
            Get_Edit_Event_SponsorPackage(event_data?.id)
                .then((response) => {
                 const data = response?.data
                 setCreatePackages(data)
                })
        } catch (errr) {
            console.log(errr)
        }finally{
            setPackageListLoading(false);
        }
    }

    const GetType = async () => {
        try {
            await GetTypeEventEssentials().then((response) => {
                setEventType(response?.data);
            });
        } catch (errr) {
            console.log(errr);
        }
    }

    useEffect(() => {
        GetType()
        getPredefineData();
    }, [])


    const handleDeletePackage = (id) => {
        setPackage_id(id)
        setShowDltModal(true)
    }

    const handleEditPackage = async (id) => {
        setPackage_id(id)
        setUpdateCheck(true)
        try {
            await Packages_get_event(id)
                .then((response) => {
                    if (response?.data?.success) {
                        const value = response?.data?.data
                        setFormData(value)
                        setSelectedOption({
                            value: value?.title,
                            label: value?.title,
                        });
                        setGuestCoverType(value?.guests_covered)
                        setEarly_bird(value?.early_bird_offer)
                        setLast_Minute(value?.last_minute_offer)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    const EmptyState = () => {
        setFormData({
            title: "",
            amount: "",
            cut_off_date: "",
            total_guests: "",
            guests_covered: "",
            guests_count: "",
            description: "",
            currency_id: "",
            available_package: "",
            package_inclusions: "",

            early_bird_offer: "",
            early_bird_offer_amount: "",
            // early_bird_offer_start_date: "",
            early_bird_offer_end_date: "",
            // early_bird_offer_start_time: "",
            // early_bird_offer_end_time: "",

            last_minute_offer: "",
            last_minute_offer_amount: "",
            last_minute_offer_start_date: "",
            // last_minute_offer_end_date: "",
            // last_minute_offer_start_time: "",
            // last_minute_offer_end_time: "",
        })
        setSelectedOption(null)
        setEarly_bird('0')
        setLast_Minute('0')
        setGuestCoverType('0')
    }

    // Define validation logic
    const validate = () => {
        const errors = {};
        if (!formData.title) {
            errors.title = 'Package Name is required';
        }
        if (!formData.amount || isNaN(formData.amount) || formData.amount <= 0) {
            errors.amount = 'Regular Offer Price is required';
        }
        // if (!formData.total_guests || isNaN(formData.total_guests) || formData.total_guests <= 0 || !Number.isInteger(Number(formData.total_guests))) {
        //     errors.total_guests = 'Total Guests is required';
        // }

        if (guestCoverType == 1) {
            if (!formData.guests_count || isNaN(formData.guests_count) || formData.guests_count <= 0 || !Number.isInteger(Number(formData.guests_count))) {
                errors.guests_count = 'Guests Count is required';
            }
        }
        if (!formData.cut_off_date) {
            errors.cut_off_date = 'Cut-off Date is required';
        }
        // if (!formData.description) {
        //     errors.description = 'Sponsor Description is required';
        // }
        if (!formData.available_package) {
            errors.available_package = 'Available Packages is required';
        }
        const package_inclus = formData.package_inclusions?.replace(/<p><br><\/p>/g, '')
        if (!package_inclus) {
            errors.package_inclusions = 'Package Inclusions is required';
        }

        if (early_bird == 1) {
            if (!formData.early_bird_offer_amount) {
                errors.early_bird_offer_amount = 'Early Bird Offer Price is required';
            } else if (parseFloat(formData?.early_bird_offer_amount) > parseFloat(formData?.amount)) {
                errors.early_bird_offer_amount = 'Early Bird Offer Price must be less than the Regular Offer Price.'
            }
            // if (!formData.early_bird_offer_start_date) {
            //     errors.early_bird_offer_start_date = 'Early Bird Offer Publish Date is required';
            // }
            if (!formData.early_bird_offer_end_date) {
                errors.early_bird_offer_end_date = 'Early Bird Offer Cut-Off Date is required';
            }
            // if (!formData.early_bird_offer_start_time) {
            //     errors.early_bird_offer_start_time = 'Early Bird Offer Publish Time is required';
            // }
            // if (!formData.early_bird_offer_end_time) {
            //     errors.early_bird_offer_end_time = 'Early Bird Offer Cut-Off Time is required';
            // }
        }

        if (last_Minute == 1) {
            if (!formData.last_minute_offer_amount) {
                errors.last_minute_offer_amount = 'Last Minute Offer Price is required';
            } else if (parseFloat(formData?.last_minute_offer_amount) > parseFloat(formData?.amount)) {
                errors.last_minute_offer_amount = 'Last Minute Offer Price must be less than the Regular Offer Price.'
            }
            if (!formData.last_minute_offer_start_date) {
                errors.last_minute_offer_start_date = 'Last Minute Offer Publish Date is required';
            }
            // if (!formData.last_minute_offer_end_date) {
            //     errors.last_minute_offer_end_date = 'Last Minute Offer Cut-Off Date is required';
            // }
            // if (!formData.last_minute_offer_start_time) {
            //     errors.last_minute_offer_start_time = 'Last Minute Offer Publish Time is required';
            // }
            // if (!formData.last_minute_offer_end_time) {
            //     errors.last_minute_offer_end_time = 'Last Minute Offer Cut-Off Time is required';
            // }
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // rect quill length count
    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
    
        let textWithNewlines = div.innerHTML
    
        if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
          textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
        } else {
          // Otherwise, apply replacements
          textWithNewlines = textWithNewlines
            .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
        }
    
        div.innerHTML = textWithNewlines;
        return div.textContent || div.innerText || "";
      };

    const getDescriptionLength = () => {
        const cleanDescription = stripHtmlTags(formData.package_inclusions);
        return cleanDescription.length;
    };

    const maxLength = 500;
    const currentLength = getDescriptionLength();
    const remainingLength = maxLength - currentLength;
    //

    // useEffect(() => {
    // const firstErrorKey = Object.keys(errors)[0];
    // console.log("firstErrorKey", firstErrorKey)
    // if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey].current) {
    //     refs[firstErrorKey].current.focus();
    // }
    // }, [errors]);


    const handlePackageName = (option) => {
        setSelectedOption(option);
        setFormData({ ...formData, title: option?.label })
    };

    // console.log("packagelistLoading",packagelistLoading)

    return (
        <>
            <div className="basicInfo">
                {/* <h2 className="basicinfo-head">Sponsorship
                    Package</h2> */}
                <div className="d-flex align-items-center gap-1 position-relative">
                    <p className="createevent-head-p">Share what sponsors will gain by supporting your event. Describe the benefits and perks they’ll receive, making it clear why sponsoring your event is a great opportunity.</p>
                    {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Sponsorship Package</h2>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <IoMdInformationCircleOutline size={23} />
                    </div>
                    {isHovered && (
                        <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <p className="info-popup-text">Share what sponsors will gain by supporting your event. Describe the benefits and perks they’ll receive, making it clear why sponsoring your event is a great opportunity.</p>
                        </div>
                    )} */}
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <form onSubmit={handleSubmit}>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label className="form-label">Package Name<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="Enter sponsorship package name" />
                                    <CreatableSelect
                                        value={selectedOption}
                                        onChange={handlePackageName}
                                        options={predefinePackageList}
                                        placeholder="Select package or create an option"
                                        isClearable
                                    />
                                    {errors.title && (
                                        <span className="error">{errors.title}</span>
                                    )}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="form-group col-md-6" >
                                    <label className="form-label">Event Type<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="Specify the type of event this package applies to" />
                                    <select
                                        className="form-control"
                                        name="event_type"
                                        disabled>
                                        <option value="" selected disabled>
                                          Event Type
                                        </option>
                                        {eventType &&
                                            eventType?.map((val) => {
                                             return (
                                                 <option key={val?.id} value={val?.id} selected={val?.id == event_data?.event_type}>
                                                   {val?.name}
                                                 </option>
                                                );
                                            })}
                                    </select>
                                </div>
                            </div>


                            <div className="row mt-2">
                                <div className="form-group col-md-6" >
                                    <label className="form-label">Cut-off Date<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="The last date sponsors can avail this sponsorship package" />
                                    <input
                                        type="date"
                                        value={formData?.cut_off_date}
                                        name="cut_off_date"
                                        className="form-control"
                                        onFocus={(e) => e.currentTarget?.showPicker()}
                                        onChange={handleChange}
                                        min={today}
                                        max={event_data?.end_date}
                                        ref={refs?.cut_off_date}
                                    />
                                    {errors.cut_off_date && (
                                      <span className="error">{errors.cut_off_date}</span>
                                    )}
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="form-label">Total Guests<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="Expected Number of guest participating in the event" />
                                    <input
                                        min="0"
                                        type="number"
                                        // value={formData?.total_guests}
                                        value={createdPackages?.event?.guests_count_max}
                                        disabled
                                        name="total_guests"
                                        className="form-control"
                                        placeholder="Guests"
                                        onChange={handleChange}
                                    />
                                    {errors.total_guests && (
                                        <span className="error">{errors.total_guests}</span>
                                    )}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="form-group col-md-12">
                                    <label className="form-label">Guests Covered<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="Number of guests covered in this package" />

                                    <div className='row '>
                                        <div className="d-flex gap-3 col-md-6">
                                            <div className="form-check">
                                                <label className="form-check-label m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="0"
                                                        checked={guestCoverType == 0}
                                                        name="guestCoverType"
                                                        onChange={(e) => setGuestCoverType(e?.target?.value)}
                                                    />
                                                    All
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label m-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="1"
                                                        checked={guestCoverType == 1}
                                                        name="guestCoverType"
                                                        onChange={(e) => setGuestCoverType(e?.target?.value)}
                                                    />
                                                    Enter Count
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            guestCoverType == 1 &&
                                            <div className='col-md-6'>
                                                <input
                                                    min={createdPackages?.event?.guests_count_min}
                                                    max={createdPackages?.event?.guests_count_max}
                                                    type="number"
                                                    value={formData?.guests_count}
                                                    name="guests_count"
                                                    className="form-control"
                                                    placeholder="Guests Count"
                                                    onChange={handleChange}
                                                    ref={refs?.guests_count}
                                                />
                                                {errors.guests_count && (
                                                    <span className="error">{errors.guests_count}</span>
                                                )}
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="form-group col-md-6" style={{ position: "relative" }}>
                                    <label className='form-label'>Regular Offer Price<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="Details of the standard investment of this package." />

                                    <input
                                        type="text"
                                        min='0'
                                        value={formatNumber(formData?.amount)}
                                        name='amount'
                                        className="form-control"
                                        placeholder="Regular Offer Price"
                                        onChange={handleChange}
                                        ref={refs?.amount}
                                    />
                                    <select className="currency_tag" name="currency_id" value={selectedCurrencySymbol} onChange={handleChangeCurrencySymbol}>
                                        {allConfingData?.currency_list?.map((val) => {
                                            return (
                                                <option key={val?.id} value={val?.id}>{val?.code} {val?.symbol}</option>
                                            )
                                        })}
                                    </select>
                                    {errors?.amount && <span className="error">{errors?.amount}</span>}
                                </div>

                                <div className="form-group col-md-6" title="">
                                    <label className="form-label">Available Packages<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="List how many of these packages are available for purchase." />

                                    <input
                                        type="number"
                                        min='0'
                                        value={formData?.available_package}
                                        name='available_package'
                                        className="form-control"
                                        placeholder="Available Package"
                                        onChange={handleChange}
                                        ref={refs?.available_package}
                                    />
                                    {errors.available_package && <span className="error">{errors.available_package}</span>}
                                </div>

                                <div className='form-group col-md-12'>
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label mb-0">
                                            Package Inclusions<span className="asterisk">*</span>
                                            {/* <IoMdInformationCircleOutline size={16} color="black" title='List what all is included in this sponsorship package' /> */}
                                        </label>
                                        <p className="Character-0500">
                                            Character:<span className="text-style-1">
                                                {/* remainingLength */}
                                                {currentLength}/{maxLength}
                                            </span>
                                        </p>
                                    </div>
                                    <p className="reactquill-tooltip-p mb-1">(Note: List what all is included in this sponsorship package)</p>
                                    <div style={{ height: '165px' }}>
                                        <ReactQuill
                                            value={formData?.package_inclusions}
                                            onChange={handlePackageInclusions}
                                            theme='snow'
                                            style={{ height: '120px' }}
                                            placeholder='Enter Package Inclusions'
                                        // ref={refs?.package_inclusions}
                                        />
                                    </div>
                                    {errors.package_inclusions && <span className="error">{errors.package_inclusions}</span>}
                                </div>

                                {/* <div className='form-group col-md-12' title='List what all is included in this sponsorship package'>
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label">
                                            Sponsor Description<span className="asterisk">*</span>
                                        </label>
                                        <p className="Character-0500">
                                            Character:<span className="text-style-1">
                                                {formData.description === "<p><br></p>" ?
                                                    `${0}` :
                                                    `${formData.description === "" ? formData.description?.length : `${formData.description?.length - 7}`}`}

                                                {formData.description === "<p><br></p>" ?
                                                    `/${500}` :
                                                    `${formData.description === "" ? `/${500}` : `/${500 - formData.description?.length + 7}`}`}
                                            </span>
                                        </p>
                                    </div>


                                    <div style={{ height: '165px' }}>
                                        <ReactQuill
                                            value={formData?.description}
                                            onChange={handleDescription}
                                            theme='snow'
                                            style={{ height: '120px' }}
                                            placeholder='Enter Sponsor Description'
                                        />
                                    </div>
                                    {errors.description && <span className="error">{errors.description}</span>}
                                </div> */}

                            </div>


                            <div className="row mt-3">
                                <div className="d-flex gap-3">
                                    <label className="mb-0">
                                        Do you want to add any Early Bird Offer?<span className="asterisk">*</span>
                                    </label>
                                    <div className="d-flex gap-3">
                                        <div className="form-check">
                                            <label className="form-check-label m-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="1"
                                                    checked={early_bird == 1}
                                                    name="early_bird"
                                                    onChange={(e) => setEarly_bird(e?.target?.value)}
                                                />
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label m-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="0"
                                                    checked={early_bird == 0}
                                                    name="early_bird"
                                                    onChange={(e) => setEarly_bird(e?.target?.value)}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {early_bird == 1 && (
                                <>
                                    <div className="row mt-2">
                                        <div className="form-group col-md-6 position-relative">
                                            <label className="form-label">Early Bird Offer Price<span className="asterisk"></span></label>
                                            <IoMdInformationCircleOutline size={16} color="black" title='Special pricing available for sponsors who commit early' />
                                            <input
                                                min="0"
                                                type="text"
                                                value={formatNumber(formData?.early_bird_offer_amount)}
                                                onChange={handleChange}
                                                name="early_bird_offer_amount"
                                                className="form-control"
                                                placeholder="Early Bird Offer Price"
                                                ref={refs?.early_bird_offer_amount}
                                            />
                                            <select className="currency_tag" name="currency_id" disabled value={selectedCurrencySymbol} onChange={handleChangeCurrencySymbol}>
                                                {allConfingData?.currency_list?.map((val) => {
                                                    return (
                                                        <option key={val?.id} value={val?.id}>{val?.code} {val?.symbol}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors?.early_bird_offer_amount && (
                                                <span className="error">{errors?.early_bird_offer_amount}</span>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Early Bird Offer Cut-Off Date<span className="asterisk"></span></label>
                                            <input
                                                type="date"
                                                value={formData?.early_bird_offer_end_date}
                                                onChange={handleChange}
                                                name="early_bird_offer_end_date"
                                                className="form-control"
                                                onFocus={(e) => e?.currentTarget?.showPicker()}
                                                max={event_data?.end_date}
                                                // min={formData?.early_bird_offer_start_date}
                                                ref={refs?.early_bird_offer_end_date}
                                            />
                                            {errors.early_bird_offer_end_date && (
                                                <span className="error">{errors.early_bird_offer_end_date}</span>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="form-group col-md-6" >
                                            <label className="form-label">Early Bird Offer Publish Date<span className="asterisk"></span></label>
                                            <input
                                                type="date"
                                                value={formData?.early_bird_offer_start_date}
                                                onChange={handleChange}
                                                name="early_bird_offer_start_date"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                min={today}
                                                max={event_data?.end_date}
                                                ref={refs?.early_bird_offer_start_date}
                                            />
                                            {errors.early_bird_offer_start_date && (
                                                <span className="error">{errors.early_bird_offer_start_date}</span>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Early Bird Offer Publish Time<span className="asterisk"></span></label>
                                            <input
                                                type="time"
                                                value={formData?.early_bird_offer_start_time}
                                                onChange={handleChange}
                                                name="early_bird_offer_start_time"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                ref={refs?.early_bird_offer_start_time}
                                            />
                                            {errors.early_bird_offer_start_time && (
                                                <span className="error">{errors.early_bird_offer_start_time}</span>
                                            )}
                                        </div>
                                    </div> */}
                                    {/* <div className="row">
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Early Bird Offer Cut-Off Date<span className="asterisk"></span></label>
                                            <input
                                                type="date"
                                                value={formData?.early_bird_offer_end_date}
                                                onChange={handleChange}
                                                name="early_bird_offer_end_date"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                max={event_data?.end_date}
                                                min={formData?.early_bird_offer_start_date}
                                                ref={refs?.early_bird_offer_end_date}
                                            />
                                            {errors.early_bird_offer_end_date && (
                                                <span className="error">{errors.early_bird_offer_end_date}</span>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Early Bird Offer Cut-Off Time<span className="asterisk"></span></label>
                                            <input
                                                type="time"
                                                value={formData?.early_bird_offer_end_time}
                                                onChange={handleChange}
                                                name="early_bird_offer_end_time"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                ref={refs?.early_bird_offer_end_time}
                                            />
                                            {errors.early_bird_offer_end_time && (
                                                <span className="error">{errors.early_bird_offer_end_time}</span>
                                            )}
                                        </div>
                                    </div> */}
                                </>
                            )}

                            <div className="row mt-2">
                                <div className="d-flex gap-3">
                                    <label className="mb-0">
                                        Do you want to add any Last Minute Offer?<span className="asterisk">*</span>
                                    </label>
                                    <div className="d-flex gap-3">
                                        <div className="form-check">
                                            <label className="form-check-label m-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="1"
                                                    checked={last_Minute == 1}
                                                    name="last_Minute"
                                                    onChange={(e) => setLast_Minute(e?.target?.value)}
                                                />
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label m-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="0"
                                                    checked={last_Minute == 0}
                                                    name="last_Minute"
                                                    onChange={(e) => setLast_Minute(e?.target?.value)}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {last_Minute == 1 && (
                                <>

                                    <div className="row mt-2">
                                        <div className="form-group col-md-6 position-relative">
                                            <label className="form-label">Last Minute Offer Price<span className="asterisk"></span></label>
                                            <IoMdInformationCircleOutline size={16} color="black" title='Special pricing available for sponsors who last minute' />
                                            <input
                                                min="0"
                                                type="text"
                                                value={formatNumber(formData?.last_minute_offer_amount)}
                                                onChange={handleChange}
                                                name="last_minute_offer_amount"
                                                className="form-control"
                                                placeholder="Last Minute Offer Price"
                                                ref={refs?.last_minute_offer_amount}
                                            />
                                            <select className="currency_tag" name="currency_id" disabled value={selectedCurrencySymbol} onChange={handleChangeCurrencySymbol}>
                                                {allConfingData?.currency_list?.map((val) => {
                                                    return (
                                                        <option key={val?.id} value={val?.id}>{val?.code} {val?.symbol}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors.last_minute_offer_amount && (
                                                <span className="error">{errors.last_minute_offer_amount}</span>
                                            )}
                                        </div>

                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Last Minute Offer Publish Date<span className="asterisk"></span></label>
                                            <input
                                                type="date"
                                                value={formData?.last_minute_offer_start_date}
                                                onChange={handleChange}
                                                name="last_minute_offer_start_date"
                                                className="form-control"
                                                onFocus={(e) => e?.currentTarget?.showPicker()}
                                                max={event_data?.end_date}
                                                min={formData?.early_bird_offer_end_date ? formData?.early_bird_offer_end_date : today}
                                                ref={refs?.last_minute_offer_start_date}
                                            />
                                            {errors.last_minute_offer_start_date && (
                                                <span className="error">{errors.last_minute_offer_start_date}</span>
                                            )}
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Last Minute Offer Publish Date<span className="asterisk"></span></label>
                                            <input
                                                type="date"
                                                value={formData?.last_minute_offer_start_date}
                                                onChange={handleChange}
                                                name="last_minute_offer_start_date"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                max={event_data?.end_date}
                                                min={formData?.early_bird_offer_end_date ? formData?.early_bird_offer_end_date : today}
                                                ref={refs?.last_minute_offer_start_date}
                                            />
                                            {errors.last_minute_offer_start_date && (
                                                <span className="error">{errors.last_minute_offer_start_date}</span>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Last Minute Offer Publish Time<span className="asterisk"></span></label>
                                            <input
                                                type="time"
                                                value={formData?.last_minute_offer_start_time}
                                                onChange={handleChange}
                                                name="last_minute_offer_start_time"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                ref={refs?.last_minute_offer_start_time}
                                            />
                                            {errors.last_minute_offer_start_time && (
                                                <span className="error">{errors.last_minute_offer_start_time}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Last Minute Offer Cut-Off Date<span className="asterisk"></span></label>
                                            <input
                                                type="date"
                                                value={formData?.last_minute_offer_end_date}
                                                onChange={handleChange}
                                                name="last_minute_offer_end_date"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                max={event_data?.end_date}
                                                min={formData?.last_minute_offer_start_date ? formData?.last_minute_offer_start_date : formData?.early_bird_offer_end_date}
                                                ref={refs?.last_minute_offer_start_date}
                                            />
                                            {errors.last_minute_offer_end_date && (
                                                <span className="error">{errors.last_minute_offer_end_date}</span>
                                            )}
                                        </div>
                                        <div className="form-group col-md-6" title="">
                                            <label className="form-label">Last Minute Offer Cut-Off Time<span className="asterisk"></span></label>
                                            <input
                                                type="time"
                                                value={formData?.last_minute_offer_end_time}
                                                onChange={handleChange}
                                                name="last_minute_offer_end_time"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                                ref={refs?.last_minute_offer_end_time}
                                            />
                                            {errors.last_minute_offer_end_time && (
                                                <span className="error">{errors.last_minute_offer_end_time}</span>
                                            )}
                                        </div>
                                    </div> */}


                                </>
                            )}

                            <div className="d-flex justify-content-md-end align-items-center mt-3">
                                <div className="d-flex flex-column flex-md-row gap-2">
                                    <button
                                        className="d-flex align-items-center gap-1 saveandcontinue-btn"
                                        type="submit">
                                         {saveBtnLoading ? (
                                          <div
                                           className="spinner-border text-light "
                                           role="status">
                                           <span className="sr-only">Loading...</span>
                                            </div>
                                          ) : (
                                           ""
                                           )}
                                        Save
                                        <img className="arrow-img" src={arrow} alt="arrow" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                 
                    {createdPackages?.data?.length > 0 &&
                        <div className="col-md-5">
                            <div className="sidemenu p-2">
                                {createdPackages?.data?.map((val) => {
                                    return (<>
                                    {packagelistLoading ? <Loader2/> :
                                        <div className="input-group d-flex align-items-center donation-input py-0">
                                            <div type="text"
                                                className="donation-package-input form-control">
                                                <span className="package-title-text">{val?.title}</span>
                                                <br />
                                                <span className=" select-pack-text">
                                                    {val?.currencies?.symbol} {val?.amount}
                                                </span>
                                            </div>
                                            <button
                                                className="edit-btn"
                                                onClick={() => handleEditPackage(val?.id)}
                                                title="Edit" >
                                                <MdOutlineEdit size={20} />
                                            </button>
                                            <button
                                                className="delete-btn"
                                                onClick={() => handleDeletePackage(val?.id)}
                                                title="Delete">
                                                <MdDeleteOutline size={20} />
                                            </button>
                                        </div>
                                      }
                                    </>)
                                })}
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-md-end align-items-center mt-3">
                        <div className="d-flex flex-column flex-md-row gap-2">
                            {createdPackages?.data?.length > 0 &&
                                <button
                                    className="d-flex align-items-center gap-1 saveandcontinue-btn"
                                    type="button" onClick={() => setTabValue("coupons")}>
                                    Save and Continue
                                    <img className="arrow-img" src={arrow} alt="arrow" />
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <DeleteConfirmation setShowDltModal={setShowDltModal} showDltModal={showDltModal} package_id={package_id} getPredefineData={getPredefineData} />
        </>
    )
}
export default EventSponsorPackage