import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { Get_TransferUsers } from "../../../utils/CreateEvents/createEvent";

const EventTransferTo = ({ refs, input, setInput, showUsers, setShowUsers}) => {
  const [listingUsers, setListingUsers] = useState([]);

  const handleAddUsers = (selectedOption) => {
    setShowUsers(selectedOption);
     
    setInput({ ...input, transfer_user_id:selectedOption?.value});
  };

  const getUserList = async () => {
    try {
      const response = await Get_TransferUsers();
      const data = response?.data?.data;
      setListingUsers(
        data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">
              This Event Transfer To
              {/* <span className="asterisk">*</span> */}
            </label>
            {/* <IoMdInformationCircleOutline size={16} color="black" title="List the primary languages of the event." /> */}

            <Select
              name="transfer_user_id"
              value={showUsers}
              classNamePrefix="select"
              placeholder="Select Users"
              className=""
              isClearable
              onChange={handleAddUsers}
              options={listingUsers}
              ref={refs?.users}
            />
            {/*{errors?.languages && (
               <span className="error">{errors?.languages}</span>
            )}*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventTransferTo;
