import React, { useState, useEffect } from "react";
import arrow from "../../assets/images/arrow2.svg";
import ReactQuill from "react-quill";
import { CreatePastEventPitch, GetPastEventPitch, PastEvent_Confirmed_Sponsor_Dlt, PastEvent_Confirmed_Sponsor_Update, PastEventGetSingleConfirmedSponsor } from "../../utils/createPastEventForm/createpasteventform";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";


const PastEventPitchForm = ({ setTabValue, event_data, Messages, isEdit, tabValue, Eid }) => {
  const [confirmedSponsors, setConfirmedSponsors] = useState("0");
  const [input, setInput] = useState({});
  const [sponsors, setSponsors] = useState([]);
  const [editSponsorsList, setEditSponsorsList] = useState([]);
  const [confirmedSponsorId, setConfirmedSponsorId] = useState(null)
  const [normalEdit, setNormalEdit] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const [updateCheck, setUpdateCheck] = useState(false)
  const [sponsorData, setSponsorData] = useState({
    sponsor_name: "",
    sponsors_brand_logo: null,
    sponsor_details: ""
  });
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const [sponsorError, setSponsorError] = useState({});

  const getData = async () => {
    try {
      await GetPastEventPitch(Eid)
        .then((response) => {
          // setInput(response?.data?.data)
          setConfirmedSponsors(response?.data?.data?.any_confirmed_sponsors)
          setEditSponsorsList(response?.data?.data?.new_sponsor)
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isEdit && tabValue == "Event-Pitch") {
      getData();
    }
  }, [Eid])


  useEffect(() => {
    setInput({
      ...input,
      any_confirmed_sponsors: confirmedSponsors,
    });
  }, [confirmedSponsors])


  const onSubmit = async (e) => {
    e.preventDefault();
    const fdata = {
      ...input,
      new_sponsor: [...editSponsorsList, ...sponsors,]
    }

    if (confirmedSponsors == '1') {
      if (editSponsorsList?.length > 0 || sponsors?.length > 0) {
        try {
          await CreatePastEventPitch(fdata, Eid).then((results) => {
            if (results?.data?.success) {
              setTabValue("Event-Host");
              Messages(results?.data?.message);
            }
          });
        } catch (error) {
          console.log(error)
        }
      } else {
        toast.warn("Sponsor is required", {
          position: "top-center",
        });
      }
    } else {
      try {
        await CreatePastEventPitch(fdata, Eid).then((results) => {
          if (results?.data?.success) {
            setTabValue("Event-Host");
            Messages(results?.data?.message);
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }


  const sponsorValidateForm = () => {
    let valid = true;
    const newErrors = {};
    if (sponsorData?.sponsor_name?.trim() == "") {
      newErrors.sponsor_name = "Confirmed Sponsors is required";
      valid = false;
    }
    if (!sponsorData?.sponsors_brand_logo) {
      newErrors.sponsors_brand_logo = "Sponsor Brand logo is required";
      valid = false;
    }


    const sponsorDetails = sponsorData?.sponsor_details?.replace(/<p><br><\/p>/g, '')
    if (sponsorDetails?.trim() == "") {
      newErrors.sponsor_details = "Sponsor Details is required";
      valid = false;
    }

    setSponsorError(newErrors)
    return valid;
  }

  const handleAddSponsor = (e) => {
    e.preventDefault();
    if (sponsorValidateForm()) {
      const newSponsor = {
        sponsor_name: sponsorData?.sponsor_name,
        sponsors_brand_logo: sponsorData?.sponsors_brand_logo,
        sponsor_details: sponsorData?.sponsor_details
      };

      if (updateCheck) {
        try {
          PastEvent_Confirmed_Sponsor_Update(confirmedSponsorId, sponsorData)
            .then((response) => {
              console.log(response?.data)
              if (response?.data?.success) {
                Messages(response?.data?.message)
                setUpdateCheck(false)
                getData();
                setSponsorData({
                  sponsor_name: "",
                  sponsors_brand_logo: null,
                  sponsor_details: ""
                });
              }
            })
        } catch (err) {
          console.log(err)
        }
      } else {
        if (normalEdit) {
          setSponsors((prevSponsors) =>
            prevSponsors.map((sponsor, index) =>
              index === confirmedSponsorId ? newSponsor : sponsor
            )
          );
          setSponsorData({
            sponsor_name: "",
            sponsors_brand_logo: null,
            sponsor_details: ""
          });
          Messages("Sponsor update successfully")
          setNormalEdit(false)
        } else {
          setSponsors([...sponsors, newSponsor]);
          setSponsorData({
            sponsor_name: "",
            sponsors_brand_logo: null,
            sponsor_details: ""
          });
        }
      }
      // fileInputRef.current.value = null;
    }
  };

  const handleNormalEdit = (sponsor, id) => {
    setSponsorData(sponsor)
    setConfirmedSponsorId(id)
    setNormalEdit(true)
  }

  const handleDeleteSponsor = (indexToDelete) => {
    setSponsors((prevSponsors) =>
      prevSponsors.filter((_, index) => index !== indexToDelete)
    );
  };

  const EditConfirmSponsor = async (id) => {
    setUpdateCheck(true)
    setConfirmedSponsorId(id)

    try {
      PastEventGetSingleConfirmedSponsor(id)
        .then((response) => {
          setSponsorData(response?.data?.data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const DltConfirmSponsor = async (id) => {
    try {
      await PastEvent_Confirmed_Sponsor_Dlt(id)
        .then((response) => {
          if (response?.data?.success) {
            Messages(response?.data?.message)
            getData();
          }
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleSponsorChange = (e) => {
    const { name, value, files } = e.target;
    if (name == "sponsors_brand_logo") {
      setSponsorData({
        ...sponsorData,
        [name]: files[0],
      });
    } else {
      setSponsorData({
        ...sponsorData,
        [name]: value,
      });
    }
  };

  const handleSponsorDetails = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setSponsorData((prevState) => ({
        ...prevState,
        sponsor_details: value,
      }));
      setSponsorError({
        ...sponsorError,
        sponsor_details: "",
      });
    } else {
      setSponsorError({
        ...sponsorError,
        sponsor_details:
          "Sponsor Details  cannot exceed 500 characters.",
      });
    }
  }

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    let textWithNewlines = div.innerHTML

    if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
      textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
    } else {
      // Otherwise, apply replacements
      textWithNewlines = textWithNewlines
        .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
    }

    div.innerHTML = textWithNewlines;
    return div.textContent || div.innerText || "";
  };


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // React Sponsor details quill length count
  const maxThemeLength = 500
  const getSponsorLength = () => {
    const cleanDescription = stripHtmlTags(sponsorData.sponsor_details);
    return cleanDescription.length;
  };
  const currentSponsorLength = getSponsorLength();
  const remainingSponsorLength = maxThemeLength - currentSponsorLength
  //

  return (
    <>
      <div className="basicInfo">
        {/* <h2 className="basicinfo-head">Event Pitch</h2> */}
        <div className="d-flex align-items-center gap-1 position-relative">
        <p className="createevent-head-p">
            This section is designed to articulate the unique value and opportunities your event offers to potential sponsors.
            It should present a persuasive argument to potential sponsors about why they should support your event.
        </p>
          {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Event Pitch</h2>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <IoMdInformationCircleOutline size={23} />
          </div>
          {isHovered && (
            <div
              className="info-popup"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <p className="info-popup-text">
                This section is designed to articulate the unique value and opportunities your event offers to potential sponsors.
                It should present a persuasive argument to potential sponsors about why they should support your event.
              </p>
            </div>
          )} */}
        </div>
        <form onSubmit={onSubmit}>
          <div>
            <div className="row">
              {event_data?.description && (
                <div className="col-md-12" >
                  <label className="form-label mb-0">
                    Event Description
                  </label>
                  {/* <IoMdInformationCircleOutline size={16} color="black" title="Provide a brief overview describing the purpose and what the event is all about and its main attractions." /> */}
                  <p className="reactquill-tooltip-p mb-1">(Note: Provide a brief overview describing the purpose and what the event is all about and its main attractions.)</p>
                  {/* <div style={{ height: "220px" }}> */}
                  <ReactQuill
                    value={event_data?.description}
                   //onChange={""}
                    readOnly={true}
                    modules={{toolbar: false}}
                    disabled
                    theme="snow"
                    style={{ height: "130px" }}
                    placeholder="Event Description"
                  />
                 {/* </div> */}
                  {/* <textarea
                    value={stripHtmlTags(event_data?.description)}
                    type="text"
                    name="event_description"
                    disabled
                    className="form-control"
                    placeholder="Event Description"
                  /> */}
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="d-flex align-items-center gap-3">
                <label className="mb-0">Do you have any confirmed sponsors? </label>
                <div className="d-flex gap-3">
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="1"
                        checked={confirmedSponsors == "1"}
                        name="confirmedSponsors"
                        onChange={(e) => setConfirmedSponsors(e?.target?.value)}
                      />
                      Yes</label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="0"
                        checked={confirmedSponsors == "0"}
                        name="confirmedSponsors"
                        onChange={(e) => setConfirmedSponsors(e?.target?.value)}
                      />
                      No</label>
                  </div>
                </div>
              </div>
            </div>
            {
              confirmedSponsors == "1" &&
              <>
                <div>
                  <div className="row align-items-end mt-2">
                    <h5 className="form-heading">
                      Confirmed Sponsors
                      <IoMdInformationCircleOutline size={16} color="black" title="Provide details of any confirmed sponsors" />
                    </h5>
                    <div className="col-md-6">
                      <label className="form-label">
                        Confirmed Sponsors<span className="asterisk">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={sponsorData?.sponsor_name}
                        name="sponsor_name"
                        onChange={handleSponsorChange}
                        placeholder="Enter Confirmed Sponsors"
                      />
                      {sponsorError?.sponsor_name && (
                        <span className="error">{sponsorError?.sponsor_name}</span>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label className="form-label">
                        Sponsor Brand logo<span className="asterisk">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        // ref={fileInputRef}
                        onChange={handleSponsorChange}
                        name="sponsors_brand_logo"
                        placeholder="Logo"
                      />
                      {sponsorError?.sponsors_brand_logo && (
                        <span className="error">{sponsorError?.sponsors_brand_logo}</span>
                      )}
                    </div>

                    <div className="col-md-6">
                      {sponsorData?.sponsors_brand_logo !== null && (
                        <div className="mt-3">
                          <div className="d-flex justify-content-end">
                            <button className="img-close-btn" type="button">
                              <IoClose
                                size={24}
                                onClick={() => setSponsorData({ ...sponsorData, sponsors_brand_logo: null })}
                              />
                            </button>
                          </div>

                          <div className="mt-2 d-flex justify-content-center">
                            {sponsorData?.sponsors_brand_logo instanceof File ? (
                              <img
                                className="event-logo-img"
                                src={URL.createObjectURL(sponsorData?.sponsors_brand_logo)}
                                alt="updateimg"
                              />
                            ) : (
                              <img
                                className="event-logo-img"
                                src={`${baseUrls?.sponsors_brand_logo}/${sponsorData?.sponsors_brand_logo}`}
                                alt="updateimg"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="d-flex justify-content-between">
                        <label className="form-label">
                          Sponsor Details<span className="asterisk">*</span>
                        </label>
                        <p className="Character-0500">
                          Character:<span className="text-style-1">
                          {/* remainingSponsorLength */}
                            {currentSponsorLength}/{maxThemeLength}
                          </span>
                        </p>
                      </div>


                      <div style={{ height: "190px" }}>
                        <ReactQuill
                          value={sponsorData?.sponsor_details}
                          onChange={handleSponsorDetails}
                          theme="snow"
                          style={{ height: "130px" }}
                          placeholder="Enter Sponsor Details"
                        />
                      </div>
                      {sponsorError?.sponsor_details && (
                        <span className="error">{sponsorError?.sponsor_details}</span>
                      )}
                    </div>

                    <div className="col-md-3 mt-3 mt-sm-0">
                      <button
                        className="n-btn"
                        type="button"
                        onClick={handleAddSponsor}>
                        Add Sponsor
                      </button>
                    </div>
                  </div>

                  {editSponsorsList?.length > 0 &&
                    <div className="mt-3">
                      <h5 className="form-heading">Confirmed Sponsors List</h5>
                      <ul className="sponsor-list p-0">
                        {editSponsorsList?.map((sponsor, index) => (
                          <li
                            key={index}
                            className="d-flex justify-content-between gap-2 align-items-center sponsor-item mt-2">
                            <img
                              src={`${baseUrls?.sponsors_brand_logo}/${sponsor?.sponsors_brand_logo}`}
                              alt={sponsor?.sponsor_name}
                              className="sponsor-logo"
                            />
                            <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_name}</p>

                            <div className="sponsor-details" dangerouslySetInnerHTML={{ __html: sponsor?.sponsor_details }}></div>
                            <div className="d-flex gap-2">
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => EditConfirmSponsor(sponsor?.id)}
                                type="button" >
                                Edit
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => DltConfirmSponsor(sponsor?.id)}
                                type="button" >
                                Delete
                              </button>
                            </div>

                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                  {
                    sponsors?.length > 0 &&
                    <div className="mt-3">
                      {/* <h5 className="form-heading">Confirmed Sponsors List</h5> */}
                      <ul className="sponsor-list p-0">
                        {sponsors?.map((sponsor, index) => (
                          <li
                            key={index}
                            className="d-flex justify-content-between gap-2 align-items-center sponsor-item mt-2">
                            <img
                              src={URL.createObjectURL(sponsor?.sponsors_brand_logo)}
                              alt={sponsor.sponsor_name}
                              className="sponsor-logo"
                            />
                            <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_name}</p>

                            <div className="sponsor-details" dangerouslySetInnerHTML={{ __html: sponsor?.sponsor_details }}></div>

                            <div className="d-flex gap-2">
                              <button
                                className="btn btn-danger btn-sm"
                                type="button"
                                onClick={() => handleNormalEdit(sponsor, index)}>
                                Edit
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                type="button"
                                onClick={() => handleDeleteSponsor(index)}>
                                Delete
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </div>
              </>

            }
          </div>
          <div className="d-flex justify-content-md-end align-items-center my-3">
            <div className="d-flex flex-column flex-md-row gap-2">
              <button
                className="d-flex align-items-center gap-1 saveandcontinue-btn"
                type="submit" >
                Save and Continue
                <img className="arrow-img" src={arrow} alt="arrow" />
              </button>
            </div>
          </div>
        </form>
      </div >
    </>
  )
}
export default PastEventPitchForm