import { useDispatch, useSelector } from "react-redux";
import { setEditdata } from "../../redux/EditEvent/editevent";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import preview from "../../assets/images/preview.svg";
import EditBasicInformation from "../../components/EditEventForm/EditBasicInfoForm";
import EditPitchForm from "../../components/EditEventForm/EditPitchForm";
import EditPackagesForm from "../../components/EditEventForm/EditPackagesForm";
import { ToastContainer, toast } from "react-toastify";
import EditCreateCoupon from "../../components/EditEventForm/EditCreateCoupon";
import EditEventEssentials from "../../components/EditEventForm/EditEventEssentials";
import EditEventGuest from "../../components/EditEventForm/EditEventGuest";
import EditEventHost from "../../components/EditEventForm/EditEventHost";
import EditEventPitch from "../../components/EditEventForm/EditEventPitch";
import EditEventPartnerShip from "../../components/EditEventForm/EditEventPartnerShip";
import EditSponsorPackage from "../../components/EditEventForm/EditSponsorPackage";
import EditEventSocialHub from "../../components/EditEventForm/EditEventSocialHub";
import EditEventCancellationRefund from "../../components/EditEventForm/EditEventCancellationRefund";
import EditEventMediaHub from "../../components/EditEventForm/EditEventMediaHub";
import EditEventReferences from "../../components/EditEventForm/EditEventReferences";
import { Get_Edit_Event } from "../../utils/EditEvent/editEvent";
import { TiArrowLeft } from "react-icons/ti";
import { RiArrowRightSFill } from "react-icons/ri";


const EditEvents = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("Event-Essentials");
  const [EditData, setEditData] = useState({});
  const [userData, setUserData] = useState([]);
  const params = useParams();
  const id = params?.id;
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);

  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const dispatch = useDispatch();

  // console.log("EditData",EditData)

  const EditEvents = async () => {
    try {
      const response = await Get_Edit_Event(id);
      setEditData(response?.data?.data)
      // dispatch(setEditdata(response?.data?.data));
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    if (tabValue == "Event-Essentials") {
      EditEvents();
    }
  }, [tabValue]);

  const Messages = (val) => {
    toast.success(val, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      theme: "light",
    });
  };

  const tabNames = {
    "Event-Essentials": "Event Essentials",
    "Event-Guest": "Event Guests",
    "Event-Pitch": "Event Pitch",
    "Event-Host": "Event Host",
    "Event-Partnerships": "Event Partnerships",
    "Sponsor-Packages": "Sponsor Packages",
    "coupons": "Coupons",
    "Event-Media-Hub": "Event Media Hub",
    "Social-Hub": "Social Hub",
    "Cancellation-Refund": "Cancellation / Refund",
    "References": "References",
  };

  return (
    <>
      <ToastContainer />
      <div className="newevent container fluid">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex align-items-center gap-1">
            <h5 className="create-event-heading m-0">Edit Event</h5>
             <RiArrowRightSFill size={20}/>
            <h5 className="create-event-heading m-0">{tabNames[tabValue]}</h5>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex justify-content-lg-end gap-2">
              <button
                className="btn d-flex align-items-center gap-1 preview-btn px-0"
                onClick={()=>navigate(`/preview/${id}`,{state: "EditEvent" })}>
                <img src={preview} alt="pre"/>
                Preview
              </button>
              {/* <button className="btn submitbutton">Submit for Validation</button> */}
              <button className="go-back-btn d-flex align-items-center ps-0" onClick={() => navigate(-1)} type="button">
              <TiArrowLeft size={20}/> Go Back
              </button>
            </div>
          </div>
        </div>

        <div className="row mb-5 mt-4">
          <div className="col-lg-3 d-flex flex-column gap-2 create-main-tab">
            <button
              onClick={() => setTabValue("Event-Essentials")}
              className={`${tabValue == "Event-Essentials"
                ? "active-create-event-tab"
                : "create-event-tab"
                } `}
            >
              Event Essentials
            </button>
            <button
              onClick={() => setTabValue("Event-Guest")}
              className={`${tabValue == "Event-Guest" ? "active-create-event-tab" : "create-event-tab"
                } `}
            >
              Event Guests
            </button>

            <button
              onClick={() => setTabValue("Event-Pitch")}
              className={`${tabValue == "Event-Pitch" ? "active-create-event-tab" : "create-event-tab"
                } `}
            >
              Event Pitch
            </button>
            <button
              onClick={() => setTabValue("Event-Host")}
              className={`${tabValue == "Event-Host" ? "active-create-event-tab" : "create-event-tab"
                } `}
            >
              Event Host
            </button>

            <button
              onClick={() => setTabValue("Event-Partnerships")}
              className={`${tabValue == "Event-Partnerships" ? "active-create-event-tab" : "create-event-tab"
                } `}
            >
              Event Partnerships
            </button>

            <button
              onClick={() => setTabValue("Sponsor-Packages")}
              className={`${tabValue == "Sponsor-Packages" ? "active-create-event-tab" : "create-event-tab"
                } `}>
              Sponsor Packages
            </button>
            <button
              onClick={() => setTabValue("coupons")}
              className={`${tabValue == "coupons" ? "active-create-event-tab" : "create-event-tab"
                } `}
            >
              Coupons
            </button>
            <button
              onClick={() => setTabValue("Event-Media-Hub")}
              className={`${tabValue == "Event-Media-Hub" ? "active-create-event-tab" : "create-event-tab"
                } `}>
              Event Media Hub
            </button>

            <button
              onClick={() => setTabValue("Social-Hub")}
              className={`${tabValue == "Social-Hub" ? "active-create-event-tab" : "create-event-tab"
                } `}
            >
              Social Hub
            </button>

            <button
              onClick={() => setTabValue("Cancellation-Refund")}
              className={`${tabValue == "Cancellation-Refund" ? "active-create-event-tab" : "create-event-tab"
                } `}
            >
              Cancellation / Refund
            </button>

            <button
              onClick={() => setTabValue("References")}
              className={`${tabValue == "References" ? "active-create-event-tab" : "create-event-tab"
                } `}>
              References
            </button>

          </div>

          <div className="col-lg-9">
            {tabValue == "Event-Essentials" ?
              <div className="">
                <EditEventEssentials id={id}
                  EditData={EditData}
                  setUserData={setUserData}
                  setTabValue={setTabValue}
                  profileinfo={profileinfo}
                  Messages={Messages} />
              </div>
              : tabValue == "Event-Guest" ?
                <div className="">
                  <EditEventGuest id={id} setTabValue={setTabValue} Messages={Messages} />
                </div>
                : tabValue == "Event-Pitch" ?
                  <div className="">
                    <EditEventPitch id={id} EditData={EditData} setTabValue={setTabValue} Messages={Messages} baseUrls={baseUrls} />
                  </div>
                  : tabValue == "Event-Host" ?
                    <div className="">
                      <EditEventHost id={id} setTabValue={setTabValue} Messages={Messages} userData={EditData?.users}/>
                    </div>
                    : tabValue == "Event-Partnerships" ?
                      <div className="">
                        <EditEventPartnerShip id={id} EditData={EditData} setTabValue={setTabValue} Messages={Messages} />
                      </div>
                      : tabValue == "Sponsor-Packages" ?
                        <div className="">
                          <EditSponsorPackage id={id} EditData={EditData} setTabValue={setTabValue} Messages={Messages} />
                        </div>
                        : tabValue == "Event-Media-Hub" ?
                          <div className="">
                            <EditEventMediaHub id={id} setTabValue={setTabValue} Messages={Messages} />
                          </div>
                          : tabValue == "Social-Hub" ?
                            <div className="">
                              <EditEventSocialHub id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                            : tabValue == "Cancellation-Refund" ?
                              <div className="">
                                <EditEventCancellationRefund id={id} setTabValue={setTabValue} Messages={Messages} />
                              </div>
                              : tabValue == "coupons" ?
                                <div className="">
                                  <EditCreateCoupon id={id} setTabValue={setTabValue} Messages={Messages} />
                                </div> : tabValue == "References" ?
                                  <div className="">
                                    <EditEventReferences id={id} setTabValue={setTabValue} Messages={Messages} />
                                  </div> : ''
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEvents;
