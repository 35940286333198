import arrow from "../../assets/images/arrow2.svg";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useEffect, useState, useRef } from "react";
import {
  CreateEventGuests,
  GetGuestData,
} from "../../utils/CreateEvents/createEvent";
import {
  Get_UserInterest,
  Get_languages_list,
} from "../../utils/basicInformation/basicinformationManager";
import Loader from "../Loader/Loader";
import { Get_Edit_Event_Guest } from "../../utils/EditEvent/editEvent";
import ReactQuill from "react-quill";
import { FiPlus } from "react-icons/fi";
import { LuMinus } from "react-icons/lu";
import { CreateInterest } from "../../utils/profile/GetInterests";
import { ToastContainer, toast } from "react-toastify";
import { IoMdInformationCircleOutline } from "react-icons/io";
import InputRange from "react-input-range";

const EditEventGuest = ({ setTabValue, id, Messages }) => {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [predefineIntrest, setPredefineIntrest] = useState([]);
  const [showIntrest, setShowIntrest] = useState([]);
  const [listingLanguage, setListingLanguage] = useState([]);
  const [showlanguages, setShowLanguages] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);
  const [showInterestSection, setShowInterestSection] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [newInterest, setNewInterest] = useState("");
  const [interestError, setInterestError] = useState("");

  const [guestAccompanying, setGuestAccompanying] = useState([])
  const [guestLocation, setGuestLocation] = useState([]);
  const [guestGender, setGuestGender] = useState([]);
  const [guestAge, setGuestAge] = useState([]);
  const [guestMaterial, setGuestMaterial] = useState([]);
  const [guestEducation, setGuestEducation] = useState([]);
  const [guestProfession, setGuestProfession] = useState([]);
  const [guestIndustry, setGuestIndustry] = useState([]);
  const [guestHouseIncome, setGuestHouseIncome] = useState([]);
  const [guestTechnologyProficiency, setGuestTechnologyProficiency] = useState([]);
  const [guestSocialMedia, setGuestSocialMedia] = useState([]);

  const [showGuestAccompanying, setShowGuestAccompanying] = useState([]);
  const [showGuestLocation, setShowGuestLocation] = useState([]);
  const [showGuestGender, setShowGuestGender] = useState([]);
  const [showGuestAge, setShowGuestAge] = useState([]);
  const [showGuestMarital, setShowGuestMarital] = useState([]);
  const [showGuestEducation, setShowGuestEducation] = useState([]);
  const [showGuestProfession, setShowGuestProfession] = useState([]);
  const [showGuestIndustry, setShowGuestIndustry] = useState([]);
  const [showHouseIncome, setShowHouseIncome] = useState([]);
  const [showGuestTechnologyProficiency, setShowGuestTechnologyProficiency] = useState([])
  const [showGuestSocialMedia, setShowGuestSocialMedia] = useState([])

  const [isHovered, setIsHovered] = useState(false);

  const [input, setInput] = useState({
    guests_locations: "",
    guests_count_min: "",
    guests_count_max: "",
    accompanying_guests: "",
    guests_gender: "",
    guests_age: "",
    guests_marital_status: "",
    guests_education: "",
    guests_profession: "",
    guests_industry: "",
    guests_household_income: "",
    guests_technology_proficiency: "",
    guests_social_media_usage: "",
    guests_profile_summary: "",
    languages: "",
    tags: [],
  });

  const refs = {
    guests_locations: useRef(null),
    guests_count_min: useRef(null),
    guests_count_max: useRef(null),
    accompanying_guests: useRef(null),
    guests_gender: useRef(null),
    guests_age: useRef(null),
    guests_marital_status: useRef(null),
    guests_education: useRef(null),
    guests_profession: useRef(null),
    guests_industry: useRef(null),
    guests_household_income: useRef(null),
    guests_technology_proficiency: useRef(null),
    guests_social_media_usage: useRef(null),
    guests_profile_summary: useRef(null),
    languages: useRef(null),
    tags: useRef(null),
  };

  const [error, setError] = useState({});

  useEffect(() => {
    try {
      Get_Edit_Event_Guest(id).then((response) => {
        const data = response?.data?.data

        const Intrest = response?.data?.data?.tags?.map((item) => ({
          value: item?.user_interests?.id,
          label: item?.user_interests?.name,
        }));
        setShowIntrest(Intrest);
        const listIntrest = Intrest?.map((item) => item.value);

        const guestAccompanying = data?.accompanying_guests?.map((item) => ({
          value: item?.user_accompanying_guests?.id,
          label: item?.user_accompanying_guests?.guest_type,
        }));
        setShowGuestAccompanying(guestAccompanying)
        const listAccompanying = guestAccompanying?.map((item) => item.value);


        const guestlocation = data?.guest_locations?.map((item) => ({
          value: item?.guest_location?.id,
          label: item?.guest_location?.location_name,
        }));
        setShowGuestLocation(guestlocation)
        const listlocation = guestlocation?.map((item) => item.value);


        const guestGender = data?.guest_gender?.map((item) => ({
          value: item?.guest_gender?.id,
          label: item?.guest_gender?.gender_type,
        }))
        setShowGuestGender(guestGender)
        const listguestGender = guestGender?.map((item) => item.value);


        const guestAge = data?.guest_age?.map((item) => ({
          value: item?.guest_age?.id,
          label: item?.guest_age?.age_group,
        }))
        setShowGuestAge(guestAge)
        const listguestAge = guestAge?.map((item) => item.value);


        const guestMarital = data?.guest_marital_status?.map((item) => ({
          value: item?.merital_status?.id,
          label: item?.merital_status?.marital_status,
        }))
        setShowGuestMarital(guestMarital)
        const listguestMaritalStatus = guestMarital?.map((item) => item.value);


        const guestEducation = data?.guest_educations?.map((item) => ({
          value: item?.guest_educations?.id,
          label: item?.guest_educations?.education_level,
        }))
        setShowGuestEducation(guestEducation)
        const listguestEducation = guestEducation?.map((item) => item.value);


        const guestprofession = data?.guest_professions?.map((item) => ({
          value: item?.guest_profession?.id,
          label: item?.guest_profession?.profession,
        }))
        setShowGuestProfession(guestprofession)
        const listguestProfession = guestprofession?.map((item) => item.value);


        const GuestIndustry = data?.guest_industry?.map((item) => ({
          value: item?.industry?.id,
          label: item?.industry?.industry,
        }))
        setShowGuestIndustry(GuestIndustry)
        const listguestIndustry = GuestIndustry?.map((item) => item.value);


        const GuestHouseIncome = data?.guest_house_hold_income?.map((item) => ({
          value: item?.house_hold_income?.id,
          label: item?.house_hold_income?.house_hold_income,
        }))
        setShowHouseIncome(GuestHouseIncome)
        const listguestHouseIncome = GuestHouseIncome?.map((item) => item.value);


        const GuestTechnologyProficiency = data?.technology_proficiency?.map((item) => ({
          value: item?.technology_proficiency?.id,
          label: item?.technology_proficiency?.proficiency_level,
        }))
        setShowGuestTechnologyProficiency(GuestTechnologyProficiency)
        const listguestGuestTechnologyProficiency = GuestTechnologyProficiency?.map((item) => item.value);


        const GuestSocialMedia = data?.guest_social_media_usage?.map((item) => ({
          value: item?.social_media_usage?.id,
          label: item?.social_media_usage?.usage_level,
        }))
        setShowGuestSocialMedia(GuestSocialMedia)
        const listguestGuestSocialMedia = GuestSocialMedia?.map((item) => item.value);

        const languages = response?.data?.data?.languages;
        setShowLanguages(
          languages?.map((item) => {
            return {
              value: item?.languages,
              label: item?.languages,
            };
          })
        );

        const lang = languages?.map((item) => item?.languages);
        setInput((currentInput) => ({
          ...currentInput,
          ...response?.data?.data,
          languages: JSON.stringify(lang),
          tags: JSON.stringify(listIntrest),
          guests_locations: JSON.stringify(listlocation),
          accompanying_guests: JSON.stringify(listAccompanying),
          guests_gender: JSON.stringify(listguestGender),
          guests_age: JSON.stringify(listguestAge),
          guests_marital_status: JSON.stringify(listguestMaritalStatus),
          guests_education: JSON.stringify(listguestEducation),
          guests_profession: JSON.stringify(listguestProfession),
          guests_industry: JSON.stringify(listguestIndustry),
          guests_household_income: JSON.stringify(listguestHouseIncome),
          guests_technology_proficiency: JSON.stringify(listguestGuestTechnologyProficiency),
          guests_social_media_usage: JSON.stringify(listguestGuestSocialMedia),
        }));
      });
    } catch (errr) {
      console.log(errr);
    }
  }, [id]);

  const GetStaticDataFunction = async () => {
    try {
      await GetGuestData().then((response) => {
        const data = response?.data?.data
        setDataList(data);

        setGuestAccompanying(
          data?.accompanying_guests?.map((item) => {
            return {
              value: item?.id,
              label: item?.guest_type,
            };
          })
        )

        setGuestLocation(
          data?.guests_location?.map((item) => {
            return {
              value: item?.id,
              label: item?.location_name,
            };
          })
        );

        setGuestGender(
          data?.guests_gender?.map((item) => {
            return {
              value: item?.id,
              label: item?.gender_type,
            };
          })
        );

        setGuestAge(
          data?.guests_age?.map((item) => {
            return {
              value: item?.id,
              label: item?.age_group,
            };
          })
        );

        setGuestMaterial(
          data?.guests_marital_status?.map((item) => {
            return {
              value: item?.id,
              label: item?.marital_status,
            };
          })
        );

        setGuestEducation(
          data?.guests_education?.map((item) => {
            return {
              value: item?.id,
              label: item?.education_level,
            };
          })
        );

        setGuestProfession(
          data?.guests_profession?.map((item) => {
            return {
              value: item?.id,
              label: item?.profession,
            };
          })
        );

        setGuestIndustry(
          data?.guests_industry?.map((item) => {
            return {
              value: item?.id,
              label: item?.industry,
            };
          })
        );

        setGuestHouseIncome(
          data?.guests_household_income?.map((item) => {
            return {
              value: item?.id,
              label: item?.house_hold_income
            };
          })
        );
        setGuestTechnologyProficiency(
          data?.guest_technology_proficiency?.map((item) => {
            return {
              value: item?.id,
              label: item?.proficiency_level
            };
          })
        );

        setGuestSocialMedia(
          data?.guests_social_media_usage?.map((item) => {
            return {
              value: item?.id,
              label: item?.usage_level,
            };
          })
        );

      });
    } catch (errr) {
      console.log(errr);
    } finally {
      setPageLoad(false);
    }

    try {
      await Get_languages_list().then((response) => {
        const lan = response?.data?.data;
        setListingLanguage(
          lan.map((item) => {
            return {
              value: item?.name,
              label: item?.name,
            };
          })
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getUserInterests = async () => {
    try {
      await Get_UserInterest().then((response) => {
        const getInterest = response?.data;
        const options = getInterest?.map((topic) => ({
          value: topic.id,
          label: topic.name,
        }));
        setPredefineIntrest(options);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetStaticDataFunction();
    getUserInterests();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleGuestProfileSummary = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        guests_profile_summary: value,
      }));
      setError({ ...error, guests_profile_summary: "" });
    } else {
      setError({
        ...error,
        guests_profile_summary:
          "Guest Profile Summary cannot exceed 500 characters.",
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        await CreateEventGuests(input, id).then((results) => {
          if (results?.data?.success) {
            setTabValue("Event-Pitch");
            Messages(results?.data?.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const handleAddIntrest = (selectedOption) => {
    setShowIntrest(selectedOption);
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, tags: JSON.stringify(list) });
  };

  const handleAddLanguage = (selectedOptions) => {
    const capitalizedLang = selectedOptions
      ?.map((item) => item?.value)
      .filter((value) => /^[a-zA-Z\s()\/]*$/.test(value))
      .map((value) => value.charAt(0).toUpperCase() + value.slice(1));
    setInput({ ...input, languages: JSON.stringify(capitalizedLang) });

    const showInCretable = selectedOptions
      ?.filter((item) => /^[a-zA-Z\s()\/]*$/.test(item?.value))
      .map((item) => ({
        value: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
        label: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
      }));
    setShowLanguages(showInCretable);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const guestsLocations = Array.isArray(input.guests_locations)
      ? input.guests_locations
      : JSON.parse(input.guests_locations || "[]");

    if (guestsLocations.length <= 0) {
      newErrors.guests_locations = "Guest Location is required";
      valid = false;
    }

    if (!input.guests_count_min) {
      newErrors.guests_count_min = "Minimum Guest is required";
      valid = false;
    } else if (!input.guests_count_max) {
      newErrors.guests_count_max = "Maximum Guest is required";
      valid = false;
    }

    const guestsAccompanying = Array.isArray(input.accompanying_guests)
      ? input.accompanying_guests
      : JSON.parse(input.accompanying_guests || "[]");
    if (guestsAccompanying.length <= 0) {
      newErrors.accompanying_guests = "Accompanying Guest is required";
      valid = false;
    }

    const guestsGender = Array.isArray(input.guests_gender)
      ? input.guests_gender
      : JSON.parse(input.guests_gender || "[]");
    if (guestsGender.length <= 0) {
      newErrors.guests_gender = "Guest Gender is required";
      valid = false;
    }

    const guestsAge = Array.isArray(input.guests_age)
      ? input.guests_age
      : JSON.parse(input.guests_age || "[]");
    if (guestsAge.length <= 0) {
      newErrors.guests_age = "Guest Age is required";
      valid = false;
    }


    const guestsEducation = Array.isArray(input.guests_education)
      ? input.guests_education
      : JSON.parse(input.guests_education || "[]");
    if (guestsEducation.length <= 0) {
      newErrors.guests_education = "Guest Education is required";
      valid = false;
    }
    const guestsProfession = Array.isArray(input.guests_profession)
      ? input.guests_profession
      : JSON.parse(input.guests_profession || "[]");
    if (guestsProfession.length <= 0) {
      newErrors.guests_profession = "Guest Profession is required";
      valid = false;
    }


    if (input?.tags.length <= 0) {
      newErrors.tags = "Guest Interest is required";
      valid = false;
    } else if (
      !Array.isArray(JSON.parse(input.tags)) ||
      JSON.parse(input.tags).length < 5
    ) {
      newErrors.tags = "Select at least 5 Guest Interest";
      valid = false;
    } else if (JSON.parse(input.tags).length > 15) {
      newErrors.tags = "Select at most 15 Guest Interest";
      valid = false;
    }
    if (showlanguages.length == 0) {
      newErrors.languages = "Guest Language  is required";
      valid = false;
    }

    const guest_summaray = input.guests_profile_summary?.replace(/<p><br><\/p>/g, '')
    if (guest_summaray == undefined || guest_summaray?.trim() == "") {
      newErrors.guests_profile_summary = "Guests Profile Summary is required";
      valid = false;
    }
    setError(newErrors);
    return valid;
  };

  const getAddSelectInterests = async (resData) => {
    try {
      await Get_UserInterest().then((response) => {
        const getInterest = response?.data;
        const foundItem = getInterest?.find(item => item?.name == resData);
        const newItem = {
          value: foundItem?.id,
          label: foundItem?.name,
        }
        // setShowIntrest(prevItems => [...prevItems, newItem])

        setShowIntrest(prevItems => {
          const updatedItems = [...prevItems, newItem];
          const updatedValues = updatedItems.map(item => item.value);
          setInput({ ...input, tags: JSON.stringify(updatedValues) });
          return updatedItems;
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createInt = async () => {
    if (!newInterest) {
      setInterestError("Interest Required");
    } else {
      setAddBtnLoading(true);
      setInterestError("");
      const fData = {
        name: newInterest,
      };
      try {
        const response = await CreateInterest(fData);
        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
          });
          setNewInterest("");
          getUserInterests();
          setShowInterestSection(false);
          const resData = response?.data?.newly_added;
          resData && getAddSelectInterests(resData);
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
          });
          setNewInterest("");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
        });
        setNewInterest("");
      } finally {
        setAddBtnLoading(false);
      }
    }
  };

  const handleGuestAccompanying = (selectedOption) => {
    setShowGuestAccompanying(selectedOption)
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, accompanying_guests: JSON.stringify(list) });
  }

  const handleGuestGender = (selectedOption) => {
    setShowGuestGender(selectedOption)
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, guests_gender: JSON.stringify(list) });
  }

  const handleGuestAge = (selectedOption) => {
    setShowGuestAge(selectedOption)
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, guests_age: JSON.stringify(list) });
  }


  const handleGuestLocation = (selectedOption) => {
    // setShowGuestLocation(selectedOption)
    // const list = selectedOption?.map((item) => {
    //   return item.value;
    // });
    // setInput({ ...input, guests_locations: JSON.stringify(list) });

    if (selectedOption.some(option => option.label === 'All')) {
      // Check if "All" is selected
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        // If "All" is selected, select all options except "All"
        const allOptions = guestLocation.filter(option => option.label !== 'All');
        setShowGuestLocation(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_locations: JSON.stringify(allValues) });
      } else {
        // If "All" is deselected, remove all options
        setShowGuestLocation([]);
        setInput({ ...input, guests_locations: JSON.stringify([]) });
      }
    } else {
      // Normal behavior
      setShowGuestLocation(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_locations: JSON.stringify(list) });
    }
  };

  const handleGuestMaterial = (selectedOption) => {
    if (selectedOption.some(option => option.label === 'All')) {
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        const allOptions = guestMaterial.filter(option => option.label !== 'All');
        setShowGuestMarital(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_marital_status: JSON.stringify(allValues) });
      } else {
        setShowGuestMarital([]);
        setInput({ ...input, guests_marital_status: JSON.stringify([]) });
      }
    } else {
      setShowGuestMarital(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_marital_status: JSON.stringify(list) });
    }
  }

  const handleGuestEducation = (selectedOption) => {
    if (selectedOption.some(option => option.label === 'All')) {
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        const allOptions = guestEducation.filter(option => option.label !== 'All');
        setShowGuestEducation(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_education: JSON.stringify(allValues) });
      } else {
        setShowGuestEducation([]);
        setInput({ ...input, guests_education: JSON.stringify([]) });
      }
    } else {
      setShowGuestEducation(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_education: JSON.stringify(list) });
    }
  }

  const handleGuestProfession = (selectedOption) => {
    if (selectedOption.some(option => option.label === 'All')) {
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        const allOptions = guestProfession.filter(option => option.label !== 'All');
        setShowGuestProfession(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_profession: JSON.stringify(allValues) });
      } else {
        setShowGuestProfession([]);
        setInput({ ...input, guests_profession: JSON.stringify([]) });
      }
    } else {
      setShowGuestProfession(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_profession: JSON.stringify(list) });
    }
  }

  const handleGuestIndustry = (selectedOption) => {
    if (selectedOption.some(option => option.label === 'All')) {
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        const allOptions = guestIndustry.filter(option => option.label !== 'All');
        setShowGuestIndustry(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_industry: JSON.stringify(allValues) });
      } else {
        setShowGuestIndustry([]);
        setInput({ ...input, guests_industry: JSON.stringify([]) });
      }
    } else {
      setShowGuestIndustry(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_industry: JSON.stringify(list) });
    }
  }

  const handleGuestHouseIncome = (selectedOption) => {
    if (selectedOption.some(option => option.label === 'All')) {
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        const allOptions = guestHouseIncome.filter(option => option.label !== 'All');
        setShowHouseIncome(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_household_income: JSON.stringify(allValues) });
      } else {
        setShowHouseIncome([]);
        setInput({ ...input, guests_household_income: JSON.stringify([]) });
      }
    } else {
      setShowHouseIncome(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_household_income: JSON.stringify(list) });
    }
  }

  const handleGuestTechnologyProficiency = (selectedOption) => {
    if (selectedOption.some(option => option.label === 'All')) {
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        const allOptions = guestTechnologyProficiency.filter(option => option.label !== 'All');
        setShowGuestTechnologyProficiency(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_technology_proficiency: JSON.stringify(allValues) });
      } else {
        setShowGuestTechnologyProficiency([]);
        setInput({ ...input, guests_technology_proficiency: JSON.stringify([]) });
      }
    } else {
      setShowGuestTechnologyProficiency(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_technology_proficiency: JSON.stringify(list) });
    }
  }

  const handleGuestSocialMedia = (selectedOption) => {
    if (selectedOption.some(option => option.label === 'All')) {
      const allSelected = selectedOption.find(option => option.label === 'All');
      if (allSelected) {
        const allOptions = guestSocialMedia.filter(option => option.label !== 'All');
        setShowGuestSocialMedia(allOptions);
        const allValues = allOptions.map(option => option.value);
        setInput({ ...input, guests_social_media_usage: JSON.stringify(allValues) });
      } else {
        setShowGuestSocialMedia([]);
        setInput({ ...input, guests_social_media_usage: JSON.stringify([]) });
      }
    } else {
      setShowGuestSocialMedia(selectedOption);
      const list = selectedOption.map((item) => item.value);
      setInput({ ...input, guests_social_media_usage: JSON.stringify(list) });
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleRangeChange = (range) => {
    setInput((prevInput) => ({
      ...prevInput,
      guests_count_min: range.min,
      guests_count_max: range.max,
    }));
  };

  const capitalizeWords = (string) => {
    return string.split(' ')?.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    )?.join(' ');
  };

  const handleInterestChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedValue = capitalizeWords(inputValue);
    setNewInterest(capitalizedValue);
  }

  // rect quill length count
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    let textWithNewlines = div.innerHTML

    if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
      textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
    } else {
      // Otherwise, apply replacements
      textWithNewlines = textWithNewlines
        .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
    }

    div.innerHTML = textWithNewlines;
    return div.textContent || div.innerText || "";
  };

  const getDescriptionLength = () => {
    const cleanDescription = stripHtmlTags(input.guests_profile_summary);
    return cleanDescription.length;
  };

  const maxLength = 500;
  const currentLength = getDescriptionLength();
  const remainingLength = maxLength - currentLength;
  //

  // useEffect(() => {
  //   const firstErrorKey = Object?.keys(error)[0];
  //   if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey]?.current) {
  //     refs[firstErrorKey]?.current?.focus();
  //   }
  // }, [error]);

  return (
    <>
      <ToastContainer />
      {pageLoad ? (
        <Loader />
      ) : (
        <div className="basicInfo">
          {/* <h2 className="basicinfo-head">Event Guest</h2> */}
          <div className="d-flex align-items-center gap-1 position-relative">
            <p className="createevent-head-p">This section is dedicated to gathering comprehensive details about the demographics and preferences of
              your event's attendees. By providing following insights, Sponsors get to effectively match their target audience with the event Guests.
              This enables them to make informed decisions about sponsoring the event and ensuring alignment with their branding and marketing objectives.</p>
            {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Event Guests</h2>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <IoMdInformationCircleOutline size={23} />
            </div>
            {isHovered && (
              <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <p className="info-popup-text">This section is dedicated to gathering comprehensive details about the demographics and preferences of your event's attendees. By providing following insights, Sponsors get to effectively match their target audience with the event Guests. This enables them to make informed decisions about sponsoring the event and ensuring alignment with their branding and marketing objectives.</p>
              </div>
            )} */}
          </div>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Guests Location<span className="asterisk">*</span>
                  </label>
                  <IoMdInformationCircleOutline size={16} title="Specify the geographic origin of your guests." />

                  {/* <select
                    className="form-control"
                    name="guests_locations"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Location
                    </option>
                    {dataList?.guests_location?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_locations == val?.id}
                      >
                        {val?.location_name}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestLocation}
                    name="location"
                    options={guestLocation}
                    onChange={handleGuestLocation}
                    placeholder="Select Guest Location"
                    closeMenuOnSelect={false}
                    ref={refs?.guests_locations}
                  />
                  {error.guests_locations && (
                    <span className="error">{error.guests_locations}</span>
                  )}
                </div>
              </div>

              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Count<span className="asterisk">*</span>
                  </label>
                  <IoMdInformationCircleOutline size={16} title="Select the expected range of guest attendance for your event" />
                  <div className="card-group-item">
                    <div className="range-content p-0">
                      <div className="d-flex gap-4">
                        <div className="">
                          <input
                            type="number"
                            min="0"
                            className="filter-section-input"
                            name="guests_count_min"
                            placeholder="Min"
                            value={input.guests_count_min}
                            onChange={handleChange}
                            ref={refs?.guests_count_min}
                          />
                        </div>
                        <div className="">
                          <input
                            type="number"
                            max="100000"
                            className="filter-section-input"
                            name="guests_count_max"
                            placeholder="Max"
                            value={input.guests_count_max}
                            onChange={handleChange}
                            ref={refs?.guests_count_max}
                          />
                        </div>
                      </div>

                      <div className="mt-2 px-3 mb-2">
                        <InputRange
                          maxValue={100000}
                          minValue={0}
                          name="guest_count_min_max"
                          value={{ min: input.guests_count_min, max: input.guests_count_max }}
                          onChange={handleRangeChange}
                          formatLabel={() => ''}
                        />
                      </div>
                      {error.guests_count_min && (
                        <span className="error">{error.guests_count_min}</span>
                      )}
                      {error.guests_count_max && (
                        <span className="error">{error.guests_count_max}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Accompanying Guest<span className="asterisk">*</span>
                  </label>
                  <IoMdInformationCircleOutline size={16} title="Indicate who will accompany the guest to the event." />
                  {/* <select
                    className="form-control"
                    name="accompanying_guests"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Accompanying Guest
                    </option>
                    {dataList?.accompanying_guests?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.accompanying_guests == val?.id}
                      >
                        {val?.guest_type}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestAccompanying}
                    name="accpomping"
                    options={guestAccompanying}
                    onChange={handleGuestAccompanying}
                    placeholder="Select Accompanying Guest"
                    closeMenuOnSelect={false}
                    ref={refs?.accompanying_guests}
                  />
                  {error.accompanying_guests && (
                    <span className="error">{error.accompanying_guests}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div
                className="col-md-8"

              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Interest<span className="asterisk">*</span>
                  </label>
                  <IoMdInformationCircleOutline size={16} title="Enter the primary interests or hobbies of your guests." />

                  <div className="d-flex align-items-center gap-2">
                    <Select
                      isMulti
                      value={showIntrest}
                      options={predefineIntrest}
                      onChange={handleAddIntrest}
                      className="w-100"
                      classNamePrefix="Select"
                      placeholder="Select Guest Interest"
                      closeMenuOnSelect={false}
                      ref={refs?.tags}
                    />
                    <button
                      className={showInterestSection ? "active-add-interests-btn m-0" : "add-interests-btn m-0 "}
                      type="button"
                      onClick={() => setShowInterestSection(!showInterestSection)}>
                      {showInterestSection ? (
                        <>
                          <FiPlus size={18} />
                          Add More
                          {/* <LuMinus size={20} color="white" /> */}
                        </>) : (<>
                          <FiPlus size={18} />
                          Add More
                        </>)}
                    </button>
                  </div>
                  {error.tags && <span className="error">{error.tags}</span>}
                </div>
              </div>

              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Language<span className="asterisk">*</span>
                  </label>
                  <IoMdInformationCircleOutline size={16} title="List the primary languages spoken by your guests." />

                  <CreatableSelect
                    isMulti
                    value={showlanguages}
                    classNamePrefix="select"
                    placeholder="Select Guest Language"
                    className=""
                    onChange={handleAddLanguage}
                    options={listingLanguage}
                    ref={refs?.languages}
                  />
                  {error.languages && (
                    <span className="error">{error.languages}</span>
                  )}
                </div>
              </div>
            </div>

            {showInterestSection && (
              <div className="row mt-0">
                <div className="form-group col-6 col-md-8">
                  <label className="form-label">
                    New Interest<span className="asterisk">*</span>
                  </label>

                  <div className="d-flex gap-2">
                    <input
                      className="form-control"
                      placeholder="Enter New Interest"
                      value={newInterest}
                      name="newInterest"
                      onChange={handleInterestChange}
                    />

                    <button
                      className=" active-add-interests-btn"
                      type="button"
                      onClick={createInt}>
                      {addBtnLoading && (
                        <div className=" spinner-border text-light"
                          role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      Add
                    </button>
                  </div>
                  {interestError && (
                    <p className="error ms-1">{interestError}</p>
                  )}
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Gender<span className="asterisk">*</span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Specify the gender identity of your guests" />

                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_gender"
                    onChange={handleChange} >
                    <option selected disabled>
                      Select Guest Gender
                    </option>
                    {dataList?.guests_gender?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_gender == val?.id}>
                        {val?.gender_type}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestGender}
                    name="gender"
                    options={guestGender}
                    onChange={handleGuestGender}
                    placeholder="Select Guest Gender"
                    closeMenuOnSelect={false}
                    ref={refs?.guests_gender}
                  />
                  {error.guests_gender && (
                    <span className="error">{error.guests_gender}</span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Guest Age<span className="asterisk">*</span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Provide the age or age range of your guests" />
                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_age"
                    onChange={handleChange}>
                    <option selected disabled>
                      Select Age
                    </option>
                    {dataList?.guests_age?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_age == val?.id}
                      >
                        {val?.age_group}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestAge}
                    name="age"
                    options={guestAge}
                    onChange={handleGuestAge}
                    placeholder="Select Guest Age"
                    closeMenuOnSelect={false}
                    ref={refs?.guests_age}
                  />
                  {error.guests_age && (
                    <span className="error">{error.guests_age}</span>
                  )}
                </div>
              </div>
              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Marital Status<span className="asterisk"></span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Select the marital status of your guests." />

                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_marital_status"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Marital Status
                    </option>
                    {dataList?.guests_marital_status?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_marital_status == val?.id}
                      >
                        {val?.marital_status}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestMarital}
                    name="Material"
                    options={guestMaterial}
                    onChange={handleGuestMaterial}
                    placeholder="Select Guest Marital Status"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Education<span className="asterisk">*</span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Indicate the highest education level achieved by your guests." />

                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_education"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Education
                    </option>
                    {dataList?.guests_education?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_education == val?.id}
                      >
                        {val?.education_level}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestEducation}
                    name="Material"
                    options={guestEducation}
                    onChange={handleGuestEducation}
                    placeholder="Select Guest Education"
                    closeMenuOnSelect={false}
                    ref={refs?.guests_education}
                  />

                  {error.guests_education && (
                    <span className="error">{error.guests_education}</span>
                  )}
                </div>
              </div>

              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Profession<span className="asterisk">*</span>
                    <IoMdInformationCircleOutline size={16} color="black" title="List the occupations of your guests." />

                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_profession"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Profession
                    </option>
                    {dataList?.guests_profession?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_profession == val?.id}
                      >
                        {val?.profession}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestProfession}
                    name="profession"
                    options={guestProfession}
                    onChange={handleGuestProfession}
                    placeholder=" Select Guest Profession"
                    closeMenuOnSelect={false}
                    ref={refs?.guests_profession}
                  />
                  {error.guests_profession && (
                    <span className="error">{error.guests_profession}</span>
                  )}
                </div>
              </div>
              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Industry<span className="asterisk"></span>
                    <IoMdInformationCircleOutline size={16} color="black" title="List the professional fields of your guests." />

                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_industry"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Industry
                    </option>
                    {dataList?.guests_industry?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_industry == val?.id}
                      >
                        {val?.industry}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestIndustry}
                    name="industry"
                    options={guestIndustry}
                    onChange={handleGuestIndustry}
                    placeholder="Select Guest Industry"
                    closeMenuOnSelect={false}
                  />
                  {error.guests_industry && (
                    <span className="error">{error.guests_industry}</span>
                  )}
                </div>
              </div>


            </div>

            <div className="row mt-2">
              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Household Income<span className="asterisk"></span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Specify the income range of your guests' households." />
                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_household_income"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Household Income
                    </option>
                    {dataList?.guests_household_income?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_household_income == val?.id}
                      >
                        {val?.house_hold_income}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showHouseIncome}
                    name="houseIncome"
                    options={guestHouseIncome}
                    onChange={handleGuestHouseIncome}
                    placeholder="Select Guest Household Income"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Technology Proficiency
                    <span className="asterisk"></span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Rate the technology skill level of your guests." />

                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_technology_proficiency"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Technology Proficiency
                    </option>
                    {dataList?.guest_technology_proficiency?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={
                          input?.guests_technology_proficiency == val?.id
                        }
                      >
                        {val?.proficiency_level}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestTechnologyProficiency}
                    name="technologyProficiency"
                    options={guestTechnologyProficiency}
                    onChange={handleGuestTechnologyProficiency}
                    placeholder="Select Guest Technology Proficiency"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div
                className="col-md-4"
              >
                <div className="form-group">
                  <label className="form-label">
                    Guest Social Media Usage<span className="asterisk"></span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Describe how frequently your guests use social media." />

                  </label>
                  {/* <select
                    className="form-control"
                    name="guests_social_media_usage"
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select Guest Social Media Usage
                    </option>

                    {dataList?.guests_social_media_usage?.map((val, idx) => (
                      <option
                        value={val?.id}
                        key={idx}
                        selected={input?.guests_social_media_usage == val?.id}
                      >
                        {val?.usage_level}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    value={showGuestSocialMedia}
                    name="socialMedia"
                    options={guestSocialMedia}
                    onChange={handleGuestSocialMedia}
                    placeholder="Select Guest Social Media Usage"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div
                className="col-md-12"
              >
                <div className="d-flex justify-content-between">
                  <label className="form-label">
                    Guest Profile Summary<span className="asterisk">*</span>
                    <IoMdInformationCircleOutline size={16} color="black" title="Summarize your guests’ demographics and preferences. In addition to the data collected, consider adding insights on Community, Cultural Background, Buying Preferences, Leisure Activities, Disability Access Needs, Dietary Preferences/Restrictions, Travel Preferences etc." />

                  </label>
                  <p className="Character-0500">
                    Character:<span className="text-style-1">
                      {/* remainingLength */}
                      {currentLength}/{maxLength}
                    </span>
                  </p>
                </div>

                <div style={{ height: "220px" }}>
                  <ReactQuill
                    value={input.guests_profile_summary}
                    onChange={handleGuestProfileSummary}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder="Guest Profile Summary"
                  // ref={refs?.guests_profile_summary}
                  />
                </div>
                {error.guests_profile_summary && (
                  <span className="error">{error.guests_profile_summary}</span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-md-end align-items-center mt-3 mt-lg-1">
              <div className="d-flex flex-column flex-md-row gap-2">
                <button className="d-flex align-items-center gap-1 saveandcontinue-btn" type="submit">
                  {loading ? (
                    <div
                      className="spinner-border text-light "
                      role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                  Save and Continue
                  <img className="arrow-img" src={arrow} alt="arrow" />
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default EditEventGuest;
