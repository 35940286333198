
import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import imageicon from "../../assets/images/image-upload-icon.svg";
import Documenticon from "../../assets/images/document-upload-icon.svg";
import arrow from "../../assets/images/arrow2.svg";
import pdfimg from "../../assets/images/pdf.png";
import WarningModal from "../Common/WarningModal";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import upload from "../../assets/images/upload-icon.png";
import { Event_MediaHub } from "../../utils/CreateEvents/createEvent";
import { DeleteBanner, Get_Edit_Event_Media_Hub } from "../../utils/EditEvent/editEvent";
import { Event_Image_Docs_delete } from "../../utils/editevents/EditEvent";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import SelectChampModal from "../CreateEvents/SelectChampsModal";
import { Express_Event_MediaHub, GetExpress_Event_Media_Hub } from "../../utils/CreateExpreesEvent/CreateExpressEvent";
import BannerLogoSection from "../Common/CreateEvent/BannerLogoSection";
import EventMediaHubImageDoc from "../Common/CreateEvent/EventMediaHubImageDoc";

const ExpressEventMediaHub = ({ event_id, setTabValue, Messages, isEdit, Eid, tabValue }) => {
  const [video_link, setVideo_link] = useState('');
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectChampMdl, setSelectChampMdl] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [multipleVideoLink, setMultipleVideoLink] = useState([]);
  const [showPdf, setShowPdf] = useState([]);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;
  const [formData, setFormData] = useState({
    banner:null,
    logo:null,
    website_link:"",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
  });
  const [selectedBannerFiles, setSelectedBannerFiles] = useState([]);
  const [errors, setErrors] = useState({
    multipleVideoLink: "",
    imagess: "",
    documentss: "",
  });
  const [predefine_Banner, setPredefine_Banner] = useState([]);
  const [showImages, setShowImages] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const refs = {
    banner: useRef(null),
    logo: useRef(null),
    website_link: useRef(null)
  };

  const EventmediaData = async () => {
    try {
      GetExpress_Event_Media_Hub(Eid).then((response) => {
        const data = response?.data?.data;
        setFormData(data);
        setMultipleVideoLink(data?.video_links.map((item) => item?.video_link));
        setShowImages(
          data?.event_gellary.filter(
            (item) => item?.mime_type?.toLowerCase() == "jpg" ||
            item?.mime_type?.toLowerCase() == "png" ||
            item?.mime_type?.toLowerCase() == "jpeg" ||
            item?.mime_type?.toLowerCase() == "webp" ||
            item?.mime_type?.toLowerCase() === "gif" ||
            item?.mime_type?.toLowerCase() === "bmp" ||
            item?.mime_type?.toLowerCase() === "svg+xml"
         
          )
        );
        setShowPdf(
          data?.event_gellary.filter(
            (item) => item?.mime_type?.toLowerCase() == "pdf" ||
            item?.mime_type?.toLowerCase() == "doc" ||
            item?.mime_type?.toLowerCase() == "docx" ||
            item?.mime_type?.toLowerCase() == "xls" ||
            item?.mime_type?.toLowerCase() == "xlsx" ||
            item?.mime_type?.toLowerCase() == "txt" 
          )
        );
        //setSelectedBannerFiles([]);
        setPredefine_Banner(data?.event_banner);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isEdit && tabValue == 'Event-Media-Hub') {
      EventmediaData();
    }
  }, []);

  const handleAddMultipleURL = () => {
    if (video_link.trim() !== '') {
      const updatedLinks = [...multipleVideoLink, video_link];
      if (updatedLinks.length <= 5) {
        setMultipleVideoLink(updatedLinks);
        setVideo_link('');
      } else {
        toast.warn("You can only add up to 5 video links.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        });
      }
    }
  };

  const handleDeleteURL = (index) => {
    const updatedVideoLinks = multipleVideoLink.filter((item, idx) => idx !== index);
    setMultipleVideoLink(updatedVideoLinks);
  };

  const handleFileChangeImage = (event) => {
    const files = event.target.files;
    const imageArray = [];

    if (images.length + files.length + showImages?.length > 6) {
      toast.warn("You can only add up to 6 images", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      imageArray.push(file);
    }
    setImages([...images, ...imageArray]);
  };


  const handleFileChangeDocument = (event) => {
    const files = event.target.files;
    const documentArray = [];

    if (documents.length + files.length + showPdf?.length > 3) {
      toast.warn("You can only add up to 3 documents", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      documentArray.push(file);
    }
    setDocuments([...documents, ...documentArray]);
  };

  const handleRemoveDocument = (idx) => {
    const val = documents.filter((item, index) => index !== idx)
    setDocuments(val)
  }


  const submitData = async () => {
    if (!validateForm()) {
      return;
    }
    const FData = {
      banner_images: selectedBannerFiles,
      logo: formData?.logo,
      video_links: multipleVideoLink,
      images: images,
      website_link: formData?.website_link,
      documents: documents,
      facebook:formData?.facebook,
      instagram:formData?.instagram,
      twitter:formData?.twitter,
      linkedin:formData?.linkedin,
      youtube:formData?.youtube
    };
    try {
      setSubmitBtnLoading(true);
      await Express_Event_MediaHub(Eid,FData).then((result) => {
        if (result?.data?.success) {
          Messages(result?.data?.message);
        }
        navigate(`/expresseventpreview/${Eid}`)
        //setTabValue("Social-Hub")
      });
    } catch (error) {
      console.log(error);
    }
    finally {
      setSubmitBtnLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};

    // if (multipleVideoLink.length === 0) {
    //   errors.multipleVideoLink = "Please provide at least one video link.";
    // } 

    // if (!formData.banner) {
    //   errors.banner = "Banner is required";
    // } else if (formData?.banner?.size > 1 * 1024 * 1024) {
    //   errors.banner = "Banner size exceeds the maximum allowed size (2MB).";
    // }

    if (selectedBannerFiles?.length == 0 && predefine_Banner?.length == 0) {
      errors.banner = "Please add at least one banner.";
    }

    if (!formData?.logo) {
      errors.logo = "Logo is required";
    } else if (formData?.logo?.size > 1 * 1024 * 1024) {
      errors.logo = "Logo size exceeds the maximum allowed size (2MB).";
    }

    const urlRegex = /^https?|ftp:\/\/[^ "]+\/?$/;

    const trimmedWebsiteUrl = formData?.website_link?.trim();
    if (formData?.website_link !== "" && formData?.website_link !== null) {
      if (!urlRegex.test(trimmedWebsiteUrl)) {
        errors.website_link = "Invalid Website URL";
       }
    }

    const trimmedFacebookUrl = formData?.facebook?.trim();
    if (formData?.facebook !== "" && formData?.facebook !== null) {
      if (!urlRegex.test(trimmedFacebookUrl)) {
        errors.facebook = "Invalid Facebook URL";
       }
    }

    const trimmedtwitterUrl = formData?.twitter?.trim();
    if (formData?.twitter !== "" && formData?.twitter !== null) {
      if (!urlRegex.test(trimmedtwitterUrl)) {
        errors.twitter = "Invalid Twitter URL";
       }
    }

    const trimmedlinkedinUrl = formData?.linkedin?.trim();
    if (formData?.linkedin !== "" && formData?.linkedin !== null) {
      if (!urlRegex.test(trimmedlinkedinUrl)) {
        errors.linkedin = "Invalid LinkedIn URL";
       }
    }

    const trimmedinstagraminUrl = formData?.instagram?.trim();
    if (formData?.instagram !== "" && formData?.instagram !== null) {
      if (!urlRegex.test(trimmedinstagraminUrl)) {
        errors.instagram = "Invalid Instagram URL";
       }
    }

    const trimmedyoutubeinUrl = formData?.youtube?.trim();
    if (formData?.youtube !== "" && formData?.youtube !== null) {
      if (!urlRegex.test(trimmedyoutubeinUrl)) {
        errors.youtube = "Invalid YouTube URL";
       }
    }

    // if (images.length === 0) {
    //   errors.imagess = "Please add at least one image.";
    // }

    // if (documents.length === 0) {
    //   errors.documentss = "Please add at least one document.";
    // }

    setErrors(errors);
    return Object.keys(errors)?.length === 0;
  };


  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // console.log("selectedBannerFiles",selectedBannerFiles.length)
  // useEffect(() => {
  //   const firstErrorKey = Object.keys(errors)[0];
  //   if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey].current) {
  //     refs[firstErrorKey].current.focus();
  //   }
  // }, [errors]);

  return (<>
    {selectChampMdl && (
      <SelectChampModal setSelectChampMdl={setSelectChampMdl} />
    )}

    <div className="basicInfo ">
      <div className="d-flex align-items-center gap-1 position-relative">
      <p className="createevent-head-p">This section allows you to upload and manage various media and documents related to your event to enhance your event's
       presentation and provide sponsors with essential information.</p>
        {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Event Media Hub</h2>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <IoMdInformationCircleOutline size={23} />
        </div>
        {isHovered && (
          <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <p className="info-popup-text">This section allows you to upload and manage various media and documents related to your event to enhance your event's
              presentation and provide sponsors with essential information.</p>
          </div>
        )} */}
      </div>
    
    <BannerLogoSection
      baseUrls={baseUrls} 
      formData={formData} 
      setFormData={setFormData}
      selectedBannerFiles={selectedBannerFiles} 
      setSelectedBannerFiles={setSelectedBannerFiles}
      errors={errors}
      Messages={Messages}
      refs={refs}
      EventmediaData={EventmediaData}
      predefine_Banner={predefine_Banner}
      handleInputChange={handleInputChange}
    />

    {/* img doc */}
    <EventMediaHubImageDoc
      handleFileChangeDocument={handleFileChangeDocument}
      handleFileChangeImage={handleFileChangeImage}
      images={images}
      setImages={setImages}
      showImages={showImages}
      EventmediaData={EventmediaData}
      Messages={Messages}
      baseUrls={baseUrls}
      documents={documents}
      setDocuments={setDocuments}
      showPdf={showPdf}
    />

      <div className="mt-3">
        <h4 className="form-heading">Videos <IoMdInformationCircleOutline size={16} color="black" title="Upload any videos related to the event" /></h4>
        <div className="row">
          <div className="col-md-8">
            <label className="form-label ">Video URL</label>
            <div>
              <div className="d-flex form-group ">
                <div className="col-8">
                  <input
                    type="url"
                    value={video_link}
                    onChange={(e) => setVideo_link(e.target.value)}
                    className="form-control"
                    placeholder="Enter Video URL"
                  />
                </div>
                <div>
                  <button className="saveandcontinue-btn ms-4" onClick={handleAddMultipleURL}>Add Link</button>
                </div>
              </div>
              <div className="col-md-10">
                {multipleVideoLink?.map((item, idxx) => {
                  return (
                    <div className="create-msg-date-time-bar d-flex align-items-center">
                      <span className="create-Message">{item}</span>
                      <div className="">
                        <button className="create-Date-Time" onClick={() => handleDeleteURL(idxx)}>Remove</button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {/* {errors.multipleVideoLink && (
                <span className="error">{errors.multipleVideoLink}</span>
              )} */}
          </div>
          {/* <YouTube videoId="wZeHdXlJx4M?si=SxSV9l17ABlJvX1d" opts={opts} /> */}
        </div>
      </div>

      <div className="row mt-3">
        <h5 className="form-section-heading">Event Website <IoMdInformationCircleOutline size={16} color="black" title="Enter your event’s official website for more detailed information" /></h5> 
        <div className="form-group col-md-6">
          <label className="form-label mb-0">Website URL</label>
          <div className="">
            <input
              type="text"
              value={formData.website_link}
              name="website_link"
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Website URL"
            />
          </div>
          {errors?.website_link && (
            <span className="error">{errors?.website_link}</span>
          )}
        </div>

        <div className="form-group col-md-6">
            <label className="form-label">Facebook</label>
              {/* <IoMdInformationCircleOutline size={16} color="black" title="Enter the URL of your event's Facebook page for attendees to like and follow" /> */}
              <input
                  type="text"
                  value={formData.facebook}
                  name="facebook"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Facebook Url"
               />
                {errors.facebook && (
                  <span className="error">{errors.facebook}</span>
              )}
          </div>
      </div>

       <div className="row">
             <div className="form-group col-md-6">
                <label className="form-label">X (Twitter)</label>
                {/* <IoMdInformationCircleOutline size={16} color="black" title="Provide your event's X (Twitter) handle so attendees can follow and tweet about your event" /> */}
                <input
                  type="text"
                  value={formData.twitter}
                  name="twitter"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Twitter Url"
                />
                {errors.twitter && (
                  <span className="error">{errors.twitter}</span>
                )}
              </div>

              <div
                className="form-group col-md-6">
                <label className="form-label">LinkedIn</label>
                {/* <IoMdInformationCircleOutline size={16} color="black" title="Link the LinkedIn page of your event for professional networking and updates" /> */}

                <input
                  type="text"
                  value={formData.linkedin}
                  name="linkedin"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter LinkedIn Url"
                />
                {errors.linkedin && (
                  <span className="error">{errors.linkedin}</span>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label">Instagram</label>
                {/* <IoMdInformationCircleOutline size={16} color="black" title="Add the Instagram profile link for your event to share photos and stories" /> */}
                <input
                  type="text"
                  value={formData.instagram}
                  name="instagram"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Instagram Url"
                />
                {errors.instagram && (
                  <span className="error">{errors.instagram}</span>
                )}
              </div>

              <div className="form-group col-md-6">
              <label className="form-label">YouTube Channel</label>
              {/* <IoMdInformationCircleOutline size={16} color="black" title="Add the Youtube channel link for your event to reels and photos" /> */}

              <input
                type="text"
                value={formData.youtube}
                name="youtube"
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter YouTube Channel Url"
              />
              {errors.youtube && <span className="error">{errors.youtube}</span>}
            </div>
            </div>

         <div className="mt-3">
          <h5 className="form-section-heading">Host</h5>
          <div className="row">
           <div className="col-md-5 ">
            <div className="position-relative event-profile-img">
              {/* <label className="form-label">Logo<span className="asterisk">*</span></label> */}
              <img
                className="event-profile-img"
                src={profileinfo?.image_type == "browse" ?
                  `${baseUrls?.customer_image_url}/${profileinfo?.image}` :
                  `${baseUrls?.avatar_image_url}/${profileinfo?.predefine_image}`}
                alt="profile"
              />
            </div>
          </div>
          <div className="col-md-7 ">
            <div className="position-relative event-profile-img">
              {
                profileinfo?.website_link &&
                <div>
                  <label className="form-label">Website<span className="asterisk">*</span></label>
                  <Link to={profileinfo?.website_link}>{profileinfo?.website_link}</Link>
                </div>
              }
          </div>
        </div>
        </div>
      </div>


      <div className="d-flex justify-content-end align-items-center mt-4">
        {/* <button className="btn delete-event-btn">Delete Event</button> */}
        <div className="d-flex gap-2">
          {/* {!profileinfo?.user_type &&
              <button className="d-flex align-items-center gap-1 btn saveandcontinue-btn" 
                onClick={()=>setSelectChampMdl(!selectChampMdl)} type="button">Change Champ</button>} */}
          <button className="saveandcontinue-btn d-flex align-items-center gap-2" onClick={submitData}>
            {submitBtnLoading && (
              <div className="spinner-border text-light " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            Save and Continue
            <img className="arrow-img" src={arrow} alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  </>
  );
};

export default ExpressEventMediaHub;
