
import moment from "moment";
import {useState,useEffect} from "react";

const EventDateTimeTabs=({val})=>{
  const [activeTab, setActiveTab] = useState('');

  useEffect(()=>{
      const activeTabValue = val[0]?.date;
      setActiveTab(activeTabValue)
  },[val]);

  const groupByDate = (events_timing) => {
    return events_timing?.reduce((acc, event) => {
      const date = event?.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date]?.push(event);
      return acc;
    }, {});
  };

  const groupedEvents = groupByDate(val);
  //console.log("groupedEvents",val ? "true" : "false")
   
   return(<>
     <div className="schedule-main-div mt-3">
      <div className="row">
        <div className="col-3">
          <div className="schedule-tabs">
            {Object.keys(groupedEvents)?.map(date => (
              <div
                key={date}
                className={`schedule-tab ${activeTab == date ? 'active' : ''}`}
                onClick={() => setActiveTab(date)}>
                {moment(date).format('ddd, DD MMM YYYY')}
              </div>
            ))}
          </div>
        </div>
        
        <div className="col-9">
          <div className="tab-content">
            {Object.keys(groupedEvents)?.map(date => (
              <div key={date}
                className={`tab-panel ${activeTab == date ? 'active' : ''}`}>
                {activeTab == date && (
                  groupedEvents[date]?.map(event => (
                    <div key={event.id} className="tab-value-section">
                     <div>
                      {event.start_time && (
                        <div className="time-div">
                          {moment(event?.start_time, 'HH:mm:ss').format('hh:mm A')} - {moment(event.end_time, 'HH:mm:ss').format('hh:mm A')}
                        </div>
                      )}
                     </div>
                     <div>
                        {event?.title && <div className="details text-capitalize">{event?.title}</div>}
                        {event?.description && <div className="description" dangerouslySetInnerHTML={{ __html: event?.description }}></div>}
                     </div>
                    </div>
                  ))
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    {/* <div className="schedule-main-div mt-3">   
     <div className="row">
       <div className="col-3">
      <div className="schedule-tabs">
        {val?.map(tab => (
          <div
            key={tab?.id}
            className={`schedule-tab ${activeTab == tab?.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab?.id)}>
            { moment(tab?.date).format('DD MMM YYYY')}
          </div>
        ))}
      </div>
      </div>
      
      <div className="col-9">
      <div className="tab-content">
        {val?.map(tab => (
          <div
            key={tab.id}
            className={`tab-panel ${activeTab == tab?.id ? 'active' : ''}`}>
            {activeTab == tab?.id && (
              <div className="d-flex align-items-center gap-3">
                {tab?.start_time && <div className="time-div">{moment(tab?.start_time, 'HH:mm:ss').format('hh:mm A')} {moment(tab?.end_time, 'HH:mm:ss').format('hh:mm A')}</div>}
                <div>
                 {tab?.title && <div className="details">{tab?.title}</div>}
                 {tab?.description && <div className="description" dangerouslySetInnerHTML={{ __html:tab?.description}}></div>}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      </div>
      </div>
    </div>    */}
   </>)
};

export default EventDateTimeTabs;