import React, { useState, useEffect } from "react";
import arrow from "../../assets/images/arrow2.svg";
import {
  Confirmed_Sponsor_Dlt,
  Confirmed_Sponsor_Update,
  CreateEventPitch,
  GetSingleConfirmedSponsor,
} from "../../utils/CreateEvents/createEvent";
import { Get_Edit_Event_Pitch } from "../../utils/EditEvent/editEvent";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";

const EditEventPitch = ({ setTabValue, id, Messages, baseUrls, EditData }) => {
  const [openSponsorCharacteristics, setOpenSponsorCharacteristics] = useState("0");
  const [sponsorshipPreferences, setSponsorshipPreferences] = useState("0");
  const [promotionalPlan, setPromotionalPlan] = useState("0");
  const [confirmedSponsors, setConfirmedSponsors] = useState("0");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [confirmedSponsorId, setConfirmedSponsorId] = useState(null);
  const [updateCheck, setUpdateCheck] = useState(false);
  const [normalEdit, setNormalEdit] = useState(false);
  const [editSponsorsList, setEditSponsorsList] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const [input, setInput] = useState({
    why_partner_with_us: "",
    event_theme: "",
    preferred_categories: "",
    before_brand_visibility: "",
    during_brand_visibility: "",
    after_brand_visibility: "",
    engagement_opportunities: "",
    desired_sponsor_characteristics: "",
    sponsorship_preferences: "",
    promotional_plan: "",
    media_exposure: "",
    social_media_campaigns: "",
    // confirmed_sponsors: '',
    // sponsors_brand_logo: '',
    any_sponsor_characteristics: "",
    any_confirmed_sponsors: "",
    any_promotional_plan: "",
    any_sponsorship_preferences: "",
  });
  const [sponsors, setSponsors] = useState([]);
  const [sponsorData, setSponsorData] = useState({
    sponsor_name: "",
    sponsors_brand_logo: null,
    sponsor_details: "",
  });

  const [sponsorError, setSponsorError] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    setInput({
      ...input,
      any_sponsor_characteristics: openSponsorCharacteristics,
      any_confirmed_sponsors: confirmedSponsors,
      any_promotional_plan: promotionalPlan,
      any_sponsorship_preferences: sponsorshipPreferences,
    });
  }, [
    openSponsorCharacteristics,
    sponsorshipPreferences,
    promotionalPlan,
    confirmedSponsors,
  ]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getData = async () => {
    try {
      await Get_Edit_Event_Pitch(id).then((response) => {
        const data = response?.data?.data;
        setInput(data);
        setOpenSponsorCharacteristics(data?.any_sponsor_characteristics);
        setConfirmedSponsors(data?.any_confirmed_sponsors);
        setSponsorshipPreferences(data?.any_sponsorship_preferences);
        setPromotionalPlan(data?.any_promotional_plan);
        setEditSponsorsList(data?.new_sponsor);
      });
    } catch (errr) {
      console.log(errr);
    }
  };
  useEffect(() => {
    getData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "sponsors_brand_logo") {
      setInput({
        ...input,
        [name]: files[0],
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const fdata = {
        ...input,
        new_sponsor: [...editSponsorsList, ...sponsors],
      };
      if (confirmedSponsors == "1") {
        if (editSponsorsList?.length > 0 || sponsors?.length > 0) {
          setSaveBtnLoading(true)
          try {
            await CreateEventPitch(fdata, id).then((results) => {
              if (results?.data?.success) {
                setTabValue("Event-Host");
                Messages(results?.data?.message);
              }
            });
          } catch (error) {
            console.log(error);
          } finally {
            setSaveBtnLoading(false)
          }
        } else {
          toast.warn("Sponsor is required", {
            position: "top-center",
          });
        }
      } else {
        setSaveBtnLoading(true)
        try {
          await CreateEventPitch(fdata, id).then((results) => {
            if (results?.data?.success) {
              setTabValue("Event-Host");
              Messages(results?.data?.message);
            }
          });
        } catch (error) {
          console.log(error);
        } finally {
          setSaveBtnLoading(false)
        }
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const preferred = input.preferred_categories?.replace(/<p><br><\/p>/g, "");
    if (preferred?.trim() == "") {
      newErrors.preferred_categories = "Preferred Categories is required";
      valid = false;
    }

    const before = input.before_brand_visibility?.replace(/<p><br><\/p>/g, "");
    if (before?.trim() == "") {
      newErrors.before_brand_visibility = "Before The Event is required";
      valid = false;
    }

    const during = input.during_brand_visibility?.replace(/<p><br><\/p>/g, "");
    if (during?.trim() == "") {
      newErrors.during_brand_visibility = "During The Event is required";
      valid = false;
    }

    const after = input.after_brand_visibility?.replace(/<p><br><\/p>/g, "");
    if (after?.trim() == "") {
      newErrors.after_brand_visibility = "After The Event is required";
      valid = false;
    }

    const enge_opp = input.engagement_opportunities?.replace(
      /<p><br><\/p>/g,
      ""
    );
    if (enge_opp?.trim() == "") {
      newErrors.engagement_opportunities =
        "Engagement Opportunities is required";
      valid = false;
    }

    if (openSponsorCharacteristics == "1") {
      const desired = input.desired_sponsor_characteristics?.replace(
        /<p><br><\/p>/g,
        ""
      );
      if (desired?.trim() == "") {
        newErrors.desired_sponsor_characteristics =
          "Desired Sponsor Characteristics is required";
        valid = false;
      }
    }
    if (sponsorshipPreferences == "1") {
      const preferences = input.sponsorship_preferences?.replace(
        /<p><br><\/p>/g,
        ""
      );
      if (preferences?.trim() == "") {
        newErrors.sponsorship_preferences =
          "Sponsorship Preferences is required";
        valid = false;
      }
    }
    if (promotionalPlan == "1") {
      const exposure = input.media_exposure?.replace(/<p><br><\/p>/g, "");
      if (exposure?.trim() == "") {
        newErrors.media_exposure = "Media Exposure is required";
        valid = false;
      }
      const socialMedia = input.social_media_campaigns?.replace(
        /<p><br><\/p>/g,
        ""
      );
      if (socialMedia?.trim() == "") {
        newErrors.social_media_campaigns = "Social Media Campaigns is required";
        valid = false;
      }
    }

    if (confirmedSponsors == "1") {
    }

    setError(newErrors);
    return valid;
  };

  // reactQuill function -------------
  const handleWhyPartnerWithUs = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text?.length <= 2500) {
      setInput((prevState) => ({
        ...prevState,
        why_partner_with_us: value,
      }));
      setError({
        ...error,
        why_partner_with_us: "",
      });
    } else {
      setError({
        ...error,
        why_partner_with_us: "Why Partner With Us cannot exceed 2500 characters.",
      });
    }
  };

  const handleEventTheme = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        event_theme: value,
      }));
      setError({
        ...error,
        event_theme: "",
      });
    } else {
      setError({
        ...error,
        event_theme: "Event Theme cannot exceed 500 characters.",
      });
    }
  };

  const handlePreferredCategories = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        preferred_categories: value,
      }));
      setError({
        ...error,
        preferred_categories: "",
      });
    } else {
      setError({
        ...error,
        preferred_categories:
          "Preferred Categories cannot exceed 500 characters.",
      });
    }
  };

  const handleBeforeVisibility = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        before_brand_visibility: value,
      }));
      setError({
        ...error,
        before_brand_visibility: "",
      });
    } else {
      setError({
        ...error,
        before_brand_visibility:
          "Before Brand Visibility cannot exceed 500 characters.",
      });
    }
  };

  const handleDuringVisibility = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        during_brand_visibility: value,
      }));
      setError({
        ...error,
        during_brand_visibility: "",
      });
    } else {
      setError({
        ...error,
        during_brand_visibility:
          "During Brand Visibility cannot exceed 500 characters.",
      });
    }
  };

  const handleAfterVisibility = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        after_brand_visibility: value,
      }));
      setError({
        ...error,
        after_brand_visibility: "",
      });
    } else {
      setError({
        ...error,
        after_brand_visibility:
          "After Brand Visibility cannot exceed 500 characters.",
      });
    }
  };

  const handleEngagementOpportunities = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        engagement_opportunities: value,
      }));
      setError({
        ...error,
        engagement_opportunities: "",
      });
    } else {
      setError({
        ...error,
        engagement_opportunities:
          "Engagement Opportunities cannot exceed 500 characters.",
      });
    }
  };

  const handleDesiredCharacteristics = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        desired_sponsor_characteristics: value,
      }));
      setError({
        ...error,
        desired_sponsor_characteristics: "",
      });
    } else {
      setError({
        ...error,
        desired_sponsor_characteristics:
          "Desired Sponsor Characteristics cannot exceed 500 characters.",
      });
    }
  };

  const handleSponsorshipPreferences = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        sponsorship_preferences: value,
      }));
      setError({
        ...error,
        sponsorship_preferences: "",
      });
    } else {
      setError({
        ...error,
        sponsorship_preferences:
          "Sponsorship Preferences cannot exceed 500 characters.",
      });
    }
  };

  const handlePromotionalPlan = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        promotional_plan: value,
      }));
      setError({
        ...error,
        promotional_plan: "",
      });
    } else {
      setError({
        ...error,
        promotional_plan: "Promotional Plan cannot exceed 500 characters.",
      });
    }
  };

  const handleMediaExposure = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        media_exposure: value,
      }));
      setError({
        ...error,
        media_exposure: "",
      });
    } else {
      setError({
        ...error,
        media_exposure:
          "Media Exposure Characteristics cannot exceed 500 characters.",
      });
    }
  };

  const handleSocialMediaCampaigns = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        social_media_campaigns: value,
      }));
      setError({
        ...error,
        social_media_campaigns: "",
      });
    } else {
      setError({
        ...error,
        social_media_campaigns:
          "Social Media Campaigns Characteristics cannot exceed 500 characters.",
      });
    }
  };

  const handleSponsorDetails = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setSponsorData((prevState) => ({
        ...prevState,
        sponsor_details: value,
      }));
      setSponsorError({
        ...sponsorError,
        sponsor_details: "",
      });
    } else {
      setSponsorError({
        ...sponsorError,
        sponsor_details: "Sponsor Details cannot exceed 500 characters.",
      });
    }
  };

  const handleSponsorChange = (e) => {
    const { name, value, files } = e.target;
    if (name == "sponsors_brand_logo") {
      setSponsorData({
        ...sponsorData,
        [name]: files[0],
      });
    } else {
      setSponsorData({
        ...sponsorData,
        [name]: value,
      });
    }
  };

  const sponsorValidateForm = () => {
    let valid = true;
    const newErrors = {};
    if (sponsorData?.sponsor_name?.trim() == "") {
      newErrors.sponsor_name = "Confirmed Sponsors is required";
      valid = false;
    }
    if (!sponsorData?.sponsors_brand_logo) {
      newErrors.sponsors_brand_logo = "Sponsor Brand logo is required";
      valid = false;
    }

    const sponsorDetail = sponsorData?.sponsor_details?.replace(
      /<p><br><\/p>/g,
      ""
    );
    if (sponsorDetail?.trim() == "") {
      newErrors.sponsor_details = "Sponsor Details is required";
      valid = false;
    }

    setSponsorError(newErrors);
    return valid;
  };

  const handleAddSponsor = (e) => {
    e.preventDefault();
    if (sponsorValidateForm()) {
      const newSponsor = {
        sponsor_name: sponsorData?.sponsor_name,
        sponsors_brand_logo: sponsorData?.sponsors_brand_logo,
        sponsor_details: sponsorData?.sponsor_details,
      };

      if (updateCheck) {
        try {
          Confirmed_Sponsor_Update(confirmedSponsorId, sponsorData).then(
            (response) => {
              console.log(response?.data);
              if (response?.data?.success) {
                Messages(response?.data?.message);
                setUpdateCheck(false);
                getData();
                setSponsorData({
                  sponsor_name: "",
                  sponsors_brand_logo: null,
                  sponsor_details: "",
                });
              }
            }
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        if (normalEdit) {
          setSponsors((prevSponsors) =>
            prevSponsors.map((sponsor, index) =>
              index === confirmedSponsorId ? newSponsor : sponsor
            )
          );
          setSponsorData({
            sponsor_name: "",
            sponsors_brand_logo: null,
            sponsor_details: "",
          });
          Messages("Sponsor update successfully");
          setNormalEdit(false);
        } else {
          setSponsors([...sponsors, newSponsor]);
          setSponsorData({
            sponsor_name: "",
            sponsors_brand_logo: null,
            sponsor_details: "",
          });
        }
      }
      // fileInputRef.current.value = null;
    }
  };

  const handleNormalEdit = (sponsor, id) => {
    setSponsorData(sponsor);
    setConfirmedSponsorId(id);
    setNormalEdit(true);
  };

  const handleDeleteSponsor = (indexToDelete) => {
    setSponsors((prevSponsors) =>
      prevSponsors.filter((_, index) => index !== indexToDelete)
    );
  };

  const EditConfirmSponsor = async (id) => {
    setUpdateCheck(true);
    setConfirmedSponsorId(id);

    try {
      GetSingleConfirmedSponsor(id).then((response) => {
        setSponsorData(response?.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const DltConfirmSponsor = async (id) => {
    try {
      await Confirmed_Sponsor_Dlt(id).then((response) => {
        if (response?.data?.success) {
          Messages(response?.data?.message);
          getData();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    let textWithNewlines = div.innerHTML

    if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
      textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
    } else {
      // Otherwise, apply replacements
      textWithNewlines = textWithNewlines
        .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
    }

    div.innerHTML = textWithNewlines;
    return div.textContent || div.innerText || "";
  };

  // React Quill length count-------------------start-----------------------//
  const maxThemeLength = 500;
  const maxWhyPartnerWithUsLength = 2500;

  // React theme quill length count
  const getWhyPartnerWithUsLength = () => {
    const cleanDescription = stripHtmlTags(input?.why_partner_with_us);
    return cleanDescription.length;
  };

  const currentWhyPartnerWithUsLength = getWhyPartnerWithUsLength();
  const remainingWhyPartnerWithUsLength = maxWhyPartnerWithUsLength - currentWhyPartnerWithUsLength;

  const getThemeLength = () => {
    const cleanDescription = stripHtmlTags(input?.event_theme);
    return cleanDescription.length;
  };
  const currentThemeLength = getThemeLength();
  const remainingThemeLength = maxThemeLength - currentThemeLength;

  // React preferred quill length count
  const getPreferredLength = () => {
    const cleanDescription = stripHtmlTags(input.preferred_categories);
    return cleanDescription.length;
  };
  const currentPreferredLength = getPreferredLength();
  const remainingPreferredLength = maxThemeLength - currentPreferredLength;

  // React before quill length count
  const getBeforeLength = () => {
    const cleanDescription = stripHtmlTags(input.before_brand_visibility);
    return cleanDescription.length;
  };
  const currentBeforeLength = getBeforeLength();
  const remainingBeforeLength = maxThemeLength - currentBeforeLength;

  // React during quill length count
  const getDuringLength = () => {
    const cleanDescription = stripHtmlTags(input.during_brand_visibility);
    return cleanDescription.length;
  };
  const currentDuringLength = getDuringLength();
  const remainingDuringLength = maxThemeLength - currentDuringLength;

  // React after quill length count
  const getAfterLength = () => {
    const cleanDescription = stripHtmlTags(input.after_brand_visibility);
    return cleanDescription.length;
  };
  const currentAfterLength = getAfterLength();
  const remainingAfterLength = maxThemeLength - currentAfterLength;

  // React Engment quill length count
  const getEngagementLength = () => {
    const cleanDescription = stripHtmlTags(input.engagement_opportunities);
    return cleanDescription.length;
  };
  const currentengagementLength = getEngagementLength();
  const remainingengagementLength = maxThemeLength - currentengagementLength;

  // React Desired quill length count
  const getDesiredLength = () => {
    const cleanDescription = stripHtmlTags(
      input.desired_sponsor_characteristics
    );
    return cleanDescription.length;
  };
  const currentdesiredLength = getDesiredLength();
  const remainingdesiredLength = maxThemeLength - currentdesiredLength;

  // React prefrences quill length count
  const getSponsorshipLength = () => {
    const cleanDescription = stripHtmlTags(input.sponsorship_preferences);
    return cleanDescription.length;
  };
  const currentSponsorshipLength = getSponsorshipLength();
  const remainingSponsorshipLength = maxThemeLength - currentSponsorshipLength;

  // React Promotional quill length count
  const getPromotionalLength = () => {
    const cleanDescription = stripHtmlTags(input.promotional_plan);
    return cleanDescription.length;
  };
  const currentPromotionalLength = getPromotionalLength();
  const remainingPromotionalLength = maxThemeLength - currentPromotionalLength;

  // React Media quill length count
  const getMediaLength = () => {
    const cleanDescription = stripHtmlTags(input.media_exposure);
    return cleanDescription.length;
  };
  const currentMediaLength = getMediaLength();
  const remainingMediaLength = maxThemeLength - currentMediaLength;

  // React Social media quill length count
  const getSocialLength = () => {
    const cleanDescription = stripHtmlTags(input.social_media_campaigns);
    return cleanDescription.length;
  };
  const currentSocialLength = getSocialLength();
  const remainingSocialLength = maxThemeLength - currentSocialLength;

  // React Sponsor details quill length count
  const getSponsorLength = () => {
    const cleanDescription = stripHtmlTags(sponsorData.sponsor_details);
    return cleanDescription.length;
  };
  const currentSponsorLength = getSponsorLength();
  const remainingSponsorLength = maxThemeLength - currentSponsorLength;

  // React Quill length count-------------------end-----------------------//

  return (
    <>
      <div className="basicInfo">
        {/* <h2 className="basicinfo-head">Event Pitch</h2> */}
        <div className="d-flex align-items-center gap-1 position-relative">
          <p className="createevent-head-p">
            This section is designed to articulate the unique value and
            opportunities your event offers to potential sponsors. It should
            present a persuasive argument to potential sponsors about why
            they should support your event.
          </p>
          {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">
            Event Pitch
          </h2>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <IoMdInformationCircleOutline size={23} />
          </div>
          {isHovered && (
            <div
              className="info-popup"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <p className="info-popup-text">
                This section is designed to articulate the unique value and
                opportunities your event offers to potential sponsors. It should
                present a persuasive argument to potential sponsors about why
                they should support your event.
              </p>
            </div>
          )} */}
        </div>
        <form onSubmit={onSubmit}>
          <div className="row">
            {EditData?.description && (
              <div className="col-md-12">
                <label className="form-label mb-0">Event Description</label>
                {/* <IoMdInformationCircleOutline
                  size={16}
                  title="Provide a brief overview describing the purpose and what the event is all about and its main attractions."
                /> */}
                <p className="reactquill-tooltip-p mb-1">(Note: Provide a brief overview describing the purpose and what the event is all about and its main attractions.)</p>
               {/* <div style={{ height: "180px" }}> */}
                <ReactQuill
                 value={EditData?.description}
                 //onChange={""}
                 readOnly={true}
                 modules={{toolbar: false}}
                 disabled
                 theme="snow"
                 style={{ height: "130px" }}
                 placeholder="Event Description"
               />
              {/* </div> */}
                {/* <textarea
                  value={stripHtmlTags(EditData?.description)}
                  type="text"
                  name="event_description"
                  rows="5"
                  disabled
                  className="form-control"
                  placeholder="Event Description"
                /> */}
              </div>
            )}
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <label className="form-label mb-1">
                  Why Partner With Us?<span className="asterisk"></span>
                  {/* <IoMdInformationCircleOutline size={16} color="black" title="Explain the theme and any special focus areas of the event." /> */}
                </label>
                <p className="Character-0500">
                  Character:
                  <span className="text-style-1">
                    {/* remainingWhyPartnerWithUsLength */}
                    {currentWhyPartnerWithUsLength}/{maxWhyPartnerWithUsLength}
                  </span>
                </p>
              </div>
              <p className="reactquill-tooltip-p mb-1">(Note: Enter the how sponsor would benefit from partnering with your event.)</p>
              <div style={{ height: "190px" }}>
                <ReactQuill
                  value={input?.why_partner_with_us}
                  onChange={handleWhyPartnerWithUs}
                  theme="snow"
                  style={{ height: "130px" }}
                  placeholder="Enter Why Partner With Us?"
                />
              </div>
              {error.why_partner_with_us && (
                <span className="error">{error.why_partner_with_us}</span>
              )}
            </div>
          </div>

          <div className="row mt-3 mt-sm-0">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <label className="form-label mb-0">
                  Event Theme<span className="asterisk"></span>
                  {/* <IoMdInformationCircleOutline
                    size={16}
                    color="black"
                    title="Explain the theme and any special focus areas of the event."
                  /> */}
                </label>
                <p className="Character-0500">
                  Character:
                  <span className="text-style-1">
                    {/* remainingThemeLength */}
                    {currentThemeLength}/{maxThemeLength}
                  </span>
                </p>
              </div>
              <p className="reactquill-tooltip-p mb-1">(Note: Explain the theme and any special focus areas of the event.)</p>
              <div style={{ height: "190px" }}>
                <ReactQuill
                  value={input?.event_theme}
                  onChange={handleEventTheme}
                  theme="snow"
                  style={{ height: "130px" }}
                  placeholder="Enter Event Theme"
                />
              </div>
              {error.event_theme && (
                <span className="error">{error.event_theme}</span>
              )}
            </div>
          </div>

          <div className="row mt-3 mt-sm-0">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <label className="form-label mb-0">
                  Preferred Categories<span className="asterisk">*</span>
                  {/* <IoMdInformationCircleOutline
                    size={16}
                    color="black"
                    title="List the brands or product / service categories which align best with your"
                  /> */}
                </label>
                <p className="Character-0500">
                  Character:
                  <span className="text-style-1">
                    {/* remainingPreferredLength */}
                    {currentPreferredLength}/{maxThemeLength}
                  </span>
                </p>
              </div>
              <p className="reactquill-tooltip-p mb-1">(Note: List the brands or product / service categories which align best with your)</p>
              <div style={{ height: "190px" }}>
                <ReactQuill
                  value={input?.preferred_categories}
                  onChange={handlePreferredCategories}
                  theme="snow"
                  style={{ height: "130px" }}
                  placeholder="Enter Preferred Categories"
                />
              </div>
              {error.preferred_categories && (
                <span className="error">{error.preferred_categories}</span>
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <h5 className="form-heading">Sponsorship Benefits</h5>
              <label className="mb-0">
                Brand Visibility
                {/* <IoMdInformationCircleOutline size={16} color="black" title="Detail the various opportunities for brand exposure" /> */}
                {/* <span className="asterisk"></span> */}
              </label>
              <p className="reactquill-tooltip-p mb-2">(Note: Detail the various opportunities for brand exposure)</p>
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-between">
              <label className="form-label">
                Before The Event<span className="asterisk">*</span>
                {/* <IoMdInformationCircleOutline
                  size={16}
                  color="black"
                  title="Detail the various opportunities for brand exposure"
                /> */}
              </label>
              <p className="Character-0500">
                Character:
                <span className="text-style-1">
                  {/* remainingBeforeLength */}
                  {currentBeforeLength}/{maxThemeLength}
                </span>
              </p>
            </div>

            <div className="col-md-12">
              <div style={{ height: "190px" }}>
                <ReactQuill
                  value={input?.before_brand_visibility}
                  onChange={handleBeforeVisibility}
                  theme="snow"
                  style={{ height: "130px" }}
                  placeholder="Enter Before The Event"
                />
              </div>
              {error.before_brand_visibility && (
                <span className="error">{error.before_brand_visibility}</span>
              )}
            </div>
          </div>
          <div className="row mt-3 mt-sm-0">
            <div className="d-flex justify-content-between">
              <label className="form-label">
                During The Event<span className="asterisk">*</span>
                {/* <IoMdInformationCircleOutline
                  size={16}
                  color="black"
                  title="Detail the various opportunities for brand exposure"
                /> */}
              </label>
              <p className="Character-0500">
                Character:
                <span className="text-style-1">
                  {/* remainingDuringLength */}
                  {currentDuringLength}/{maxThemeLength}
                </span>
              </p>
            </div>

            <div className="col-md-12">
              <div style={{ height: "190px" }}>
                <ReactQuill
                  value={input?.during_brand_visibility}
                  onChange={handleDuringVisibility}
                  theme="snow"
                  style={{ height: "130px" }}
                  placeholder="Enter During The Event"
                />
              </div>
              {error.during_brand_visibility && (
                <span className="error">{error.during_brand_visibility}</span>
              )}
            </div>
          </div>

          <div className="row mt-3 mt-sm-0">
            <div className="d-flex justify-content-between">
              <label className="form-label">
                After The Event<span className="asterisk">*</span>
                {/* <IoMdInformationCircleOutline
                  size={16}
                  color="black"
                  title="Detail the various opportunities for brand exposure"
                /> */}
              </label>
              <p className="Character-0500">
                Character:
                <span className="text-style-1">
                  {/* remainingAfterLength */}
                  {currentAfterLength}/{maxThemeLength}
                </span>
              </p>
            </div>
            <div className="col-md-12">
              <div style={{ height: "190px" }}>
                <ReactQuill
                  value={input?.after_brand_visibility}
                  onChange={handleAfterVisibility}
                  theme="snow"
                  style={{ height: "130px" }}
                  placeholder="Enter After The Event"
                />
              </div>
              {error.after_brand_visibility && (
                <span className="error">{error.after_brand_visibility}</span>
              )}
            </div>
          </div>

          <div className="row mt-3 mt-sm-0">
            <div className="d-flex justify-content-between">
              <label className="form-label mb-0">
                Engagement Opportunities<span className="asterisk">*</span>
                {/* <IoMdInformationCircleOutline
                  size={16}
                  color="black"
                  title="Describe how sponsors can engage with attendees like booths, presentations, or interactive sessions."
                /> */}
              </label>
              <p className="Character-0500">
                Character:
                <span className="text-style-1">
                  {/* remainingengagementLength */}
                  {currentengagementLength}/{maxThemeLength}
                </span>
              </p>
            </div>
            <p className="reactquill-tooltip-p mb-1">(Note: Describe how sponsors can engage with attendees like booths, presentations, or interactive sessions.)</p>
            <div className="col-md-12">
              <div style={{ height: "190px" }}>
                <ReactQuill
                  value={input?.engagement_opportunities}
                  onChange={handleEngagementOpportunities}
                  theme="snow"
                  style={{ height: "130px" }}
                  placeholder="Enter Engagement Opportunities"
                />
              </div>
              {error.engagement_opportunities && (
                <span className="error">{error.engagement_opportunities}</span>
              )}
            </div>
          </div>

          <div>
            <div className="row mt-3">
              <div className="d-flex align-items-center gap-3">
                <label className="mb-0">
                  Do you have any Sponsor Characteristics for your event?
                </label>
                <div className="d-flex gap-3">
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="1"
                        checked={openSponsorCharacteristics == 1}
                        name="openSponsorCharacteristics"
                        onChange={(e) =>
                          setOpenSponsorCharacteristics(e?.target?.value)
                        }
                      />
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="0"
                        checked={openSponsorCharacteristics == 0}
                        name="openSponsorCharacteristics"
                        onChange={(e) =>
                          setOpenSponsorCharacteristics(e?.target?.value)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
                {/* <button className="create-event-btn" onClick={()=>setOpenSponsorCharacteristics(!openSponsorCharacteristics)}>
                            {openSponsorCharacteristics ? "No" :"Yes"} </button> */}
              </div>
            </div>
            {openSponsorCharacteristics == 1 && (
              <div className="row mt-2">
                <div className="d-flex justify-content-between">
                  <label className="form-label mb-0">
                    Desired Sponsor Characteristics
                    <span className="asterisk">*</span>
                    {/* <IoMdInformationCircleOutline
                      size={16}
                      color="black"
                      title="Detail the qualities and values you seek in potential sponsors to ensure alignment with your event’s ethos."
                    /> */}
                  </label>
                  <p className="Character-0500">
                    Character:
                    <span className="text-style-1">
                      {/* remainingdesiredLength */}
                      {currentdesiredLength}/{maxThemeLength}
                    </span>
                  </p>
                </div>
                <p className="reactquill-tooltip-p mb-1">(Note: Detail the qualities and values you seek in potential sponsors to ensure alignment with your event’s ethos.)</p>
                <div className="col-md-12">
                  <div style={{ height: "190px" }}>
                    <ReactQuill
                      value={input?.desired_sponsor_characteristics}
                      onChange={handleDesiredCharacteristics}
                      theme="snow"
                      style={{ height: "130px" }}
                      placeholder="Enter Desired Sponsor Characteristics"
                    />
                  </div>
                  {error.desired_sponsor_characteristics && (
                    <span className="error">
                      {error.desired_sponsor_characteristics}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="row mt-3">
            <div className="d-flex align-items-center gap-3">
              <label className="mb-0">
                Do you have any Sponsorship Preferences for your event?{" "}
              </label>
              <div className="d-flex gap-3">
                <div className="form-check">
                  <label className="form-check-label m-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="1"
                      checked={sponsorshipPreferences == 1}
                      name="sponsorshipPreferences"
                      onChange={(e) =>
                        setSponsorshipPreferences(e?.target?.value)
                      }
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label m-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="0"
                      checked={sponsorshipPreferences == 0}
                      name="sponsorshipPreferences"
                      onChange={(e) =>
                        setSponsorshipPreferences(e?.target?.value)
                      }
                    />
                    No
                  </label>
                </div>
              </div>
              {/* <button className="create-event-btn" onClick={()=>setSponsorshipPreferences(!sponsorshipPreferences)}>
                          {sponsorshipPreferences ? "No" :"Yes"} </button> */}
            </div>
          </div>
          {sponsorshipPreferences == 1 && (
            <div className="row mt-2">
              <div className="d-flex justify-content-between">
                <label className="form-label mb-0">
                  Sponsorship Preferences<span className="asterisk">*</span>
                  {/* <IoMdInformationCircleOutline
                    size={16}
                    color="black"
                    title="Describe the specific conditions or interests specific to sponsors"
                  /> */}
                </label>
                <p className="Character-0500">
                  Character:
                  <span className="text-style-1">
                    {/* remainingSponsorshipLength */}
                    {currentSponsorshipLength}/{maxThemeLength}
                  </span>
                </p>
              </div>
              <p className="reactquill-tooltip-p mb-1">(Note: Describe the specific conditions or interests specific to sponsors)</p>
              <div className="col-md-12">
                <div style={{ height: "180px" }}>
                  <ReactQuill
                    value={input?.sponsorship_preferences}
                    onChange={handleSponsorshipPreferences}
                    theme="snow"
                    style={{ height: "130px" }}
                    placeholder="Enter Sponsorship Preferences"
                  />
                </div>
                {error.sponsorship_preferences && (
                  <span className="error">{error.sponsorship_preferences}</span>
                )}
              </div>
            </div>
          )}

          <div>
            <div className="row mt-3">
              <div className="d-flex align-items-center gap-3">
                <label className="mb-0">
                  Do you have any Promotional Plan for your event?
                </label>
                <div className="d-flex gap-3">
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="1"
                        checked={promotionalPlan == 1}
                        name="promotionalPlan"
                        onChange={(e) => setPromotionalPlan(e?.target?.value)}
                      />
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="0"
                        checked={promotionalPlan == 0}
                        name="promotionalPlan"
                        onChange={(e) => setPromotionalPlan(e?.target?.value)}
                      />
                      No
                    </label>
                  </div>
                </div>
                {/* <button className="create-event-btn" onClick={()=>setPromotionalPlan(!promotionalPlan)}>
                            {promotionalPlan ? "No" :"Yes"} </button> */}
              </div>
            </div>
            {promotionalPlan == 1 && (
              <div className="row mt-2">
            <label className="mb-0">
              Promotional Plan
             <IoMdInformationCircleOutline size={16} color="black" title="Outline your marketing plan and how sponsors will be featured in event promotions" />
            </label>
                {/* <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <label className="form-label mb-0">
                      Promotional Plan<span className="asterisk"></span>
                    </label>
                    <p className="Character-0500">
                      Character:
                      <span className="text-style-1">
                        {currentPromotionalLength}/{maxThemeLength}
                      </span>
                    </p>
                  </div>
                  <p className="reactquill-tooltip-p mb-1">(Note: Outline your marketing plan and how sponsors will be featured in event promotions)</p>
                  <div style={{ height: "190px" }}>
                    <ReactQuill
                      value={input?.promotional_plan}
                      onChange={handlePromotionalPlan}
                      theme="snow"
                      style={{ height: "130px" }}
                      placeholder="Enter Promotional Plan"
                    />
                  </div>
                  {error.promotional_plan && (
                    <span className="error">{error.promotional_plan}</span>
                  )}
                </div> */}

                <div className="col-md-12 mt-3 mt-sm-0">
                  <div className="d-flex justify-content-between">
                    <label className="form-label mb-0">
                      Media Exposure<span className="asterisk">*</span>
                      {/* <IoMdInformationCircleOutline
                        size={16}
                        color="black"
                        title="Highlight any media coverage, advertising, and promotional activities that will include sponsor mentions."
                      /> */}
                    </label>
                    <p className="Character-0500">
                      Character:
                      <span className="text-style-1">
                        {/* remainingMediaLength */}
                        {currentMediaLength}/{maxThemeLength}
                      </span>
                    </p>
                  </div>
                  <p className="reactquill-tooltip-p mb-1">(Note: Highlight any media coverage, advertising, and promotional activities that will include sponsor mentions.)</p>
                  <div style={{ height: "190px" }}>
                    <ReactQuill
                      value={input?.media_exposure}
                      onChange={handleMediaExposure}
                      theme="snow"
                      style={{ height: "130px" }}
                      placeholder="Enter Media Exposure"
                    />
                  </div>
                  {error.media_exposure && (
                    <span className="error">{error.media_exposure}</span>
                  )}
                </div>

                <div className="col-md-12 mt-3 mt-sm-0">
                  <div className="d-flex justify-content-between">
                    <label className="form-label mb-0">
                      Social Media Campaigns<span className="asterisk">*</span>
                      {/* <IoMdInformationCircleOutline
                        size={16}
                        color="black"
                        title="Explain the reach and content strategy on social media, emphasizing how sponsors will be integrated."
                      /> */}
                    </label>

                    <p className="Character-0500">
                      Character:
                      <span className="text-style-1">
                        {/* remainingSocialLength */}
                        {currentSocialLength}/{maxThemeLength}
                      </span>
                    </p>
                  </div>
                  <p className="reactquill-tooltip-p mb-1">(Note: Explain the reach and content strategy on social media, emphasizing how sponsors will be integrated.)</p>
                  <div style={{ height: "190px" }}>
                    <ReactQuill
                      value={input?.social_media_campaigns}
                      onChange={handleSocialMediaCampaigns}
                      theme="snow"
                      style={{ height: "130px" }}
                      placeholder="Enter Social Media Campaigns"
                    />
                  </div>
                  {error.social_media_campaigns && (
                    <span className="error">
                      {error.social_media_campaigns}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>

          <div>
            <div className="row mt-3">
              <div className="d-flex align-items-center gap-3">
                <label className="mb-0">
                  Do you have any confirmed sponsors?{" "}
                </label>
                <div className="d-flex gap-3">
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="1"
                        checked={confirmedSponsors == 1}
                        name="confirmedSponsors"
                        onChange={(e) => setConfirmedSponsors(e?.target?.value)}
                      />
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="0"
                        checked={confirmedSponsors == 0}
                        name="confirmedSponsors"
                        onChange={(e) => setConfirmedSponsors(e?.target?.value)}
                      />
                      No
                    </label>
                  </div>
                </div>
                {/* <button className="create-event-btn" onClick={()=>setConfirmedSponsors(!confirmedSponsors)}>
                            {confirmedSponsors ? "No" :"Yes"} </button> */}
              </div>
            </div>
            {confirmedSponsors == "1" && (
              <div>
                <div className="row align-items-end mt-2">
                  <h5 className="form-heading">
                    Confirmed Sponsors
                    <IoMdInformationCircleOutline
                      size={16}
                      color="black"
                      title="Provide details of any confirmed sponsors"
                    />
                  </h5>

                  <div className="col-md-6">
                    <label className="form-label">
                      Confirmed Sponsors<span className="asterisk">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={sponsorData?.sponsor_name}
                      name="sponsor_name"
                      onChange={handleSponsorChange}
                      placeholder="Enter Confirmed Sponsors"
                    />
                    {sponsorError?.sponsor_name && (
                      <span className="error">
                        {sponsorError?.sponsor_name}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Sponsor Brand logo<span className="asterisk">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      // ref={fileInputRef}
                      onChange={handleSponsorChange}
                      name="sponsors_brand_logo"
                      placeholder="Sponsor Brand logo"
                    />
                    {sponsorError?.sponsors_brand_logo && (
                      <span className="error">
                        {sponsorError?.sponsors_brand_logo}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6">
                    {sponsorData?.sponsors_brand_logo !== null && (
                      <div className="mt-3">
                        <div className="d-flex justify-content-end">
                          <button className="img-close-btn" type="button">
                            <IoClose
                              size={24}
                              onClick={() =>
                                setSponsorData({
                                  ...sponsorData,
                                  sponsors_brand_logo: null,
                                })
                              }
                            />
                          </button>
                        </div>

                        <div className="mt-2 d-flex justify-content-center">
                          {sponsorData?.sponsors_brand_logo instanceof File ? (
                            <img
                              className="event-logo-img"
                              src={URL.createObjectURL(
                                sponsorData?.sponsors_brand_logo
                              )}
                              alt="updateimg"
                            />
                          ) : (
                            <img
                              className="event-logo-img"
                              src={`${baseUrls?.sponsors_brand_logo}/${sponsorData?.sponsors_brand_logo}`}
                              alt="updateimg"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="d-flex justify-content-between">
                      <label className="form-label">
                        Sponsor Details<span className="asterisk">*</span>
                      </label>
                      <p className="Character-0500">
                        Character:
                        <span className="text-style-1">
                          {/* remainingSponsorLength */}
                          {currentSponsorLength}/{remainingSocialLength}
                        </span>
                      </p>
                    </div>

                    <div style={{ height: "190px" }}>
                      <ReactQuill
                        value={sponsorData?.sponsor_details}
                        onChange={handleSponsorDetails}
                        theme="snow"
                        style={{ height: "130px" }}
                        placeholder="Sponsor Details"
                      />
                    </div>
                    {sponsorError?.sponsor_details && (
                      <span className="error">
                        {sponsorError?.sponsor_details}
                      </span>
                    )}
                  </div>

                  <div className="col-md-2 mt-3 mt-sm-0">
                    <button
                      className="n-btn"
                      type="button"
                      onClick={handleAddSponsor}>
                      Add Sponsor
                    </button>
                  </div>
                </div>

                {editSponsorsList?.length > 0 && (
                  <div className="mt-3">
                    <h5 className="form-heading">Confirmed Sponsors List</h5>
                    <ul className="sponsor-list p-0">
                      {editSponsorsList?.map((sponsor, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between gap-2 align-items-center sponsor-item mt-2"
                        >
                          <img
                            src={`${baseUrls?.sponsors_brand_logo}/${sponsor?.sponsors_brand_logo}`}
                            alt={sponsor?.sponsor_name}
                            className="sponsor-logo"
                          />
                          <p className="sponsor-name text-capitalize p-0 m-0">
                            {sponsor?.sponsor_name}
                          </p>

                          <div
                            className="sponsor-details"
                            dangerouslySetInnerHTML={{
                              __html: sponsor?.sponsor_details,
                            }}
                          ></div>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => EditConfirmSponsor(sponsor?.id)}
                              type="button"
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => DltConfirmSponsor(sponsor?.id)}
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {sponsors?.length > 0 && (
                  <div className="mt-3">
                    {/* <h5 className="form-heading">Confirmed Sponsors List</h5> */}
                    <ul className="sponsor-list p-0">
                      {sponsors?.map((sponsor, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between gap-2 align-items-center sponsor-item mt-2"
                        >
                          <img
                            src={URL.createObjectURL(
                              sponsor?.sponsors_brand_logo
                            )}
                            alt={sponsor.sponsor_name}
                            className="sponsor-logo"
                          />
                          <p className="sponsor-name text-capitalize p-0 m-0">
                            {sponsor?.sponsor_name}
                          </p>

                          <div
                            className="sponsor-details"
                            dangerouslySetInnerHTML={{
                              __html: sponsor?.sponsor_details,
                            }}
                          ></div>

                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                              onClick={() => handleNormalEdit(sponsor, index)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                              onClick={() => handleDeleteSponsor(index)}
                            >
                              Delete
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-md-end align-items-center my-3">
            <div className="d-flex flex-column flex-md-row gap-2">
              <button
                className="d-flex align-items-center gap-1 saveandcontinue-btn"
                type="submit" >
                {saveBtnLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                Save and Continue
                <img className="arrow-img" src={arrow} alt="arrow" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditEventPitch;
