import ReactQuill from "react-quill";
import arrow from "../../assets/images/arrow2.svg";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useEffect, useState } from 'react';
import { CreateEventGuests, GetGuestData } from "../../utils/CreateEvents/createEvent";
import { Get_UserInterest, Get_languages_list } from "../../utils/basicInformation/basicinformationManager";
import Loader from "../Loader/Loader";
import { createPastEventGuest, GetPastEventGuestForm } from "../../utils/createPastEventForm/createpasteventform";
import { LuMinus } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { CreateInterest } from "../../utils/profile/GetInterests";
import { toast } from "react-toastify";
import InputRange from "react-input-range";
import { IoMdInformationCircleOutline } from "react-icons/io";


const PastEventGuestForm = ({ setTabValue, event_id, Messages, isEdit, tabValue, Eid }) => {
    const [predefineIntrest, setPredefineIntrest] = useState([]);
    const [showInterestSection, setShowInterestSection] = useState(false);
    const [addBtnLoading, setAddBtnLoading] = useState(false);
    const [newInterest, setNewInterest] = useState("");
    const [showIntrest, setShowIntrest] = useState([]);
    const [interestError, setInterestError] = useState("");
    const [input, setInput] = useState({
        guests_count_min: '',
        guests_count_max: '',
        guests_profile_summary: '',
        tags: "",
    })
    const [loading, setLoading] = useState(false)
    const [isHovered, setIsHovered] = useState(false);
    const [error, setError] = useState({})

    const getEditGuestData = async () => {
        try {
            await GetPastEventGuestForm(Eid)
                .then((response) => {
                    const Intrest = response?.data?.data?.tags?.map((item) => ({
                        value: item?.user_interests?.id,
                        label: item?.user_interests?.name,
                    }));
                    setShowIntrest(Intrest);
                    const listIntrest = Intrest?.map((item) => item.value);

                    setInput((currentInput) => ({
                        ...currentInput,
                        ...response?.data?.data,
                        tags: JSON.stringify(listIntrest),
                    }));
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (isEdit && tabValue == 'Event-Guest') {
            getEditGuestData()
        }
    }, [Eid]);

    const getUserInterests = async () => {
        try {
            await Get_UserInterest().then((response) => {
                const getInterest = response?.data;
                const options = getInterest?.map((topic) => ({
                    value: topic.id,
                    label: topic.name,
                }));
                setPredefineIntrest(options);
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getUserInterests();
    }, []);



    const handleChange = (e) => {
        const { name, value } = e.target
        setInput({
            ...input,
            [name]: value,
        });
    }

    const handleAddIntrest = (selectedOption) => {
        setShowIntrest(selectedOption)
        const list = selectedOption?.map((item) => {
            return item.value;
        });
        setInput({ ...input, tags: JSON.stringify(list) });
    };

    const handleGuestProfileSummary = (value) => {
        const text = value.replace(/<[^>]*>?/gm, '');
        if (text.length <= 500) {
            setInput((prevState) => ({
                ...prevState,
                guests_profile_summary: value
            }));
            setError({ ...error, guests_profile_summary: "" });
        } else {
            setError({ ...error, guests_profile_summary: "Guest Profile Summary cannot exceed 500 characters." });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true)
            try {
                await createPastEventGuest(input, Eid).then((results) => {
                    if (results?.data?.success) {
                        setTabValue("Event-Pitch");
                        Messages(results?.data?.message);
                    }
                });
            } catch (error) {
                console.log(error)
            }finally{
            setLoading(false)
            }
        }
    }

    const getAddSelectInterests = async (resData) => {
        try {
            await Get_UserInterest().then((response) => {
                const getInterest = response?.data;
                const foundItem = getInterest?.find(item => item?.name == resData);
                const newItem = {
                    value: foundItem?.id,
                    label: foundItem?.name,
                }
                // setShowIntrest(prevItems => [...prevItems, newItem])
                setShowIntrest(prevItems => {
                    const updatedItems = [...prevItems, newItem];
                    const updatedValues = updatedItems.map(item => item.value);
                    setInput({ ...input, tags: JSON.stringify(updatedValues) });
                    return updatedItems;
                });
            });
        } catch (err) {
            console.log(err);
        }
    };



    const createInt = async () => {
        if (!newInterest) {
            setInterestError("New Interest is Required");
        } else {
            setAddBtnLoading(true);
            setInterestError("");
            const fData = {
                name: newInterest,
            };
            try {
                const response = await CreateInterest(fData);
                if (response?.data?.success) {
                    Messages(response?.data?.message);
                    setNewInterest("");
                    getUserInterests();
                    setShowInterestSection(false)
                    const resData = response?.data?.newly_added;
                    resData && getAddSelectInterests(resData)
                } else {
                    Messages(response?.data?.message);
                    setNewInterest("");
                }
            } catch (err) {
                Messages(err?.response?.data?.message);
                setNewInterest("");
            } finally {
                setAddBtnLoading(false);
            }
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!input.guests_count_min) {
            newErrors.guests_count_min = "Minimum Guest is required";
            valid = false;
        } else if (!input.guests_count_max) {
            newErrors.guests_count_max = "Maximum Guest is required";
            valid = false;
        }

        const guest_summaray = input.guests_profile_summary?.replace(/<p><br><\/p>/g, '')
        if (guest_summaray?.trim() == "") {
            newErrors.guests_profile_summary = "Guests Profile Summary is required";
            valid = false;
        }

        if (input?.tags.length <= 0) {
            newErrors.tags = "Guest Interest is required";
            valid = false;
        } else if (
            !Array.isArray(JSON.parse(input.tags)) ||
            JSON.parse(input.tags).length < 5
        ) {
            newErrors.tags = "Select at least 5 Guest Interest";
            valid = false;
        } else if (JSON.parse(input.tags).length > 15) {
            newErrors.tags = "Select at most 15 Guest Interest";
            valid = false;
        }

        setError(newErrors);
        return valid;
    };

    const handleRangeChange = (range) => {
        setInput((prevInput) => ({
            ...prevInput,
            guests_count_min: range.min,
            guests_count_max: range.max,
        }));
    };

    const capitalizeWords = (string) => {
        return string.split(' ')?.map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    const handleInterestChange = (e) => {
        const inputValue = e.target.value;
        const capitalizedValue = capitalizeWords(inputValue);
        setNewInterest(capitalizedValue);
    }
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // rect quill length count
    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
    
        let textWithNewlines = div.innerHTML
    
        if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
          textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
        } else {
          // Otherwise, apply replacements
          textWithNewlines = textWithNewlines
            .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
        }
    
        div.innerHTML = textWithNewlines;
        return div.textContent || div.innerText || "";
      };

    const getDescriptionLength = () => {
        const cleanDescription = stripHtmlTags(input.guests_profile_summary);
        return cleanDescription.length;
    };

    const maxLength = 500;
    const currentLength = getDescriptionLength();
    const remainingLength = maxLength - currentLength;
    //

    return (
        <>
                <div className="basicInfo">
                    {/* <h2 className="basicinfo-head">Event Guest</h2> */}
                    <div className="d-flex align-items-center gap-1 position-relative">
                        <p className="createevent-head-p">
                            This section is dedicated to gathering comprehensive details about the demographics and preferences of your event's attendees.
                            By providing following insights, Sponsors get to effectively match their target audience with the event Guests. This enables them to make informed decisions
                            about sponsoring the event and ensuring alignment with their branding and marketing objectives.
                        </p>
                        {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Event Guest</h2>
                        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <IoMdInformationCircleOutline size={23} />
                        </div>
                        {isHovered && (
                            <div
                                className="info-popup"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                <p className="info-popup-text">
                                    This section is dedicated to gathering comprehensive details about the demographics and preferences of your event's attendees.
                                    By providing following insights, Sponsors get to effectively match their target audience with the event Guests. This enables them to make informed decisions
                                    about sponsoring the event and ensuring alignment with their branding and marketing objectives.
                                </p>
                            </div>
                        )} */}
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-md-4" >
                                <div className="form-group">
                                    <label className="form-label">Guest Count<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} title="Select the expected range of guest attendance for your event" />
                                    <div className="card-group-item">
                                        <div className="range-content p-0">
                                            <div className="d-flex gap-4">
                                                <div className="">
                                                    <input
                                                        type="number"
                                                        min='0'
                                                        className="form-control"
                                                        name="guests_count_min"
                                                        placeholder="Min"
                                                        value={input.guests_count_min}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="number"
                                                        max='100000'
                                                        className="form-control"
                                                        name="guests_count_max"
                                                        placeholder="Max"
                                                        value={input.guests_count_max}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-2 px-3 mb-2">
                                                <InputRange
                                                    maxValue={100000}
                                                    minValue={0}
                                                    name="guest_count_min_max"
                                                    value={{ min:input?.guests_count_min, max:input?.guests_count_max }}
                                                    onChange={handleRangeChange}
                                                    formatLabel={() => ''}
                                                />
                                            </div>
                                            {error.guests_count_min && (
                                                <span className="error">{error.guests_count_min}</span>
                                            )}
                                            {error.guests_count_max && (
                                                <span className="error">{error.guests_count_max}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className=" col-md-6" >
                                <div className="form-group">
                                    <label className="form-label">
                                        Guest Interest<span className="asterisk">*</span>
                                    </label>
                                    <IoMdInformationCircleOutline size={16} title="Enter the primary interests or hobbies of your guests." />

                                    <div className="d-flex align-items-center gap-2">
                                        <Select
                                          defaultValue={[]}
                                          isMulti
                                          name="tags"
                                          value={showIntrest}
                                          options={predefineIntrest}
                                          onChange={handleAddIntrest}
                                          className="col-md-10"
                                          classNamePrefix="Select "
                                          placeholder="Select Guest Interest"
                                          closeMenuOnSelect={false}
                                        />
                                        <div>
                                            <button className={showInterestSection ? "active-add-interests-btn m-0" : "add-interests-btn m-0"}
                                                type="button"
                                                onClick={() => setShowInterestSection(!showInterestSection)}>
                                                {showInterestSection ? (<>
                                                    <FiPlus size={18} />
                                                    Add More
                                                    {/* <LuMinus size={20} color="white" /> */}
                                                </>) : (<>
                                                    <FiPlus size={18} />
                                                    Add More
                                                    {/* <FiPlus size={20} color="white" /> */}
                                                </>)}
                                            </button>

                                        </div>
                                    </div>
                                    {error.tags && <span className="error">{error.tags}</span>}
                                </div>
                            </div>
                        </div>
                        {showInterestSection && (
                            <div className="row">
                                <div className="form-group col-6 col-md-6">
                                    <label className="form-label">
                                        New Interest<span className="asterisk">*</span>
                                    </label>

                                    <div className="d-flex gap-2">
                                        <input
                                            className="form-control"
                                            placeholder="Enter New Interest"
                                            value={newInterest}
                                            name="newInterest"
                                            onChange={handleInterestChange}
                                        />
                                        <button
                                            className=" new-interests-btn"
                                            type="button"
                                            onClick={createInt}>
                                            {addBtnLoading && (
                                                <div
                                                    className=" spinner-border text-light"
                                                    role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            Add
                                        </button>
                                    </div>
                                    {interestError && (
                                        <p className="error ms-1">{interestError}</p>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className='row mt-2'>
                            <div className='col-md-12' >
                                <div className="d-flex justify-content-between">
                                    <label className="form-label mb-0">
                                        Guest Profile Summary<span className="asterisk">*</span>
                                        <IoMdInformationCircleOutline size={16} title="Summarize your guests’ demographics and preferences. In addition to the data collected, consider adding insights 
                                        on Community, Cultural Background, Buying Preferences, Leisure Activities, Disability Access Needs, Dietary Preferences/Restrictions,
                                         Travel Preferences etc. " color="black" />

                                    </label>
                                    <p className="Character-0500">
                                        Character:<span className="text-style-1">
                                            {/* remainingLength */}
                                            {currentLength}/{maxLength}
                                        </span>
                                    </p>
                                </div>
                                <p className="reactquill-tooltip-p mb-1">(Note: Summarize your guests’ demographics and preferences. In addition to the data collected, consider adding insights
                                    on Community, Cultural Background, Buying Preferences, Leisure Activities, Disability Access Needs, Dietary Preferences/Restrictions,
                                    Travel Preferences etc.)
                                </p>
                                <div style={{ height: '220px' }}>
                                    <ReactQuill
                                        value={input.guests_profile_summary}
                                        onChange={handleGuestProfileSummary}
                                        theme='snow'
                                        style={{ height: '150px' }}
                                        placeholder="Enter Guest Profile Summary"
                                    />
                                </div>
                                {error.guests_profile_summary && (
                                    <span className="error">{error.guests_profile_summary}</span>
                                )}
                            </div>
                        </div>
                        
                        <div className="d-flex justify-content-md-end align-items-center mt-3 mt-sm-0">
                            <div className="d-flex flex-column flex-md-row gap-2">
                                <button
                                    className="d-flex align-items-center gap-1 saveandcontinue-btn"
                                    type="submit">
                                    {loading ? (
                                        <div
                                            className="spinner-border text-light "
                                            role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    Save and Continue
                                    <img className="arrow-img" src={arrow} alt="arrow" />
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
        </>
    )
}
export default PastEventGuestForm;