import { useEffect, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { IoClose, IoWarningOutline } from "react-icons/io5";
import {
  Update_Email,
  Verify_Change_Email,
} from "../../utils/profile/UpdateEmail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { profileval } from "../../redux/UserProfile/Profile";
import { useDispatch } from "react-redux";
import { UserProfile, logActivityCheck, pass_systemIpAddress, systemIpAddress } from "../../utils/user/UserManager";

const EditEmailPopup = ({ setEmailPopup, val }) => {
  const [showSendEmailBtn, setSendEmailBtn] = useState(false);
  const [emailOtpSection, setEmailOtpSection] = useState(false);
  const [emailVerifyBtn, setEmailVerifyBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [sendBtnLoading, setSendBtnLoading] = useState(false);
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);
  const [checkEmail, setCheckEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [informationIp, setInformationIp] = useState({});
  const [countdown, setCountdown] = useState(0);
  const [reSendOtp, setReSendOtp] = useState(false)

  const dispatch = useDispatch();

  const SystemIp = async () => {
    try {
      await systemIpAddress()
        .then((response) => {
          passIpAddress(response?.data?.ip)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const passIpAddress = async (ipv4) => {
    try {
      await pass_systemIpAddress(ipv4)
        .then((response) => {
          setInformationIp(response?.data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setEmail(val?.email);
    SystemIp();
  }, [val]);

  const getProfile = async () => {
    try {
      await UserProfile().then((data) => {
        dispatch(profileval(data?.data?.user));
      });
    } catch (error) {
      console.log("profile", error);
    }
  };

  const handleInputEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = emailPattern.test(value);
    if (isValidEmail && value != val?.email) {
      setSendEmailBtn(true);
    } else {
      setSendEmailBtn(false);
    }
  };

  const handleSendOtpOnEmail = async () => {
    const fdata = {
      email: email,
      id: val?.id,
    };
    setCheckEmail(email)
    try {
      setSendBtnLoading(true);
      const response = await Update_Email(fdata);
      const res_otp = response?.data?.otp;
      if (response.data.success) {
        // setOtp(res_otp);
        setEmailOtpSection(true);
        if (res_otp) {
          setEmailVerifyBtn(true);
          setSendEmailBtn(false);

          setCountdown(300); // 5 minutes countdown
          setReSendOtp(false)
          // Start countdown
          const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown <= 1) {
                clearInterval(timer);
                setOtp(null);
                setEmailVerifyBtn(false);
                setEmailOtpSection(false)
                setReSendOtp(true)
                toast.info("OTP has expired. Please request a new one.", {
                  position: "top-center",
                  autoClose: 3000,
                });
                return 0;
              }
              return prevCountdown - 1;
            });
          }, 1000);
        } else {
          setEmailVerifyBtn(false);
        }
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      }

    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setSendBtnLoading(false);
    }
  };

  const handleEmailOtp = (e) => {
    const emailOtp = e.target.value;
    setOtp(emailOtp);
    if (emailOtp?.length > 5 && emailOtp?.length < 7) {
      setEmailVerifyBtn(true);
    } else {
      setEmailVerifyBtn(false);
    }
  };

  const loggedActivity = async () => {
    const data = {
      ip_address: informationIp?.ip,
      lat: informationIp?.latitude,
      long: informationIp?.longitude,
      log_type: 'changeEmail'
    }
    try {
      await logActivityCheck(data)
        .then((response) => {
          // console.log(response)
          // if (response?.status == 200) {
          //   localStorage.setItem("LoginToken", loginToken);
          //   navigate("/");
          // }
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleOtp = async () => {
    const fData = {
      otp: otp,
      email: email,
      id: val?.id,
    };
    try {
      setVerifyBtnLoading(true)
      const response = await Verify_Change_Email(fData);
      if (response?.data?.success) {
        getProfile();
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        loggedActivity();
        setEmailPopup(false);
      } else {
        toast.error(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setVerifyBtnLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="warningModal">
        <div className="warning-mdl">
          {/* <span className="warning-close-btn" >&times;</span> */}
          <div className='d-flex justify-content-between'>
            <h4 className="edit-email-heading m-0">Edit Email </h4>
            <button className='mdl-cancel-btn' onClick={() => setEmailPopup(false)}>
              <IoClose size='25' />
            </button>
          </div>
          <div className="form-group mt-2 mb-1">
            <label className="form-label">
              Email<span className="asterisk">*</span>
            </label>
            <div className="position-relative d-flex align-items-center">
              <input
                type="text"
                value={email}
                name="email"
                onChange={handleInputEmailChange}
                className="form-control"
                placeholder="email"
              />
              {showSendEmailBtn && (
                <button
                  className="otp-send-btn"
                  type="button"
                  onClick={handleSendOtpOnEmail}
                  title="Send otp">
                  {sendBtnLoading ? (
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) :
                    <IoMdSend size={24} color="#FF2D55" />
                  }
                </button>
              )}

              {reSendOtp && (
                <button
                  className="verify-otp"
                  type="button"
                  onClick={handleSendOtpOnEmail}>
                  {
                    sendBtnLoading &&
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                  Resend OTP
                </button>
              )}
            </div>
            {emailOtpSection && (
              <div className="position-relative d-flex align-items-center mt-2">
                <input
                  type="text"
                  value={otp}
                  name="otp"
                  className="form-control"
                  placeholder="Otp"
                  onChange={handleEmailOtp}
                />
                {emailVerifyBtn && (
                  <button
                    className="verify-otp"
                    type="button"
                    onClick={handleOtp}>
                    {
                      verifyBtnLoading &&
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    }
                    Verify
                  </button>
                )}
              </div>
            )}

            {countdown > 0 && (
              <p className="mt-2">OTP expires in: {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? `0${countdown % 60}` : countdown % 60} minutes</p>
            )}
          </div>
          {/* <div className="d-flex gap-2 justify-content-end">
            <button
              className="mdl-cancel-btn mt-2"
              onClick={() => setEmailPopup(false)}
            >
              Cancel
            </button>
            <button
              className="warning-mdl-btn mt-2"
            // onClick={() => setTabValue(tabVal)}
            >
              Ok
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default EditEmailPopup;
