import DocViewer from "@cyntler/react-doc-viewer";
import { IoCloseSharp } from "react-icons/io5";


const PdfView = ({baseUrls,pdfFile,setPdfFile}) => {
  // fileType
    const docs = [
      {
        uri: `${pdfFile?.fileType == "mediahub" ? baseUrls?.docs_image_url : baseUrls?.event_schedule_document_url}/${pdfFile?.filename}`,
      },
    ];

  return (
    <>
      <div className="">
        <div className="package_form_overlay">
          <div className="pdfview_popup">
            <div className="package_form_popup-content">    
              <div className="basicInfo ">
                <div>
                  <div className="">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn p-0 border-none text-dark"
                        onClick={() => setPdfFile({})}>
                        <IoCloseSharp size={24} />
                      </button>
                    </div>
                    <DocViewer documents={docs} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfView;
