import { useEffect, useState, useRef } from "react";
import { Get_ManageRequest } from "../../utils/managerequest/GetManageRequest";
import Loader from "../../components/Loader/Loader";
import { GetMessage } from "../../utils/managerequest/GetMessage";
import ManageChatSection from "../../components/ManageRequest/ManageChatSection";
import { useSelector } from "react-redux";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { FaToggleOff, FaToggleOn } from "react-icons/fa6";
import { TiArrowLeft } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

const ManageRequest = () => {
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const [tabValue, setTabValue] = useState(
    profileinfo?.user_type ? "Champ" : "Host"
  );
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mainLoading, setMainLoading] = useState(true);
  const [chatSection, setChatSection] = useState(false);
  const [getMesage, setGetMessage] = useState([]);
  const [messageListInfo, setmessageListInfo] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState("");
  const [userId, setUserId] = useState("");
  const [isArchiveChecked, setIsArchiveChecked] = useState(false);
  const navigate = useNavigate();
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDateDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    };
  },[dateDropdownOpen]);

  const GetManageRequest = async () => {
    setChatSection(false);
    setMainLoading(true);
    const checkIsArchive = isArchiveChecked ? 1 : 0;
    try {
      const GetRequestData = await Get_ManageRequest(tabValue, searchValue,checkIsArchive);
      if (tabValue == "Host") {
        setRequestData(GetRequestData?.data?.data);
        if (GetRequestData?.data?.data?.length > 0) {
          const requestId = GetRequestData?.data?.data[0]?.id;
          setUserId(requestId)
          setmessageListInfo(GetRequestData?.data?.data[0]);
          // setMainLoading(true);
          try {
            const getMsg = await GetMessage(requestId);
            setGetMessage(getMsg?.data?.data?.send);
          } catch (error) {
            console.log(error);
          } finally {
            // setMainLoading(false);
          }
        } else {
          setGetMessage([]);
          setmessageListInfo({});
        }
      } else {
        setRequestData(GetRequestData?.data?.data);

        if (GetRequestData?.data?.data?.length > 0) {
          const requestId = GetRequestData?.data?.data[0]?.id;
          setmessageListInfo(GetRequestData?.data?.data[0]);
          setUserId(requestId)
          // setMainLoading(true)
          try {
            const getMsg = await GetMessage(requestId);
            setGetMessage(getMsg?.data?.data?.send);
          } catch (error) {
            console.log(error);
          } finally {
            // setMainLoading(false)
          }
        } else {
          setGetMessage([]);
          setmessageListInfo({});
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setMainLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetManageRequest();
  }, [tabValue,isArchiveChecked]);


  const handleToggle=()=>{
    setIsArchiveChecked(!isArchiveChecked)
  }

  return (
    <>
      <div className="your-events container">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="">
            <h2 className="your-events-heading m-0 p-0">Manage Request</h2>
          </div>
          <div className="d-flex align-items-center position-relative gap-3" ref={dropdownRef}>
          <button className="go-back-btn p-0" onClick={() => navigate(-1)} type="button">
            <TiArrowLeft size={20}/> Go Back
          </button>
          <div className="d-flex align-items-center gap-1">
            <p className="m-0 order-list-text">Filter:</p>
            <button
              className="d-flex order-dropdown-button"
              onClick={() => { setDateDropdownOpen(!dateDropdownOpen) }}>
              Date
              <IoMdArrowDropdown size={24} />
            </button>
          </div>
            {dateDropdownOpen && (
              <div className="dropdown-menu-data">
                <div
                  className="dropdown-item"
                  onClick={() => { setDateFilterValue("this_week"); setDateDropdownOpen(false) }}>
                  This Week
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => { setDateFilterValue("last_week"); setDateDropdownOpen(false) }}>
                  Last Week
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => { setDateFilterValue("current_month"); setDateDropdownOpen(false) }}>
                  Current Month
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => { setDateFilterValue("last_month"); setDateDropdownOpen(false) }}>
                  Last Month
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => { setDateFilterValue("quarter"); setDateDropdownOpen(false) }}>
                  Quarter
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => { setDateFilterValue("6_months"); setDateDropdownOpen(false) }}>
                  6 months
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => { setDateFilterValue("this_year"); setDateDropdownOpen(false) }}>
                  This Year And Older
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end">
          {dateFilterValue && (
            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0 order-list-text">Filter By :</p>
                <div className="sort-clear-div ">
                  <p className="m-0 order-list-text text-light text-capitalize p-0">
                    {dateFilterValue}
                  </p>
                  <button
                    className="sort-clear-btn"
                    onClick={() => setDateFilterValue("")}>
                    <IoCloseOutline color="white" size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
        <div className="row main-tab">

         <div className=" col-md-3 mb-3 mb-sm-0 p-0">
          <button className={isArchiveChecked ? "archive-active-btn" : "archive-btn"} onClick={handleToggle}>
            Archive {isArchiveChecked ? <FaToggleOn size={20}/> : <FaToggleOff size={20}/>} 
         </button>
        </div>

            {!profileinfo?.user_type ? (
              <>
                <button
                  onClick={() => setTabValue("Host")}
                  className={`${tabValue == "Host" ? "active-tab-button" : "tab-button"} col-6 col-md-4 col-xl-3 `}>
                  Manage as a Host
                </button>

                <button
                  onClick={() => setTabValue("Sponsor")}
                  className={`${tabValue == "Sponsor" ? "active-tab-button" : "tab-button"} col-6 col-md-4 col-xl-3`}>
                  Manage as a Sponsor
                </button>
              </>
               ) : ( 
              <button
                onClick={() => setTabValue("Champ")}
                className={`${tabValue == "Champ" ? "active-tab-button" : "tab-button"} col-6 col-xl-3`}>
                Manage as a Champ
              </button>
            )}
          </div>
        

        </div>



        {mainLoading ? (
          <Loader />
        ) : 
          <div className="mt-4">
            {requestData?.length === 0 || requestData == undefined ||  requestData == null  ? 
            <h3 className="text-center my-5">No Record Found</h3> : (
              <ManageChatSection
                loading={loading}
                tabValue={tabValue}
                messageListInfo={messageListInfo}
                setmessageListInfo={setmessageListInfo}
                setRequestData={setRequestData}
                getMesage={getMesage}
                setGetMessage={setGetMessage}
                setChatSection={setChatSection}
                chatSection={chatSection}
                requestData={requestData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                dateFilterValue={dateFilterValue}
                setDateFilterValue={setDateFilterValue}
                userId={userId}
                setUserId={setUserId}
                baseUrls={baseUrls}
                isArchiveChecked={isArchiveChecked}
                GetManageRequest={GetManageRequest}
              />
            )}
          </div>
        }
      </div>
    </>
  );
};
export default ManageRequest;
