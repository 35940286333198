import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import imageicon from "../../assets/images/image-upload-icon.svg";
import Documenticon from "../../assets/images/document-upload-icon.svg";
import arrow from "../../assets/images/arrow2.svg";
import pdfimg from "../../assets/images/pdf.png";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import upload from "../../assets/images/upload-icon.png";
import { Event_MediaHub } from "../../utils/CreateEvents/createEvent";
import { DeleteBanner, Get_Edit_Event_Media_Hub } from "../../utils/EditEvent/editEvent";
import { Event_Image_Docs_delete } from "../../utils/editevents/EditEvent";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import BannerLogoSection from "../Common/CreateEvent/BannerLogoSection";
import EventMediaHubImageDoc from "../Common/CreateEvent/EventMediaHubImageDoc";

const EditEventMediaHub = ({ id, setTabValue, Messages }) => {
  const [video_link, setVideo_link] = useState("");
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [multipleVideoLink, setMultipleVideoLink] = useState([]);
  const [showImages, setShowImages] = useState([]);
  const [showPdf, setShowPdf] = useState([]);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const [isHovered, setIsHovered] = useState(false);


  const [formData, setFormData] = useState({
    banner: null,
    logo: null,
    website_link: "",
  });

  const refs = {
    banner: useRef(null),
    logo: useRef(null),
    website_link: useRef(null)
  };

  const [selectedBannerFiles, setSelectedBannerFiles] = useState([]);
  const [predefine_Banner, setPredefine_Banner] = useState([]);

  const [errors, setErrors] = useState({
    multipleVideoLink: "",
    imagess: "",
    documentss: "",
  });

  const handleAddMultipleURL = () => {
    if (video_link.trim() !== "") {
      const updatedLinks = [...multipleVideoLink, video_link];
      if (updatedLinks.length <= 5) {
        setMultipleVideoLink(updatedLinks);
        setVideo_link("");
      } else {
        toast.warn("You can only add up to 5 video links.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        });
      }
    }
  };

  const handleDeleteURL = (index) => {
    const updatedVideoLinks = multipleVideoLink.filter(
      (item, idx) => idx !== index
    );
    setMultipleVideoLink(updatedVideoLinks);
  };

  const handleFileChangeImage = (event) => {
    const files = event.target.files;
    const imageArray = [];

    if (images.length + files.length + showImages?.length > 6) {
      toast.warn("You can only add up to 6 images", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      imageArray.push(file);
    }
    setImages([...images, ...imageArray]);
  };

  const handleRemoveImage = (idx) => {
    const val = images.filter((item, index) => index !== idx);
    setImages(val);
  };

  const handleFileChangeDocument = (event) => {
    const files = event.target.files;
    const documentArray = [];

    if (documents.length + files.length + showPdf?.length > 3) {
      toast.warn("You can only add up to 3 documents", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      documentArray.push(file);
    }
    setDocuments([...documents, ...documentArray]);
  };

  const handleRemoveDocument = (idx) => {
    const val = documents.filter((item, index) => index !== idx);
    setDocuments(val);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "banner" || name === "logo") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const submitData = async () => {
    if (!validateForm()) {
      return;
    }
    const FData = {
      banner_images: selectedBannerFiles,
      logo: formData?.logo,
      video_links: multipleVideoLink,
      images: images,
      website_link: formData?.website_link,
      documents: documents,
    };

    try {
      setSubmitBtnLoading(true);
      await Event_MediaHub(id, FData).then((result) => {
        if (result?.data?.success) {
          Messages(result?.data?.message);
          setTabValue("Social-Hub");
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitBtnLoading(false);
    }
  };

  const EventmediaData = async () => {
    try {
      Get_Edit_Event_Media_Hub(id).then((response) => {
        const data = response?.data?.data;
        setFormData(data);
        setMultipleVideoLink(data?.video_links.map((item) => item?.video_link));
        setShowImages(
          data?.event_gellary.filter(
            (item) => item?.mime_type?.toLowerCase() == "jpg" ||
            item?.mime_type?.toLowerCase() == "png" ||
            item?.mime_type?.toLowerCase() == "jpeg" ||
            item?.mime_type?.toLowerCase() == "webp" ||
            item?.mime_type?.toLowerCase() === "gif" ||
            item?.mime_type?.toLowerCase() === "bmp" ||
            item?.mime_type?.toLowerCase() === "svg+xml"
          )
        );
        setShowPdf(
          data?.event_gellary.filter(
            (item) => item?.mime_type?.toLowerCase() == "pdf" ||
            item?.mime_type?.toLowerCase() == "doc" ||
            item?.mime_type?.toLowerCase() == "docx" ||
            item?.mime_type?.toLowerCase() == "xls" ||
            item?.mime_type?.toLowerCase() == "xlsx" ||
            item?.mime_type?.toLowerCase() == "txt" 
          )
        );
        //setSelectedBannerFiles([]);
        setPredefine_Banner(data?.event_banner);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    EventmediaData();
  }, []);

  const handleDltImageDocs = async (id) => {
    await Event_Image_Docs_delete(id).then((response) => {
      if (response?.data?.success == true) {
        EventmediaData();
        Messages(response?.data?.message);
      }
    });
  };

  const validateForm = () => {
    const errors = {};

    // if (!formData.banner ) {
    //   errors.banner = "Banner is required";
    // } else if (formData?.banner?.size > 2 * 1024 * 1024) {
    //   errors.banner = "Banner size exceeds the maximum allowed size (2MB).";
    // }
    if (selectedBannerFiles?.length == 0 && predefine_Banner?.length == 0) {
      errors.banner = "Please add at least one banner.";
    }

    if (!formData.logo) {
      errors.logo = "Logo is required";
    } else if (formData?.logo?.size > 2 * 1024 * 1024) {
      errors.logo = "Logo size exceeds the maximum allowed size (2MB).";
    }

    setErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  const handleBannerFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedBannerFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleBannerRemoveImage = (idx) => {
    const val = selectedBannerFiles?.filter((item, index) => index !== idx);
    setSelectedBannerFiles(val);
  };

  const handleRemoveBanner = async (id) => {
    try {
      const response = await DeleteBanner(id);
      Messages(response?.data?.message);
      EventmediaData();
    } catch (err) {
      console?.log(err)
    }
  }

  // useEffect(() => {
  //   const firstErrorKey = Object.keys(errors)[0];
  //   if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey]?.current) {
  //     refs[firstErrorKey]?.current.focus();
  //   }
  // }, [errors]);

  const handleDeleteBannerLogo=()=>{
    
  }

  return (
    <>
      <div className="basicInfo">
        {/* <h2 className="basicinfo-head">Event Media Hub</h2> */}
        <div className="d-flex align-items-center gap-1 position-relative">
          <p className="createevent-head-p">This section allows you to upload and manage various media and documents related to your event to enhance your event's presentation and provide sponsors with essential information.</p>
          {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Event Media Hub</h2>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <IoMdInformationCircleOutline size={23} />
          </div>
          {isHovered && (
            <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <p className="info-popup-text">This section allows you to upload and manage various media and documents related to your event to enhance your event's presentation and provide sponsors with essential information.</p>
            </div>
          )} */}
        </div>

      <BannerLogoSection 
       baseUrls={baseUrls} 
       formData={formData} 
       setFormData={setFormData}
       selectedBannerFiles={selectedBannerFiles} 
       setSelectedBannerFiles={setSelectedBannerFiles}
       errors={errors}
       Messages={Messages}
       refs={refs}
       EventmediaData={EventmediaData}
       predefine_Banner={predefine_Banner}
       handleInputChange={handleInputChange}
      />  
       {/* <div className="row">
        <div className="col-md-6 row ">
          <div className="col-md-12">
            <p className="Bannerimg-head mb-0">
              Banner Image<span className="asterisk">*</span>
              <IoMdInformationCircleOutline size={16} color="black" title="Upload a main banner image for your event." />
            </p>
            <label className="bannerimg-div mt-2">
              <input
                className="d-none"
                type="file"
                name="banner"
                accept="image/*"
                multiple
                onChange={handleBannerFileChange}
                ref={refs?.banner}
              />
              <div className="upload-image-content">
                <img src={upload} alt="upload" />
                <p className="uploadimg-font">Upload Banner Image</p>
                <p className="img-format-text">Image format: .jpg or .png</p>
                <p className="img-format-text">
                  Recommended size: w: 1440px, H: 600px
                </p>
                <div>
                  {formData?.banner && (
                    <FaRegCircleCheck color="green" size={20} />
                  )}
                </div>
              </div>
            </label>
            {errors?.banner && <span className="error">{errors?.banner}</span>}
          </div>

          <div className="col-md-12">
            {formData?.banner !== "" && (
              <div className="mt-2 ">
                <div className="banner-main-div">
                  <div className="row">
                    {selectedBannerFiles?.map((val, index) => {
                      return (
                        <>
                          <div className="position-relative mt-2 col-6 d-flex justify-content-center">
                            <img
                              className="banner-image"
                              src={URL.createObjectURL(val)}
                              alt="updateimg"
                            />
                            <button
                              className="image-remove-icon"
                              onClick={() => handleBannerRemoveImage(index)}>
                              <MdDelete size={20} color="white" />
                            </button>
                          </div>
                        </>
                      );
                    })}

                    {predefine_Banner?.map((val) => {
                      return (
                        <>
                          <div className="position-relative mt-2 col-6 d-flex justify-content-center">
                            <img
                              className="banner-image"
                              src={`${baseUrls?.event_banner_image_url}/${val?.filename}`}
                              alt="updateimg"
                            />
                            <button
                              className="image-remove-icon"
                              onClick={() => handleRemoveBanner(val?.id)}>
                              <MdDelete size={20} color="white" />
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-md-6 row mt-3 mt-md-0">
          <div className="col-md-12">
            <p className="Bannerimg-head">
              Logo<span className="asterisk">*</span>
              <IoMdInformationCircleOutline size={16} color="black" title="Upload a logo image for your event, this is the first image attendees will see."/>
            </p>
            <label className="bannerimg-div mt-2">
              <input
                className="d-none"
                type="file"
                name="logo"
                accept="image/*"
                multiple
                onChange={handleInputChange}
                ref={refs?.logo}
              />
              <div className="upload-image-content">
                <img src={upload} alt="upload" />
                <p className="uploadimg-font">Upload Logo Image</p>
                <p className="img-format-text">Image format: .jpg or .png</p>
                <p className="img-format-text">
                  Recommended size: w: 274px, H: 330px
                </p>
                {formData?.logo && <FaRegCircleCheck color="green" size={20} />}
              </div>
            </label>
            {errors?.logo && <span className="error">{errors?.logo}</span>}
          </div>

          <div className="col-md-12">
            {formData?.logo !== "" && (
              <div className="mt-2 ">

                <div className="mt-2 d-flex justify-content-center">
                  {formData?.logo instanceof File ? (
                    <div className="col-6 position-relative">
                      <img
                        className="event-logo-img"
                        src={URL.createObjectURL(formData?.logo)}
                        alt="updateimg"
                      />
                      <button className="image-remove-icon"
                        onClick={() => setFormData({ ...formData, logo: "" })}>
                        <MdDelete size={20} color="white" />
                      </button>
                    </div>
                  ) : (
                    <div className="col-6 position-relative">
                      <img
                        className="event-logo-img"
                        src={`${baseUrls?.logo_image_url}/${formData?.logo}`}
                        alt="updateimg"
                      />
                      <button className="image-remove-icon"
                        onClick={() => setFormData({ ...formData, logo: "" })}>
                        <MdDelete size={20} color="white" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        </div> */}

   <EventMediaHubImageDoc
     handleFileChangeDocument={handleFileChangeDocument}
     handleFileChangeImage={handleFileChangeImage}
     images={images}
     setImages={setImages}
     showImages={showImages}
     EventmediaData={EventmediaData}
     Messages={Messages}
     baseUrls={baseUrls}
     documents={documents}
     setDocuments={setDocuments}
     showPdf={showPdf}
     />
        {/* <div className="mt-4">
          <div className="row">
            <h4 className="form-heading">Images (Maximum 6) <IoMdInformationCircleOutline size={16} color="black" title="Add additional images to showcase different aspects of your event." /></h4>
            <div className="d-flex flex-column align-items-center col-md-12">
              <label>
                <img className="upload-icon" src={imageicon} alt="img-upload" />
                <input
                  className="d-none"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChangeImage}
                />
              </label>
              <div className="">The maximunm size of an image can be 1 MB</div>
         
            </div>
            <div className="row mt-3">
              {images?.map((file, index) => (
                <div key={index} className="col-4 col-md-2 position-relative py-1 px-1">
                  <button
                    className="image-remove-icon"
                    onClick={() => handleRemoveImage(index)}>
                    <MdDelete size={20} color="white" />
                  </button>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`selected-${index}`}
                    className="event-gellary-img"
                  />
                </div>
              ))}
              {showImages?.map((val, index) => {
                return (
                  <>
                    <div
                      className="col-4 col-md-2 position-relative px-1 py-1"
                      key={index}>
                      <button
                        className="image-remove-icon"
                        onClick={() => handleDltImageDocs(val?.id)}>
                        <MdDelete size={20} color="white" />
                      </button>
                      <img
                        className="event-gellary-img"
                        src={`${baseUrls?.event_image_url}/${val?.filename}`}
                        alt="img1"
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div> */}

        {/* <div className="">
          <div className="row mt-4">
            <h4 className="form-heading">Documents (Maximum 3) <IoMdInformationCircleOutline size={16} color="black" title="Upload any documents related to the event." /></h4>
            <div className="d-flex flex-column align-items-center col-md-12">
              <label className="">
                <img
                  className="upload-icon"
                  src={Documenticon}
                  alt="img-upload"
                />
                <input
                  className="d-none"
                  type="file"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                  multiple
                  onChange={handleFileChangeDocument}/>
              </label>
              <div className="">The maximunm size of a file can be 4 MB</div>
            </div>
            <div className="row mt-3 mb-3">
              {documents?.map((file, index) => (
                <div className="px-1 py-1 col-4 col-md-2 position-relative" key={index}>
                  <button
                    className="image-remove-icon"
                    onClick={() => handleRemoveDocument(index)}>
                    <MdDelete size={20} color="white" />
                  </button>
                  <div className="pdf-div">
                    <div className="d-flex justify-content-center">
                    <img src={pdfimg} alt="pdf" style={{height:60}} />
                    </div>
                    <p className="pdf-title">{file?.name}</p>
                  </div>
                </div>
              ))}
              {showPdf?.map((val, index) => {
                return (
                  <div
                    className="px-1 position-relative py-1 col-4 col-md-2"
                    key={index} >
                    <button
                      className="image-remove-icon"
                      onClick={() => handleDltImageDocs(val?.id)} >
                      <MdDelete size={20} color="white" />
                    </button>
                    <div className="pdf-div">
                      <div className="d-flex justify-content-center">
                       <img src={pdfimg} alt="pdf" style={{height:60}} />
                      </div>
                      <p className="pdf-title">{val?.filename}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}

        <div className="mt-3">
          <h4 className="form-heading">Videos <IoMdInformationCircleOutline size={16} color="black" title="Upload any videos related to the event" /></h4>
          <div className="row">
            <div className="col-md-8">
              <label className="form-label ">Video URL</label>
              <div>
                <div className="d-flex form-group">
                  <div className="col-8">
                    <input
                      type="url"
                      value={video_link}
                      onChange={(e) => setVideo_link(e.target.value)}
                      className="form-control"
                      placeholder="Enter Video URL"
                    />
                  </div>
                  <div>
                    <button
                      className="saveandcontinue-btn ms-4"
                      onClick={handleAddMultipleURL}>
                      Add Link
                    </button>
                  </div>
                </div>
                <div className="col-md-10">
                  {multipleVideoLink?.map((item, idxx) => {
                    return (
                      <div className="create-msg-date-time-bar d-flex align-items-center">
                        <span className="create-Message">{item}</span>
                        <div className="">
                          <button
                            className="create-Date-Time"
                            onClick={() => handleDeleteURL(idxx)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <h5 className="form-section-heading">Event Website <IoMdInformationCircleOutline size={16} color="black" title="Enter your event’s official website for more detailed information" /></h5>
          <div className="form-group col-md-8">
            <label className="form-label mb-0">Website URL</label>
            <div className="col-8">
              <input
                type="text"
                value={formData.website_link}
                name="website_link"
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter Website URL"
              />
            </div>
            {/* {errors.website_link && (
                  <span className="error">{errors.website_link}</span>
                 )} */}
          </div>
        </div>

        <div className="mt-3">
          <h5 className="form-section-heading">Host</h5>
         <div className="row">
          <div className="col-5">
            <div className=" ">
            <label className="form-label">
              Logo<span className="asterisk">*</span>
            </label>
            <div className="position-relative event-profile-img"> 
                <img className="event-profile-img"
                  src={
                    profileinfo?.image_type == "browse"
                      ? `${baseUrls?.customer_image_url}/${profileinfo?.image}`
                      : `${baseUrls?.avatar_image_url}/${profileinfo?.predefine_image}`
                  }
                  alt="profile"
                />
            </div>
            </div>
          </div>

          <div className="col-7">
            <div className=" ">
            { profileinfo?.website_link &&
              <div>
                <label className="form-label">Website<span className="asterisk">*</span></label>
                <Link to={profileinfo?.website_link}>{profileinfo?.website_link}</Link>
              </div>
            }
            </div>
          </div>
          </div>
        </div>

        <div className="d-flex justify-content-md-end align-items-center mt-4">
          {/* <button className="btn delete-event-btn">Delete Event</button> */}
          <div className="d-flex gap-2">
            {/* {!profileinfo?.user_type &&
              <button className="d-flex align-items-center gap-1 btn saveandcontinue-btn" 
                onClick={()=>setSelectChampMdl(!selectChampMdl)} type="button">Change Champ</button>} */}

            <button className="saveandcontinue-btn d-flex align-items-center gap-2" onClick={submitData}>
              {submitBtnLoading && (
                <div className="spinner-border text-light " role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
               Save and Continue
              <img className="arrow-img" src={arrow} alt="arrow" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEventMediaHub;
