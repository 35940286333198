import React, { useEffect, useState } from "react";
import { Feature_EventPrice, Featured_Event, Featured_PaymentResponse } from "../../utils/basicInformation/basicinformationManager";
import Loader2 from "../Loader/Loader2";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FeatureEventMdl from "./MyEvent/FeatureEventMdl";
import { Featured_SelectedDate } from "../../utils/myevent/MyEvent";



const FeaturePaymentCardMdl=({setShowPaymentCardMdl,event_data,setEvent_Data})=>{
  const [priceData,setPriceData]=useState({});
  const [currency,setCurrency]=useState("");
  const [loading,setLoading]=useState(true);
  const [payBtnLoading, setpayBtnLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);

 const navigate = useNavigate();

const checkCurrency=(data)=>{
  if (data?.currency == "INR") {
    setCurrency("₹");
  } else if (data?.currency == "USD") {
    setCurrency("$");
  } else if (data?.currency == "EUR") {
    setCurrency("€");
  } else if (data?.currency == "GBP") {
    setCurrency("€");
  }
}
  
  const getPrice=async()=>{
   try{
    setLoading(true)
    // const response = await Feature_EventPrice()
    // setPriceData(response?.data?.data[0]);
    // checkCurrency(response?.data?.data[0])
   }catch(error){
    console.log(error)
   }finally{
    setLoading(false)
   }
  }

  useEffect(()=>{
   getPrice()
  },[]);

  //console.log("event_data",event_data)
  const handleFeaturedEvent=async()=>{
    const Fdata={
      event_id:event_data?.id
     }
    try{
     setpayBtnLoading(true);
     const response = await Featured_Event(Fdata)
     const orderid = response?.data?.order_id;
      const options = {
        key: "rzp_test_IcgTNsIE5vV4DM",
        key_secret: "yHf8cImq5DtzflsmjgsuJApS",
        order_id: orderid,
        handler: async (response) => {
          const payres = {
            razorpay_order_id: response?.razorpay_order_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_signature: response?.razorpay_signature,
          };
          const update_pay_FD = {
            payment_id: response?.razorpay_payment_id,
            order_id: orderid,
            payment_response: JSON.stringify(payres),
            status: "success",
            event_id:event_data?.id
          };
          try {
            const response = await Featured_PaymentResponse(update_pay_FD);
            if (response?.data?.success) { 
              toast.success(response?.data?.message,{
                position:'top-center'
              });
               setShowPaymentCardMdl(false)
            }    
          } catch (error) {
            console.log("FpayError",error);
          }
        },
      };
      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    }
     catch(error){
      console.log("Ferr",error)
       toast.error(error?.response?.data?.message,{
        position:'top-center'
      });
    } finally {
      setpayBtnLoading(false);
      setEvent_Data({})
      setShowPaymentCardMdl(false)
    }
  }

  const handle_FeatureSelect_Date=async()=>{
     const FData={
      dates:selectedDates,
      event_id: event_data?.id
     }
     try{
         const response = await Featured_SelectedDate(FData);
         if(response?.data?.success){
            handleFeaturedEvent();
         }
     }catch(err){
      console.log(err)
     }
  }

  const handlePayNow=async()=>{
    handle_FeatureSelect_Date();
  }

  const handleCancelPay=()=>{
    setShowPaymentCardMdl(false);
    setEvent_Data({})
  }

    return(<>
      <ToastContainer />
        <div className="warningModal">
          <div className="Feature-payment-mdl">
            {/* <span className="warning-close-btn" >&times;</span> */}
            <h4 className="warning-text">
              Make Feature Event
            </h4>
            
            <div className="card-content">
             <div className="d-flex flex-column flex-xl-row gap-3">
            
             <div className="d-flex justify-content-center py-2">
              <FeatureEventMdl totalPrice={totalPrice} setTotalPrice={setTotalPrice} 
               selectedDates={selectedDates} setSelectedDates={setSelectedDates} event_data={event_data}/>
             </div>
             {
              loading ? 
              // <Loader2/>
              ""
              :  
             <div className="price-detail-div">
              {/* <p className="total-price-text">Price Details</p> */}
              {/* <hr /> */}
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <p className="price-coupon-text">Event Name</p>
                  </div>
                  <div className=" text-center">
                    <p className="price-coupon-text">-</p>
                    </div>
                  <div className=" text-right">
                    <p className="total-price-text">{event_data?.event_title}</p> 
                    {/* <p className="coupon-text">{data?.title}</p> */}
                  </div>
                </div>

                <div className="d-flex row  mt-2">
                  <div className="col-md-4">
                  <p className="price-coupon-text">Amount</p>
                </div>
                 
                 <div className="col-md-4 text-center">
                  <p className="price-coupon-text">-</p>
                 </div> 

                 <div className="col-md-4 text-right">
                  <p className="coupon-price-text">
                   {totalPrice} 
                   {/* {currency} {priceData?.amount}            */}
                   {/* {currency?.currencySymbol ? currency?.currencySymbol : data?.currencies?.symbol} 
                  {currency?.selectedCurrencyPrice ?  (data?.amount*currency?.selectedCurrencyPrice).toFixed(2) : data?.amount} */}
                  </p>
                  </div>
                 </div>

                {/* <div className="d-flex row mt-2">
                  <div className="col-md-4">
                  <p className="price-coupon-text">Discount</p>
                  </div>
                 
                 <div className="col-md-4 text-center">
                 <p className="price-coupon-text">-</p>
                 </div>
                 
                 <div className="col-md-4 text-right">
                  <p className="coupon-disc-text  gap-1">
                    -
                    {data?.currencies?.symbol} {discountPrice == "" ? <p className="coupon-text">0.00</p>: discountPrice?.toFixed(2)}
                  </p>
                  </div>
                </div> */}

                <hr />
                <div className="d-flex row mt-2">
                  <div className="col-md-4">
                   <p className="total-price-text">Sub Total</p>
                  </div>
                
                  <div className="col-md-4 text-center">
                   <p className="price-coupon-text">-</p>
                  </div>
                
                  <div className="col-md-4 text-right">
                   <p className="total-price-text">
                    {/* {currency?.currencySymbol ? currency?.currencySymbol : data?.currencies?.symbol} 
                    {currency?.selectedCurrencyPrice ?  (subTotalPrice*currency?.selectedCurrencyPrice).toFixed(2) : subTotalPrice} */}
                    {/* {currency} {priceData?.amount} */}
                    {totalPrice}
                  </p>
                  </div>
                </div>
                

              </div>
            </div>
           }
            </div>
          </div>
          
            <div className="d-flex gap-2 justify-content-end pt-3">
              <button
                className="cancel-champ-btn mt-2"
                onClick={handleCancelPay}>
                Cancel
              </button>
              <button
                className="warning-mdl-btn mt-2"
                onClick={handlePayNow }
                disabled={selectedDates?.length <= 0}
                >
             {payBtnLoading ? 
              <div className="spinner-border text-light " role="status">
                <span className="sr-only">Loading...</span>
              </div> : ""}
                Pay Now
              </button>
            </div> 
          </div>
        </div>  
    </>)
};

export default FeaturePaymentCardMdl;