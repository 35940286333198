import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/grid";
import {
  Grid,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import pdfimg from "../../../assets/images/pdf.png";
import Lightbox from "react-image-lightbox";

const EventScheduleImageDocumentShow = ({ events_shedule, baseUrls, setPdfFile }) => {
  const [images, setImages] = useState([]);
  const [documents, setdocuments] = useState([]);

  const acceptedMimeTypes = ["pdf", "doc", "docx", "xls", "xlsx", "txt"];

  const acceptedImageMimeTypes = [
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg+xml",
    "webp",
    "jpg"
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const ImageGallery = images?.map(
    (item) => `${baseUrls?.event_schedule_document_url}/${item?.file}`
  );

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };


  useEffect(() => {
    const events_shedule_docs = events_shedule?.filter((template) =>
      acceptedMimeTypes?.includes(template?.mime_type)
    );
    const events_shedule_imgs = events_shedule?.filter((template) =>
      acceptedImageMimeTypes?.includes(template?.mime_type)
    );
    setdocuments(events_shedule_docs);
    setImages(events_shedule_imgs);
  }, [events_shedule]);

  const breakpoints = {
    300: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    500: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1600: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  };

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={ImageGallery[photoIndex]}
          nextSrc={ImageGallery[(photoIndex + 1) % ImageGallery?.length]}
          prevSrc={ImageGallery[(photoIndex + ImageGallery?.length - 1) % ImageGallery?.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + ImageGallery?.length - 1) % ImageGallery?.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % ImageGallery?.length)
          }
        />
      )}

      {documents?.length > 0 &&
        <div>
          <div className="d-flex align-items-center gap-2">
            <h2 className="social-child-heading m-0">Documents</h2>
          </div>
          <div className="event-gellary-document-normal-mode">
            <div className="mt-3 pdf-main-div ms-0">
              <Swiper
                spaceBetween={30}
                //slidesPerView={4}
                breakpoints={breakpoints}
                modules={[Navigation, Grid]}
                navigation={true}
                loop={false}
                grid={{
                  rows: 3,
                  fill: "row",
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                className="mySwiper">
                {/* cursor-pointer */}
                {
                  documents?.map((val, index) => {
                    // console.log("valll",val)
                    return (
                      <SwiperSlide key={index}>
                        <div className="p-0 ">
                          <div className="pdf-div" role="button"
                            onClick={() => setPdfFile({ filename: val?.file, fileType: "schedule" })}>
                            <img
                              src={pdfimg}
                              alt="pdf"

                            />
                            <p className="pdf-title">{val?.file}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>


          <div className="mt-3 event-gellary-document-print-mode">
            {documents?.map((val, index) => {
              return (
                <div className="document-item-print p-0 cursor-pointer" key={index}>
                  <div className="pdf-div" role="button">
                    <img
                      src={pdfimg}
                      alt="pdf"
                    />
                    <p className="pdf-title">
                      {`${baseUrls?.event_schedule_document_url}/${val?.file} `}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

        </div>
      }

      {images?.length > 0 &&
        <div>
          <div className="d-flex align-items-center gap-2 mt-3">
            <h2 className="social-child-heading m-0">Images</h2>
          </div>
          <div className="event-gallery-image-normal-mode mt-3 ms-0">
            <Swiper
              spaceBetween={30}
              //slidesPerView={4}
              breakpoints={breakpoints}
              modules={[Navigation, Grid]}
              navigation={true}
              loop={false}
              grid={{
                rows: 3,
                fill: "row",
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              className="mySwiper">
              {/* cursor-pointer */}
              {
                images?.map((val, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="p-0" onClick={() => openLightbox(index)} role="button">
                        <img
                          className="event-gellary-img"
                          role="button"
                          src={`${baseUrls?.event_schedule_document_url}/${val?.file}`}
                          alt="img1"
                        />
                        {/* <p className="pdf-title">{val?.file}</p> */}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>

          <div className="event-gallery-image-print-mode mt-3">
            {images?.map((val, index) => {
              return (
                <div className="p-0" key={index} >
                  <img
                    className="event-gellary-img"
                    role="button"
                    src={`${baseUrls?.event_schedule_document_url}/${val?.file}`}
                    alt="img1"
                  />
                </div>
              );
            })}
          </div>

        </div>
      }
    </>
  );
};

export default EventScheduleImageDocumentShow;
