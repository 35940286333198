
import { useState, useEffect } from 'react'
import arrow from "../../assets/images/arrow2.svg";
import { useSelector } from "react-redux";
import { predefinePackage } from '../../utils/packagesform/packagesFormManager';
import { MdDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import ReactQuill from 'react-quill';
import { CreatePastEventSponsorPackage, DeletePastEventPackage, GetPastEventPackagesData, GetPastEventSponsorShipPackage, PastEvent_Update_SponsorPackage } from '../../utils/createPastEventForm/createpasteventform';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import CreatableSelect from 'react-select/creatable';

const EditPastEventSponsorPackage = ({ id, setTabValue, Messages }) => {
    const [predefinePackageList, setPredefinePackageList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(3);
    const [createdPackages, setCreatePackages] = useState([])
    const [showDltModal, setShowDltModal] = useState(false);
    const [package_id, setPackage_id] = useState('')
    const [updateCheck, setUpdateCheck] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const allConfingData = useSelector((state) => state.config_data.configData);
    const [formData, setFormData] = useState({
        title: "",
        available_packages: "",
        purchase_package: "",
        reguler_offers: "",
        package_inclusions: "",
        amount: "",
        currency_id: "",
        // others: ""
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'amount') {
            const unformattedValue = value?.replace(/[^\d]/g, '');
            setFormData({
             ...formData,
             [name]: unformattedValue
            });
        } else {
            setFormData({
             ...formData,
             [name]: value
            });
        }
    }

    const formatNumber = (value) => {
        return (value?.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    };

    const handlePackageInclusions = (value) => {
        const text = value?.replace(/<[^>]*>?/gm, '');
        if (text.length <= 500) {
            setFormData((prevState) => ({
                ...prevState,
                package_inclusions: value
            }));
            setErrors({
                ...errors,
                package_inclusions: ""
            });
        } else {
            setErrors({
                ...errors,
                package_inclusions: "Package Inclusions cannot exceed 500 characters."
            });
        }
    }

    const handleChangeCurrencySymbol = (e) => {
        const item = e.target.value
        setSelectedCurrencySymbol(item)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true)
            if (updateCheck) {
                try {
                    const response = await PastEvent_Update_SponsorPackage(package_id, formData);
                    if (response?.data?.success) {
                        Messages(response?.data?.message);
                        setUpdateCheck(false)
                        getPredefineData()
                        setFormData({
                            title: "",
                            package_inclusions: "",
                            available_packages: "",
                            purchase_package: "",
                            amount: "",
                            currency_id: 3,
                            reguler_offers: ""
                        })
                        setSelectedOption(null)
                    }
                } catch (err) {
                    console.log("err", err);
                }
            } else {
                try {
                    const response = await CreatePastEventSponsorPackage(formData, id);
                    if (response?.data?.success) {
                        Messages(response?.data?.message);
                        getPredefineData();
                        setFormData({
                            title: "",
                            package_inclusions: "",
                            available_packages: "",
                            purchase_package: "",
                            amount: "",
                            currency_id: 3,
                            reguler_offers: ""
                        })
                        setSelectedOption(null)
                    }
                } catch (err) {
                    console.log("err", err);
                }
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        setFormData({ ...formData, currency_id: selectedCurrencySymbol })
    }, [selectedCurrencySymbol])


    const getPredefineData = async () => {
        try {
            await predefinePackage().then((response) => {
                setPredefinePackageList(
                    response?.data?.map((item) => {
                        return {
                            value: item?.id,
                            label: item?.name,
                        };
                    }));
            });
        } catch (err) {
            console.log(err);
        }

        try {
            GetPastEventSponsorShipPackage(id)
                .then((response) => {
                    const data = response?.data
                    setCreatePackages(data?.data?.past_events_packages)
                })
        } catch (errr) {
            console.log(errr)
        }
    }

    useEffect(() => {
        getPredefineData();
    }, [])


    const handleDeletePackage = (id) => {
        setPackage_id(id)
        setShowDltModal(true)
    }

    const handleDelete = async () => {
        try {
            await DeletePastEventPackage(package_id)
                .then((result) => {
                    if (result?.data?.success) {
                        Messages(result?.data?.message)
                        setShowDltModal(false);
                        getPredefineData();
                    } else {
                        Messages(result?.data?.message)
                        setShowDltModal(false);
                    }
                })
        } catch (errr) {
            console.log(errr)
        }
    };


    const handleEditPackage = async (id) => {
        setPackage_id(id)
        setUpdateCheck(true)
        try {
            await GetPastEventPackagesData(id)
                .then((response) => {
                    if (response?.data?.success) {
                        const value = response?.data?.data
                        setFormData(value)
                        setSelectedOption({
                            value: value?.title,
                            label: value?.title,
                        });
                        setSelectedCurrencySymbol(value?.currency_id)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    const validate = () => {
        const errors = {};
        if (!formData.title) {
            errors.title = 'Package Name is required';
        }

        if (!formData.available_packages) {
            errors.available_packages = 'Available Package is required';
        }

        if (!formData.amount || isNaN(formData.amount) || formData.amount <= 0) {
            errors.amount = 'Regular Offer Price is required';
        }

        if (!formData.purchase_package) {
            errors.purchase_package = 'Purchase Package is required';
        }

        const packagedetail = formData.package_inclusions?.replace(/<p><br><\/p>/g, '')
        if (!packagedetail) {
            errors.package_inclusions = "Package Inclusions is required";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // rect quill length count
    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
    
        let textWithNewlines = div.innerHTML
    
        if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
          textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
        } else {
          // Otherwise, apply replacements
          textWithNewlines = textWithNewlines
            .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
        }
    
        div.innerHTML = textWithNewlines;
        return div.textContent || div.innerText || "";
      };

    const getDescriptionLength = () => {
        const cleanDescription = stripHtmlTags(formData.package_inclusions);
        return cleanDescription.length;
    };

    const maxLength = 500;
    const currentLength = getDescriptionLength();
    const remainingLength = maxLength - currentLength;
    //

    const handlePackageName = (option) => {
        setSelectedOption(option);
        setFormData({ ...formData, title: option?.label })
    };

    return (
        <>
            <div className="basicInfo">
                <div className="d-flex align-items-center gap-1 position-relative">
                    <p className="createevent-head-p">
                        Share what sponsors will gain by supporting your event. Describe the benefits and perks they’ll receive, making it clear why sponsoring your event is a great opportunity.
                    </p>
                    {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Sponsorship Package</h2>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <IoMdInformationCircleOutline size={23} />
                    </div>
                    {isHovered && (
                        <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <p className="info-popup-text">
                                Share what sponsors will gain by supporting your event. Describe the benefits and perks they’ll receive, making it clear why sponsoring your event is a great opportunity.
                            </p>
                        </div>
                    )} */}
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <form onSubmit={handleSubmit}>

                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label className="form-label">Package Name<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="Enter sponsorship package name" />
                                    <CreatableSelect
                                        value={selectedOption}
                                        onChange={handlePackageName}
                                        options={predefinePackageList}
                                        placeholder="Select package or create an option"
                                        isClearable
                                    />
                                    {errors.title && (
                                        <span className="error">{errors.title}</span>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label className="form-label">Available Package<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="List how many of these packages are available for purchase." />

                                    <input
                                        type="number"
                                        min="1"
                                        value={formData.available_packages}
                                        name="available_packages"
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder="Available Package"
                                    />
                                    {errors.available_packages && (
                                        <span className="error">{errors.available_packages}</span>
                                    )}
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="form-label">Purchase Package<span className="asterisk">*</span></label>
                                    <input
                                        type="number"
                                        min="1"
                                        value={formData.purchase_package}
                                        name="purchase_package"
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder="Purchase Package"
                                    />
                                    {errors.purchase_package && (
                                        <span className="error">{errors.purchase_package}</span>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-6" style={{ position: "relative" }}>
                                    <label className='form-label'>Regular Offer Price<span className="asterisk">*</span></label>
                                    <IoMdInformationCircleOutline size={16} color="black" title="Details of the standard investment of this package" />
                                    <input
                                        type="text"
                                        value={formatNumber(formData?.amount)}
                                        name='amount'
                                        className="form-control"
                                        placeholder="Regular Offer Price"
                                        onChange={handleChange}
                                    />
                                    <select className="currency_tag" name="currency_id" value={selectedCurrencySymbol} onChange={handleChangeCurrencySymbol}>
                                        {allConfingData?.currency_list?.map((val) => {
                                            return (
                                                <option key={val?.id} value={val?.id}>{val?.code} {val?.symbol}</option>
                                            )
                                        })}
                                    </select>
                                    {errors?.amount && <span className="error">{errors?.amount}</span>}
                                </div>
                            </div>


                            <div className="row">
                                <div className="form-group col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label mb-0">
                                            Package Inclusions<span className="asterisk">*</span>
                                            {/* <IoMdInformationCircleOutline size={16} color="black" title="List what all is included in this sponsorship package" /> */}
                                        </label>
                                        <p className="Character-0500">
                                            Character:<span className="text-style-1">
                                                {/* remainingLength */}
                                                {currentLength}/{maxLength}
                                            </span>
                                        </p>
                                    </div>
                                    <p className="reactquill-tooltip-p mb-1">(Note: List what all is included in this sponsorship package)</p>
                                    <div style={{ height: '200px' }}>
                                        <ReactQuill
                                            value={formData?.package_inclusions}
                                            onChange={handlePackageInclusions}
                                            theme='snow'
                                            style={{ height: '150px' }}
                                            placeholder='Package Inclusions'
                                        />
                                    </div>
                                    {errors.package_inclusions && (
                                        <span className="error">{errors.package_inclusions}</span>
                                    )}
                                </div>
                            </div>



                            <div className="d-flex justify-content-md-end align-items-center mt-3">
                                <div className="d-flex flex-column flex-md-row gap-2">
                                    <button
                                        className="d-flex align-items-center gap-1+ saveandcontinue-btn"
                                        type="submit">
                                        {loading ? (
                                            <div
                                                className="spinner-border text-light "
                                                role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        Save
                                        <img className="arrow-img" src={arrow} alt="arrow" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {createdPackages?.length > 0 &&
                        <div className="col-lg-5 mt-3 mt-md-0">
                            <div className="sidemenu p-2">
                                {createdPackages?.map((val) => {
                                    return (
                                        <div className="input-group d-flex align-items-center donation-input py-0">
                                            <div
                                                type="text"
                                                className="donation-package-input form-control">
                                                <span className="package-title-text">{val?.title}</span>
                                                <br />
                                                <span className=" select-pack-text">
                                                    {val?.currencies?.symbol} {val?.amount}
                                                </span>
                                            </div>
                                            <button
                                                className="edit-btn"
                                                onClick={() => handleEditPackage(val?.id)}
                                                title="Edit"
                                            >
                                                <MdOutlineEdit size={20} />
                                            </button>
                                            <button
                                                className="delete-btn"
                                                onClick={() => handleDeletePackage(val?.id)}
                                                title="Delete"
                                            >
                                                <MdDeleteOutline size={20} />
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-md-end align-items-center mt-3">
                        <div className="d-flex flex-column flex-md-row gap-2">
                            {createdPackages?.length > 0 &&
                                <button
                                    className="d-flex align-items-center gap-1 saveandcontinue-btn"
                                    type="button" onClick={() => setTabValue("Event-Media-Hub")}>
                                    Save and Continue
                                    <img className="arrow-img" src={arrow} alt="arrow" />
                                </button>

                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* <DeleteConfirmation setShowDltModal={setShowDltModal} showDltModal={showDltModal} package_id={package_id} getPredefineData={getPredefineData} /> */}

            {showDltModal &&
                <div
                    className="modal main-model-div fade show d-block"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModal"
                >
                    <div className="modal-dialog modal-confirm">
                        <div className="modal-content">
                            <div className="modal-header flex-column">
                                <div className="icon-box">
                                    <i className="material-icons">&chi;</i>
                                </div>
                                <h4 className="modal-title w-100">Are you sure?</h4>
                            </div>
                            <div className="modal-body">
                                <p>Do you really want to delete this record? This process cannot be undone.</p>
                            </div>
                            <div className=" justify-content-center">
                                <button
                                    type="button"
                                    className="btn delete-event-btn "
                                    onClick={() => setShowDltModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn saveandcontinue-btn"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default EditPastEventSponsorPackage

