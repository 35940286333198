import { IoClose } from "react-icons/io5";
import EventsCard from "../../Events/EventsCard";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";


const EventCuttoffDateModifyPopup = ({event,setEvent_Data,baseUrls,publishMdl,
  setPublishMdl,setNearByCuttoffDateMdl}) => {

    const breakpoints = {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        500: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1800: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
      };

    const handleModifyEvent=(val)=>{
     setEvent_Data({ ...val, event_status:"modify", publish_type:"modify" });
     setPublishMdl(!publishMdl);
    }

    const handleSkipNearByCuttoffMdl=(data)=>{
     localStorage.setItem('near_by_cuttoff_date_mdl','is_skip');      
     setNearByCuttoffDateMdl(false);

     const ids = data?.map((val)=>{
      return val?.id
     });
     const StringifyId = JSON.stringify(ids);   
     localStorage.setItem('near_by_cuttoff_date_id',StringifyId); 
     // localStorage.setItem('near_by_cuttoff_date_id',StringifyId);
    }
     
    return (<>
     <div className="warningModal">
        <div className="eventcuttoffdatemodify-mdl position-relative">
        <h5 className="text-capitalize">Near By Cuttoff Date</h5>
          <button
            className="terms-mdl-close-btn"
            onClick={() => setNearByCuttoffDateMdl(false)}>
            <IoClose size={24}/>
          </button>
           <hr className="w-100 m-0"/>
           <div className="eventcuttoffdatemodify-content mt-3">
           <div className="mt-3">
            <Swiper
              className=""
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              //spaceBetween={20}
              //slidesPerView={5}
              breakpoints={breakpoints}
              navigation
              loop={false}
              effect="fade"
              speed={500}>
              <div className="even-card-div">
               {
                event && event?.map((val) => {
                 return (
                   <SwiperSlide key={val?.id}>
                    <EventsCard baseUrls={baseUrls} val={val} />  
                      <button className="change-cuttoffDate-btn mt-1" onClick={()=>handleModifyEvent(val)}>Change Cuttoff Date</button>
                   </SwiperSlide>
                )})
               }
              </div>
            </Swiper>
           </div>
          </div>

          <div className="d-flex justify-content-end gap-3 mt-3">
               <button
                className="mdl-cancel-btn"
                onClick={()=>handleSkipNearByCuttoffMdl(event)}>
                 Skip
              </button>
              <button
               className="warning-mdl-btn "
               onClick={()=>setNearByCuttoffDateMdl(false)}>
                Close
              </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCuttoffDateModifyPopup;
