
import React, { useState,useEffect} from 'react';
import { IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';



const CreateEventExpressAndAdvancedMdl=({setShowExpressAndAdvancedMdl,advanceBenefit,expressBenefit})=>{
    const navigate = useNavigate();  

    const handleCreateExpressEvent=()=>{
      navigate('/createexpressevent/create')
    }

    const handleCreateEvent=()=>{
      navigate('/CreateEvent/create')
    }
   
    return(<>
     <div className="warningModal">
      <div className="CreateEventExpressAndAdvancedMdl position-relative" >
      <div className='d-flex justify-content-center align-items-center'>
      <h3 className="text-center terms-head-text">Create Event With</h3> 
      <div>
       <button className='terms-mdl-close-btn' 
        onClick={()=>setShowExpressAndAdvancedMdl(false)}>
        <IoClose color='black' size={24}/>
        </button>
      </div>
      </div>
      
        <div className="CreateEventExpressAndAdvanced-content">
          <div className="row">
            <div className="col-md-6">
              <div className="ExpressAndAdvanced-div position-relative">
                <h4 className="ExpressAndAdvanced-head">Express</h4>
                <hr className='w-100'/>
                <div className='CreateEventExpressAndAdvanced-p-div'>
                 {
                  expressBenefit?.map((val)=>{
                   return(<>
                    <p className="CreateEventExpressAndAdvanced-p"><b>{val?.title}:</b> {val?.description}</p>                    
                   </>)
                  })
                 } 
                </div>
                <div className="d-flex gap-2 justify-content-center">
                 <button className="CreateEventExpressAndAdvanced-btn mt-3" onClick={handleCreateExpressEvent}>
                   Proceed
                 </button>
                </div> 
             </div>
            </div>

            <div className="col-md-6 mt-3 mt-md-0">
             <div className="ExpressAndAdvanced-div position-relative">
                <h4 className="ExpressAndAdvanced-head">Advanced</h4>
                <hr className='w-100'/>
                <div className='CreateEventExpressAndAdvanced-p-div'>
                {
                  advanceBenefit?.map((val)=>{
                   return(<>
                    <p className="CreateEventExpressAndAdvanced-p"><b>{val?.title}:</b> {val?.description}</p>                    
                   </>)
                  })
                 } 
                </div>
                <div className="d-flex align-items-bottom justify-content-center ">
                 <button className="CreateEventExpressAndAdvanced-btn mt-3" onClick={handleCreateEvent}>
                   Proceed
                 </button>
                </div> 
             </div>
            </div>
          </div>
       </div>
     </div>
   </div>  
   </>)
  };
export default CreateEventExpressAndAdvancedMdl;