import arrow from "../../assets/images/arrow2.svg";
import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { EventReference } from "../../utils/CreateEvents/createEvent";
import PhoneInput from "react-phone-input-2";
import { Get_Edit_Event_Reference } from "../../utils/EditEvent/editEvent";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMdInformationCircleOutline } from "react-icons/io";

const EventReferences = ({
  event_id,
  setTabValue,
  Messages,
  isEdit,
  Eid,
  tabValue,
}) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isEditForm, setIsEditForm] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    mobile_no: "",
    country_code: "",
    user_type: "",
    relationship: "",
    profession: "",
    location: "",
  });

  const [errors, setErrors] = useState({});

  const gridRef = useRef();

  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Reference Name is required";
      valid = false;
    }

    // if (!formData.email) {
    //     newErrors.email = "Reference Email is required";
    //     valid = false;
    // }

    if (formData.email !== "") {
      if (!/\S+@\S+\.\S+/.test(formData?.email)) {
        newErrors.email = "Invalid Email address.";
        valid = false;
      }
    }

    if (!formData.mobile_no) {
      newErrors.mobile_no = "Reference Mobile No. is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.mobile_no)) {
      newErrors.mobile_no = "Invalid mobile number.";
    }

    if (!formData.location) {
      newErrors.location = "Reference Location is required";
      valid = false;
    }
    if (!formData.user_type) {
      newErrors.user_type = "Reference For is required";
      valid = false;
    }

    // if (!input.partner_number) {
    //   newErrors.user_type = "Reference For is required";
    //   valid = false;
    // }


    // if (formData.email !== "") {
    //   if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //     newErrors.email = "Invalid Email address."
    //     valid = false;
    //   }
    // }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    if (isEdit && tabValue == "References") {
      try {
        Get_Edit_Event_Reference(Eid).then((response) => {
          if (response?.data?.data?.length > 0) {
            setRowData(response?.data?.data);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const handleAddRow = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const index = rowData.findIndex((row) => row.id === formData.id);
      if (isEditForm) {
        // Update existing item
        const updatedRowData = [...rowData];
        updatedRowData[index] = formData;
        setRowData(updatedRowData);
        setFormData({
          id: "",
          name: "",
          email: "",
          mobile_no: "",
          country_code: +91,
          user_type: "",
          relationship: "",
          profession: "",
          location: "",
        });
        setIsEditForm(false);
        setErrors({});
      } else {
        const newId = new Date().getTime().toString();
        const newItem = { ...formData, id: newId };
        gridRef.current.applyTransaction({ add: [newItem] });
        setRowData((prevData) => [...prevData, newItem]);
        setFormData({
          id: "",
          name: "",
          email: "",
          mobile_no: "",
          country_code: +91,
          user_type: "",
          relationship: "",
          profession: "",
          location: "",
        });
        setErrors({});
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { new_reference: rowData };
    const DataStringfy = JSON.stringify(data);

    if (rowData?.length >= 5) {
      setBtnLoading(true)
      try {
        // event_id
        const response = await EventReference(Eid, DataStringfy);
        if (response?.data?.success) {
          Messages(response?.data?.message);
          navigate(`/preview/${Eid}`, { state: "Create Event" });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setBtnLoading(false)
      }
    } else {
      toast.warn("Minimum 5 references required", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const handlePhoneNumberChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setFormData({
      ...formData,
      mobile_no: `${phoneNum}`,
      country_code: `${d_code}`,
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handledeletePartnerShip = async (data) => {
    const deletedRow = rowData?.filter((item) => item.id !== data.id);
    setRowData(deletedRow);
  };

  const handleEditPartnerShip = async (data) => {
    setFormData(data);
    setIsEditForm(true);
  };

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Name",
        field: "name",
        // cellRenderer: AvatarRenderer,
        // filter: false,
      },
      {
        headerName: "Mobile No.",
        field: "mobile_no",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Email",
        field: "email",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Reference RelationShip",
        field: "relationship",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Reference Profession",
        field: "profession",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Reference Location",
        field: "location",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Reference For",
        field: "user_type",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Action",
        field: "id",
        cellRenderer: ({ val, data }) => {
          return (
            <div className="d-flex gap-2">
              <button
                className="create-Date-Time"
                type="button"
                onClick={() => handleEditPartnerShip(data)}>
                Edit
              </button>
              <button
                className="create-Date-Time"
                type="button"
                onClick={() => handledeletePartnerShip(data)}>
                Remove
              </button>
            </div>
          );
        },
      },
    ]);
  }, [rowData]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className="basicInfo">
        <div className="d-flex align-items-center gap-1 position-relative">
          <p className="createevent-head-p">
            Please share contacts who might also benefit from using our
            platform, just like you do. For each successful event hosting
            and sponsorship that comes through your referrals, we'll offer
            surprise gifts as a token of our appreciation!
          </p>
          {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">References</h2>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <IoMdInformationCircleOutline size={23} />
          </div>
          {isHovered && (
            <div
              className="info-popup"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <p className="info-popup-text">
                Please share contacts who might also benefit from using our
                platform, just like you do. For each successful event hosting
                and sponsorship that comes through your referrals, we'll offer
                surprise gifts as a token of our appreciation!
              </p>
            </div>
          )} */}
        </div>
        <div className="">
          <form onSubmit={handleSubmit}>
            <form className="event-partnerShip-form">
              <div className="row">
                <div className="form-group col-md-4">
                  <label className="form-label">
                    Reference Name<span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData?.name}
                    name="name"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Reference Name"
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>

                <div className="form-group col-md-4">
                  <label className="form-label">
                    Reference Mobile No.<span className="asterisk">*</span>
                  </label>
                  <PhoneInput
                    className=""
                    country={"in"}
                    name="mobile_no"
                    value={`${formData?.country_code}${formData?.mobile_no}`}
                    onChange={handlePhoneNumberChange}
                  />
                  {errors.mobile_no && (
                    <span className="error">{errors.mobile_no}</span>
                  )}
                </div>

                <div className="form-group col-md-4">
                  <label className="form-label">
                    Reference Email<span className="asterisk"></span>
                  </label>
                  <input
                    type="email"
                    value={formData?.email}
                    name="email"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Reference Email"
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="form-group col-md-4">
                  <label className="form-label">
                    Reference Relationship<span className="asterisk"></span>
                  </label>
                  <input
                    type="text"
                    value={formData?.relationship}
                    name="relationship"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Reference Relationship"
                  />
                  {errors.relationship && (
                    <span className="error">{errors.relationship}</span>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <label className="form-label">
                    Reference Profession<span className="asterisk"></span>
                  </label>
                  <input
                    type="text"
                    value={formData?.profession}
                    name="profession"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Reference Profession"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="form-label">
                    Reference Location<span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData?.location}
                    name="location"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Reference Location"
                  />
                  {errors.location && (
                    <span className="error">{errors.location}</span>
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-4">
                  <label className="form-label">
                    Reference for -<span className="asterisk">*</span>
                  </label>

                  <div className="d-flex gap-4">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="user_type"
                          value="Sponsor"
                          checked={formData?.user_type == "Sponsor"}
                          onChange={handleChange}
                        />
                        Sponsor
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="user_type"
                          value="Host"
                          checked={formData?.user_type == "Host"}
                          onChange={handleChange}
                        />
                        Host
                      </label>
                    </div>
                  </div>
                  {errors.user_type && (
                    <span className="error">{errors.user_type}</span>
                  )}
                </div>

                <div className="col-md-8"></div>
              </div>

              <div className="d-flex mt-3">
                <button
                  className="clear-filter-button"
                  type="button"
                  onClick={handleAddRow} >
                  Save
                </button>
              </div>
            </form>
            <div className="row mt-4">
              <div
                className="ag-theme-alpine"
                style={{ height: 300, width: "100%" }}>
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  rowSelection="multiple"
                ></AgGridReact>
              </div>
            </div>
            <div className="d-flex justify-content-md-end align-items-center mt-3 gap-2">
            <div className="d-flex flex-column flex-md-row gap-3">
              <div>
              {Eid !== 'create' &&
                <button
                  className="d-flex align-items-center gap-1 saveandcontinue-btn"
                  type="button"
                  onClick={() => navigate(`/preview/${Eid}`, { state: "Create Event" })}>
                  Save for Later
                </button>
              }
              </div>
              <div className="d-flex flex-column flex-md-row gap-2">
                <button
                  className="d-flex align-items-center gap-1 saveandcontinue-btn"
                  type="submit" >
                  {btnLoading ? (
                    <div
                      className="spinner-border text-light "
                      role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                  Submit for Review
                  <img className="arrow-img" src={arrow} alt="arrow" />
                </button>
              </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default EventReferences;
