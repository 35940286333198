import { useState, useEffect, useRef } from 'react';
import arrow from "../../assets/images/arrow2.svg";
import { RefundCharge } from '../../utils/CreateEvents/createEvent';
import { ToastContainer, toast } from "react-toastify";
import { Get_Edit_Event_Cancel_Refund } from '../../utils/EditEvent/editEvent';
import { IoMdInformationCircleOutline } from 'react-icons/io';

const EditEventCancellationRefund = ({ setTabValue, id, Messages }) => {
    const [cancellationType, setCancellationType] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        percentage_100: "",
        percentage_75: "",
        percentage_50: "",
        percentage_25: "",
        percentage_0: ""
    });

    const refs = {
        percentage_100: useRef(null),
        percentage_75: useRef(null),
        percentage_50: useRef(null),
        percentage_25: useRef(null),
        percentage_0: useRef(null)
    };

    const [errors, setErrors] = useState({});


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const GetEditData = async () => {
        try {
            await Get_Edit_Event_Cancel_Refund(id)
                .then((response) => {
                    const data = response?.data?.data
                    if (data != null) {
                        setCancellationType(data?.refund)
                        setFormData(data)
                    }
                })
        } catch (errr) {
            console.log(errr)
        }
    }

    useEffect(() => {
        GetEditData()
    }, [])


    const handleInputChange = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (cancellationType == '1') {
            if (validate()) {
                setLoading(true)
                const FData = { ...formData, refund: cancellationType }
                try {
                    const response = await RefundCharge(id, FData)
                    if (response?.data?.success) {
                        setTabValue("References")
                        Messages(response?.data?.message);
                    }
                } catch (err) {
                    console.log("err", err)
                }
                setLoading(false)
            }
        } else {
            const FData = { ...formData, refund: cancellationType }
            setLoading(true)
            try {
                const response = await RefundCharge(id, FData)
                if (response?.data?.success) {
                    setTabValue("References")
                    Messages(response?.data?.message);
                }
            } catch (err) {
                console.log("err", err)
                Messages(err?.response?.data?.message);
            }
            setLoading(false)
        }
    }

    const validate = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.percentage_100 || isNaN(formData.percentage_100)) {
            tempErrors.percentage_100 = "This field is required";
            isValid = false;
        }
        if (!formData.percentage_75 || isNaN(formData.percentage_75)) {
            tempErrors.percentage_75 = "This field is required";
            isValid = false;
        }
        if (!formData.percentage_50 || isNaN(formData.percentage_50)) {
            tempErrors.percentage_50 = "This field is required";
            isValid = false;
        }
        if (!formData.percentage_25 || isNaN(formData.percentage_25)) {
            tempErrors.percentage_25 = "This field is required";
            isValid = false;
        }
        if (!formData.percentage_0 || isNaN(formData.percentage_0)) {
            tempErrors.percentage_0 = "This field is required";
            isValid = false;
        }
        setErrors(tempErrors);
        return isValid;
    };

    // const validateFormData = (data) => {

    //     const errors = {};
    //     Object.keys(data).forEach(key => {
    //         const value = data[key];
    //         if (value === "" || isNaN(value)) {
    //             errors[key] = "This field is required";
    //         } else if (value < 0 || value > 100) {
    //             errors[key] = "This field must be between 0 and 100";
    //         }
    //     });
    //     return errors;
    // };


    // useEffect(() => {
    //     const firstErrorKey = Object.keys(errors)[0];
    //     if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey].current) {
    //         refs[firstErrorKey].current.focus();
    //     }
    // }, [errors]);

    return (
        <>
            <ToastContainer />
            <div className="basicInfo">
                {/* <h2 className="basicinfo-head">Cancellation Refund</h2> */}
                <div className="d-flex align-items-center gap-1 position-relative">
                    <p className="createevent-head-p">Cancellation period field is required on all Event pages where the same is provided by the Event host. After this date the cancellation would be chargeable. This would override on the platform general terms and conditions if mentioned here.
                    </p>
                    {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Cancellation Refund</h2>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <IoMdInformationCircleOutline size={23} />
                    </div>
                    {isHovered && (
                        <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <p className="info-popup-text">Cancellation period field is required on all Event pages where the same is provided by the Event host. After this date the cancellation would be chargeable. This would override on the platform general terms and conditions if mentioned here.
                            </p>
                        </div>
                    )} */}
                </div>
                <div className="row">

                    <div>
                        <p>Dear Sponsors,</p>
                        <p className="m-0 p-0">We understand that unforeseen circumstances may arise,
                            necessitating changes to your sponsorship plans. Please take note of our cancellation policy to ensure a smooth experience:</p>
                    </div>


                    <form onSubmit={handleSubmit}>
                        <div className='mt-4'>
                            <div className="d-flex gap-3">
                                <div className="form-check">
                                    <label className="form-check-label m-0">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value={1}
                                            checked={cancellationType == 1}
                                            name="cancellationType"
                                            onChange={(e) => setCancellationType(e?.target?.value)}
                                        />
                                        Free Cancellation Policy</label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label m-0">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value={0}
                                            checked={cancellationType == 0}
                                            name="cancellationType"
                                            onChange={(e) => { setCancellationType(e?.target?.value); setErrors({}) }}
                                        />
                                        Non-Refundable Policy</label>
                                </div>
                            </div>
                        </div>

                        {
                            cancellationType == "0" ?
                                <div className='mt-3'>
                                    <p className="">All payments made towards event sponsorship are non-refundable.</p>
                                    <p className="m-0">Thank you for your understanding and cooperation. For any inquiries or assistance, please reach out to us directly. </p>
                                    <p className="m-0">Please refer SponsorRingUs Refund and Cancellation Policy for more details.</p>
                                </div>
                                :
                                <div className="mt-3">

                                    <p className="m-0">Sponsors can cancel event sponsorships at any time.
                                        <br />Brand promotion activities begin upon finalization of sponsorship.<br />
                                        Refunds are subject to host discretion and the progress of promotional activities.</p>
                                </div>
                        }

                        {
                            cancellationType == '1' &&
                            <div className="row gap-3 mt-3">
                                <h4 className="form-heading"> Cancellation Terms</h4>
                                <div className="d-flex">
                                    <p className="cancellation-p-text">Within</p>
                                    <div className='d-flex flex-column'>
                                        <input
                                            type="number"
                                            min='0'
                                            value={formData?.percentage_100}
                                            name="percentage_100"
                                            onChange={handleInputChange}
                                            className="cancellation-input"
                                            placeholder="Enter Number of Days"
                                            ref={refs?.percentage_100}
                                        />
                                        {errors.percentage_100 && (
                                            <span className="error ms-2">{errors.percentage_100}</span>
                                        )}
                                    </div>
                                    {/* <p>days prior to the event: <b>0%</b> refund.</p> */}
                                    <p>day(s) - No charges, <b>100%</b> refund.</p>                                    
                                </div>

                                <div className="d-flex">
                                    <p className="cancellation-p-text">Within </p>
                                    <div className='d-flex flex-column'>
                                        <input
                                            type="number"
                                            min='0'
                                            value={formData.percentage_75}
                                            name="percentage_75"
                                            onChange={handleInputChange}
                                            className="cancellation-input"
                                            placeholder="Enter Number of Days"
                                            ref={refs?.percentage_75}
                                        />
                                        {errors.percentage_75 && (
                                            <span className="error ms-2">{errors.percentage_75}</span>
                                        )}
                                    </div>
                                    {/* <p> days prior to the event: <b>75%</b> charged.</p> */}
                                    <p> day(s) - <b>25%</b> charged, <b>75%</b> refund</p>
                                </div>

                                <div className="d-flex">
                                    <p className="cancellation-p-text">Within</p>
                                    <div className='d-flex flex-column'>
                                        <input
                                            type="number"
                                            min='0'
                                            value={formData.percentage_50}
                                            name="percentage_50"
                                            onChange={handleInputChange}
                                            className="cancellation-input"
                                            placeholder="Enter Number of Days"
                                            ref={refs?.percentage_50}
                                        />
                                        {errors.percentage_50 && (
                                            <span className="error ms-2">{errors.percentage_50}</span>
                                        )}
                                    </div>
                                    {/* <p> days prior to the event: <b>50%</b> charged.</p> */}
                                    <p> day(s) - <b>50%</b> charged, <b>50%</b> refund</p>
                                </div>

                                <div className="d-flex">
                                    <p className="cancellation-p-text">Within </p>
                                    <div className='d-flex flex-column'>
                                        <input
                                            type="number"
                                            min='0'
                                            value={formData?.percentage_25}
                                            name="percentage_25"
                                            onChange={handleInputChange}
                                            className="cancellation-input"
                                            placeholder="Enter Number of Days"
                                            ref={refs?.percentage_25}
                                        />
                                        {errors.percentage_25 && (
                                            <span className="error ms-2">{errors.percentage_25}</span>
                                        )}
                                    </div>
                                    {/* <p> days prior to the event: <b>25%</b> charged.</p> */}
                                    <p> day(s) - <b>75%</b> charged, <b>25%</b> refund</p>
                                </div>

                                <div className=" d-flex">
                                    <p className="cancellation-p-text">Within</p>
                                    <div className='d-flex flex-column'>
                                        <input
                                            type="number"
                                            min='0'
                                            value={formData?.percentage_0}
                                            name="percentage_0"
                                            onChange={handleInputChange}
                                            className="cancellation-input"
                                            placeholder="Enter Number of Days"
                                            ref={refs?.percentage_0}
                                        />
                                        {errors.percentage_0 && (
                                          <span className="error ms-2">{errors.percentage_0}</span>
                                        )}
                                    </div>
                                    {/* <p>days prior to the event: <b>100%</b> sponsorship charged, no refunds.</p> */}
                                    <p> day(s) - <b>100%</b> charged, <b>0%</b> refund</p>
                                </div>
                            </div>
                        }

                        <div className="d-flex justify-content-md-end align-items-center mt-3">
                            <div className="d-flex flex-column flex-md-row gap-2">
                                <button
                                    className="d-flex align-items-center gap-1 saveandcontinue-btn"
                                    type="submit">
                                    {loading ? (
                                        <div
                                            className="spinner-border text-light "
                                            role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                      ""
                                    )}
                                    Save and Continue
                                    <img className="arrow-img" src={arrow} alt="arrow" />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default EditEventCancellationRefund