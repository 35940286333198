
import React, {useState, useEffect} from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import upload from "../../../assets/images/upload-icon.png";
import { MdDelete } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { DeleteBanner } from "../../../utils/EditEvent/editEvent";
import DeleteImageDocWarningModal from "./DeleteImageDocWarningModal";

const BannerLogoSection=(props)=>{
    const {baseUrls, 
        formData, 
        setFormData,
        selectedBannerFiles, 
        setSelectedBannerFiles,
        errors,
        Messages,
        refs,
        EventmediaData,
        predefine_Banner,
        handleInputChange
    } = props;
    const [showDeleteImageDocModal,setShowDeleteImageDocModal]=useState(false);  
    const [deleteImageDocModal,setDeleteImageDocModal]=useState({id:"",type:"",file:""});
    
    
      const handleBannerFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedBannerFiles((prevFiles) => [...prevFiles, ...files]);
      };
    
      const handleBannerRemoveImage = (idx) => {
        const val = selectedBannerFiles?.filter((item, index) => index !== idx)
        setSelectedBannerFiles(val)
      }
    
    
      const handleRemoveBanner = async (id) => {
        try {
          const response = await DeleteBanner(id);
          Messages(response?.data?.message);
          EventmediaData();
          setShowDeleteImageDocModal(false)
        } catch (err) {
          console?.log(err)
        }
      }
    
      const handleDeleteImgDoc=(id,type,file)=>{
        setShowDeleteImageDocModal(true);
        setDeleteImageDocModal({id:id, type:type, file:file})
       }

       const deleteImgDoc=()=>{
        if(deleteImageDocModal?.file == 'banner'){
          if(deleteImageDocModal?.type == 'local'){
            handleBannerRemoveImage(deleteImageDocModal?.id)
            setShowDeleteImageDocModal(false)
          }else{
            handleRemoveBanner(deleteImageDocModal?.id)
          }
        }else{
          if(deleteImageDocModal?.type == 'local'){
            setFormData({ ...formData, logo: "" })
            setShowDeleteImageDocModal(false)
          }else{
            setFormData({ ...formData, logo: "" })
          }
        }
      }
      
      // console.log("selectedBannerFiles",selectedBannerFiles.length)
      useEffect(() => {
        const firstErrorKey = Object.keys(errors)[0];
        if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey].current) {
          refs[firstErrorKey].current.focus();
        }
      }, [errors]);
    
    
    return(<>
      {
      showDeleteImageDocModal && <DeleteImageDocWarningModal msg={deleteImageDocModal?.file} setShowDeleteImageDocModal={setShowDeleteImageDocModal}
       deleteImgDoc={deleteImgDoc}/>
     }
     
     <div className="row">
      <div className="row col-md-6">
        <div className="col-md-12">
          <p className="Bannerimg-head mb-0">
            Banner Image<span className="asterisk">*</span>
            <IoMdInformationCircleOutline size={16} color="black" title="Upload a main banner image for your event." />
          </p>
          <label className="bannerimg-div mt-2">
            <input
              className="d-none"
              type="file"
              name="banner"
              accept="image/*"
              multiple
              onChange={handleBannerFileChange}
              ref={refs?.banner}
            />
            <div className="upload-image-content">
              <img src={upload} alt="upload" />
              <p className="uploadimg-font">Upload Banner Image</p>
              <p className="img-format-text">Image format: .jpg or .png</p>
              <p className="img-format-text">Recommended size: w: 1440px, H: 600px</p>
              <div>
                {formData?.banner && (
                  <FaRegCircleCheck color="green" size={20} />
                )}
              </div>
            </div>
          </label>
          {errors?.banner && (
            <span className="error">{errors?.banner}</span>
          )}
        </div>

        <div className="col-md-12">
          {selectedBannerFiles?.length > 0 &&
            <div className="mt-2">
              <div className="banner-main-div">
                <div className="row">
                  {
                    selectedBannerFiles?.map((val, index) => {
                      return (<>
                        <div className="position-relative mt-2 col-6 d-flex justify-content-center">
                          {val instanceof File &&
                            <img
                              className="banner-image"
                              src={URL?.createObjectURL(val)}
                              alt="updateimg" />
                          }
                          <button className="image-remove-icon" onClick={()=> handleDeleteImgDoc(index,"local","banner")}>
                            <MdDelete size={20} color="white" />
                          </button>
                        </div>
                      </>)
                    })
                  }
                </div>
              </div>
            </div>
          }
          <div className="row">
            {predefine_Banner?.map((val) => {
              return (
                <>
                  <div className="position-relative mt-2 col-6 d-flex justify-content-center">
                    <img
                      className="banner-image"
                      src={`${baseUrls?.event_banner_image_url}/${val?.filename}`}
                      alt="updateimg"
                    />
                    <button
                      className="image-remove-icon"
                      onClick={()=> handleDeleteImgDoc(val?.id,"dynamic","banner")}>
                      <MdDelete size={20} color="white" />
                    </button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="row col-md-6 mt-3 mt-md-0">
      <div className="col-md-12">
          <p className="Bannerimg-head">
            Logo<span className="asterisk">*</span>
            <IoMdInformationCircleOutline size={16} color="black" title="Upload a logo image for your event, this is the first image attendees will see." />
          </p>
          <label className="bannerimg-div mt-2">
            <input
              className="d-none"
              type="file"
              name="logo"
              accept="image/*"
              onChange={handleInputChange}
              ref={refs?.logo}
            />
            <div className="upload-image-content">
              <img src={upload} alt="upload" />
              <p className="uploadimg-font">Upload Logo Image</p>
              <p className="img-format-text">Image format: .jpg or .png </p>
              <p className="img-format-text">Recommended size:w:274px, H:330px</p>
              {formData?.logo && (
                <FaRegCircleCheck color="green" size={20}/>
              )}
            </div>
          </label>
          {errors?.logo && <span className="error">{errors?.logo}</span>}
        </div>

        <div className="col-md-12">
          {formData?.logo && (
            <div className="mt-2">

              <div className="row mt-2 d-flex justify-content-center">
                {formData?.logo instanceof File ? (
                  <div className="col-6 position-relative">
                    <img className="event-logo-img"
                      src={URL.createObjectURL(formData?.logo)}
                      alt="updateimg" />
                    <button
                      className="image-remove-icon"
                      onClick={()=> handleDeleteImgDoc("","local","logo")}>
                      <MdDelete size={20} color="white" />
                    </button>
                  </div>
                ) : (
                  <div className="col-6 position-relative">
                    <img className="event-logo-img"
                      src={`${baseUrls?.logo_image_url}/${formData?.logo}`}
                      alt="updateimg" />

                    <button className="image-remove-icon"
                      onClick={()=> handleDeleteImgDoc("","local","logo")}>
                      <MdDelete size={20} color="white" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
    </>)
};

export default BannerLogoSection;