import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/SponsorRingUs-icon.svg";
import ForgotWithEmail from "../../components/ForgotPassword/ForgotWithEmail";
import ForgotWithMobile from "../../components/ForgotPassword/ForgotWithMobile";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NewPasswordForm from "../../components/ForgotPassword/NewPasswordForm";
import { SignupEmailOtpVerifyFinal } from "../../Services/Apis";
import { Email_ForgotVerify_OTP, Verify_ForgotNumber_OTP } from "../../utils/user/UserManager";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [mobileForm, setMobileForm] = useState({
    dialingcode: "",
    mobilenumber: "",
  });
  // OTP seaction state
  const [otpSection, setOtpSection] = useState(false);
  const [otpError,setOtpError]=useState("");
  const [forgotOption, setForgotOption] = useState("email");
 //new pass seaction state
  const [newPasswordSection, setNewPasswordSection] = useState(false);

  const [emailOtp, setEmailOtp] = useState("");
  const [verifiedEmailOtp, setVerifiedEmailOtp] = useState(false);
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);
  const [phoneOtp, setPhoneOtp] = useState("");


  const VerifyEmail = async (e) => {
    e.preventDefault(); 

   if(forgotOption == 'email'){
    if (!emailOtp || !/^[0-9]{6}$/.test(emailOtp)) {
      setOtpError('Invalid OTP. Please enter a 6-digit number.');
    } else {
    setOtpError("")
    const data = {
      email: email,
      otp: emailOtp,
    };
    setVerifyBtnLoading(true)
    try {
        Email_ForgotVerify_OTP(data).then((response) => {
          console.log("response",response)
        if (response?.data?.success) {
          setVerifiedEmailOtp(true);
          setNewPasswordSection(true);
          setOtpSection(false)
          setEmailOtp("");
          setPhoneOtp("")
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        } else {
          setEmailOtp("")
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        }
      });

    } catch (err) {
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
    }finally{
      setVerifyBtnLoading(false)
    }}

   }else {
  if (!phoneOtp || !/^[0-9]{6}$/.test(phoneOtp)) {
      setOtpError('Invalid OTP. Please enter a 6-digit number.');
    } else {
    setOtpError("")
    const data = {
      phone: mobileForm?.mobilenumber,
      country_code: mobileForm?.dialingcode,
      otp: phoneOtp,
    };
    setVerifyBtnLoading(true)
    try {
      Verify_ForgotNumber_OTP(data).then((response) => {
          // console.log("response",response)
        if (response?.data?.success) {
          setVerifiedEmailOtp(true);
          setNewPasswordSection(true);
          setOtpSection(false)
          setEmailOtp("");
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        } else {
          setEmailOtp("");
          //console.log("msg",response?.data?.message)
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        }
      });

    } catch (err) {
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
    }finally{
      setVerifyBtnLoading(false)
    }}
   }
  };

  const handleOtpChange=(e)=>{
    const otpValue = e.target.value;
    const regex = /^\d{0,6}$/;

    if (regex.test(otpValue)) {

      if(forgotOption == 'email'){
        setEmailOtp(otpValue);
      }else{
        setPhoneOtp(otpValue)
      }      
    }
  }

  return (
    <>
      <section className="forgotpassword-area">
       <ToastContainer/>
        <div className="login-signup-header">
          <Link to="/">
            <div className="w-100">
              <img src={logo} alt="logo" />{" "}
            </div>
          </Link>
        </div>

        <div className="d-table1">
          <div className="container-fluid">
            <div className="forgotpassword-form mt-5">
              <div className="d-flex justify-content-between mb-2">
               
                {otpSection ? (
                  <h3>Enter Your OTP</h3>
                  ) : newPasswordSection ? 
                  <h3>Enter New Password</h3> : (
                  <h3>Forgot Your Password</h3>
                 )}
              </div>

              <div className="d-flex gap-4">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="email"
                          disabled={otpSection || newPasswordSection}
                          checked={forgotOption == "email"}
                          name="forgotOption"
                          onChange={(e) => setForgotOption(e?.target?.value)}
                        />
                        Email</label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="mobile"
                          disabled={otpSection || newPasswordSection}
                          checked={forgotOption == "mobile"}
                          name="forgotOption"
                          onChange={(e) => setForgotOption(e?.target?.value)}
                        />
                        Mobile</label>
                    </div>
                  </div>

              <div className="">
                <div className="">
                {otpSection ? 
                <>
              <div className="">
              <label className="login-label mb-0">
              OTP <span className="asterisk">*</span>
            </label>
              <div className="position-relative d-flex align-items-center w-100 mt-2">
                <input
                  type="text"
                  value={forgotOption == 'email' ? emailOtp : phoneOtp}
                  name="emailOtp"
                  className="form-control w-100"
                  placeholder="OTP"
                  onChange={ handleOtpChange}
                />
                  <button
                    className="verify-otp"
                    type="button"
                    onClick={VerifyEmail}>
                    {
                      verifyBtnLoading &&
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    }
                    Verify
                  </button>
              </div>
               {otpError && <span className="" style={{ color: 'red', fontSize: '13px' }}>{otpError}</span>}
              </div>
            <button className="forgot-text go-back-btn mt-4" onClick={()=>setOtpSection(false)}>
              Back To Login
            </button>

                  </>
                    :
                     newPasswordSection ?      
                     <NewPasswordForm navigate={navigate} setOtpSection={setOtpSection} forgotOption={forgotOption} email={email} mobileForm={mobileForm}/>
                     :
                     forgotOption == "email" ?
                     <ForgotWithEmail setOtpSection={setOtpSection} setEmailOtp={setEmailOtp} email={email} setEmail={setEmail}/>
                      :
                     <ForgotWithMobile  mobileForm={mobileForm} setPhoneOtp={setPhoneOtp} setMobileForm={setMobileForm} setOtpSection={setOtpSection}/>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
