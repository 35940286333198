import { useState,useEffect} from "react";
import { IoClose } from "react-icons/io5";
import { Get_PublishData, PublishEvent } from "../../utils/myevent/MyEvent";
import { ToastContainer, toast } from "react-toastify";
import Loader2 from "../Loader/Loader2";
import moment from "moment";

const PublishEventPopUp = ({ event_Data, setPublishMdl, getMyEvent,profileData,setFeatureCardShow,
  setEvent_Data,setNearByCuttoffDateMdl,someFilterHide}) => {

  const getTodayDate = () => {
    return new Date().toISOString().split("T")[0];
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    published_date: getTodayDate(),
    published_end_date: "",
    published_start_time: getCurrentTime(),
    published_end_time: "",
  });
  const [errors, setErrors] = useState({});
  const [loading,setLoading]=useState(false)
  const [btnLoading, setBtnLoading] = useState(false);
  const today = new Date().toISOString().split("T")[0];

  const handleInputChange = (e) => {
   const {value,name} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const errors = {};
    if (!formData.published_date) {
      errors.published_date = "Publish Date is required";
    }
    if (!formData.published_end_date) {
      errors.published_end_date = "CutOff Date is required";
    }
    if (!formData.published_start_time) {
      errors.published_start_time = "Publish Time is required";
    }
    if (!formData.published_end_time) {
      errors.published_end_time = "CutOff Time is required";
    }
    return errors;
  };

  const GetPublishData=async()=>{
    setLoading(true)
     try{
      const response = await Get_PublishData(event_Data?.id);
      const data = response?.data?.data;
       setFormData(data)
       
     }catch(err){
      console.log("err",err)
     }
     finally{
      setLoading(false)
     }
  }

  useEffect(()=>{
    if(event_Data?.event_status == "modify"){
      GetPublishData();
    }
  },[]);

  const handlePublishEvent =async (e) => {
    e.preventDefault();
    const fData = {
      ...formData,
      event_id: event_Data?.id,
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setBtnLoading(true);
      const formattedDate = moment(formData?.published_end_date).toDate();
      setEvent_Data({...event_Data,published_end_date:formattedDate});
      try{
        const response = await PublishEvent(fData);
        setPublishMdl(false);
        if(event_Data?.event_status == "modify"){
          setNearByCuttoffDateMdl(false)
        }
        toast.success(response?.data?.message,{
         position: "top-center",
         autoClose: 3000,
         hideProgressBar:false,
         theme: "light",
        });
        getMyEvent();
        //setFeatureCardShow(true)
      }catch (err) {
        toast.error(err?.response?.data?.message,{
          position: "top-center",
          autoClose: 3000,
          hideProgressBar:false,
          theme: "light",
        });
      }finally {
        setBtnLoading(false);
      }
    }
  };
  return (<>
       <ToastContainer />
      <div className="warningModal">
        <div className="publishevent-mdl position-relative">
          <h4 className="">Publish Event</h4>
          <button className="terms-mdl-close-btn"
            onClick={() => setPublishMdl(false)}>
           <IoClose size={24}/>
          </button>
          <hr className="w-100" />
          <div className="publishevent-content mt-3">
            <h4 className="feature-card-heading">{event_Data?.title}</h4>
          {
            loading ? <Loader2/> :  
            <form onSubmit={handlePublishEvent}>
              <div className="row">
                <div
                  className="form-group col-6 col-md-6"
                  title="Specify the start date for the event">
                  <label className="form-label">
                    Publish Date<span className="asterisk">*</span>
                  </label>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      name="published_date"
                      value={formData.published_date}
                      onChange={handleInputChange}
                      onFocus={ (e) => e.currentTarget.showPicker()}
                      min={today}
                      max={someFilterHide ? "" : event_Data?.start_date}
                      disabled={!profileData?.is_premier && !profileData?.is_prime_user}
                    />
                  </div>
                  {errors.published_date && (
                    <span className="error">{errors.published_date}</span>
                  )}
                </div>

                <div
                  className="form-group col-6 col-md-6"
                  title="Specify the start time for the event">
                  <label className="form-label">
                    Publish Time<span className="asterisk">*</span>
                  </label>
                  <div>
                    <input
                      type="time"
                      className="form-control"
                      step="900"
                      pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                      name="published_start_time"
                      value={formData?.published_start_time}
                      onChange={handleInputChange}
                      disabled={!profileData?.is_premier && !profileData?.is_prime_user}
                      onFocus={(e) => e.currentTarget.showPicker()}
                    />
                  </div>
                  {errors.published_start_time && (
                    <span className="error">{errors.published_start_time}</span>
                  )}
                </div>

                <div className="form-group col-6 col-md-6"
                  title="Specify the end date for the event">
                  <label className="form-label">
                    CutOff Date<span className="asterisk">*</span>
                  </label>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      name="published_end_date"
                      disabled={!formData?.published_date}
                      value={formData?.published_end_date}
                      onChange={handleInputChange}
                      onFocus={(e) => e.currentTarget.showPicker()}
                      min={formData?.published_date ? formData?.published_date : today}
                      max={someFilterHide ? "" : event_Data?.start_date}
                    />
                  </div>
                  {errors.published_end_date && (
                    <span className="error">{errors.published_end_date}</span>
                  )}
                </div>

                <div className="col-6 col-md-6"
                  title="Specify the end time for the event">
                  <label className="form-label">
                    CutOff Time<span className="asterisk">*</span>
                  </label>
                  <div className=" ">
                    <input
                      type="time"
                      className="form-control"
                      step="900"
                      pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                      name="published_end_time"
                      value={formData.published_end_time}
                      onChange={handleInputChange}
                      onFocus={(e) => e.currentTarget.showPicker()}
                    />
                  </div>
                  {errors.published_end_time && (
                    <span className="error">{errors.published_end_time}</span>
                  )}
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <button
                  className="warning-mdl-btn mt-3"
                  type="submit">
                  {btnLoading && (
                     <div className="spinner-border text-light " role="status">
                       <span className="sr-only">Loading...</span>
                     </div>
                  )}
                  Submit
                </button>
              </div>
            </form>
          }
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishEventPopUp;
