
import axios from 'axios';
import {store} from '../store';
import { setSessionExpired } from '../redux/Auth/AuthCheck';
 
const Axiosapi = axios.create({
  baseURL: 'https://sponsorringus.com/admin/api/v1',
  // baseURL:"https://www.sponsorringus.com/backend/api/v1"
  // baseURL: 'https://ringus.idea2reality.tech/api/v1',
});

 Axiosapi.interceptors.request.use((config) => {
  const token = localStorage.getItem('LoginToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, 
  (error) => {
    return Promise.reject(error);
 }
);

 Axiosapi.interceptors.response.use((response) => {
    //If the response is successful, simply return it
    return response;
  },
  (error) => {
    // Check if the error response exists and has a status of 401
    if (error?.response && error?.response?.status == 401) {
        localStorage.removeItem('LoginToken'); // Remove the token
        //window.location.href = '/login'; // For simple redirect
        store.dispatch(setSessionExpired(true));
      }
    return Promise.reject(error);
  }
);

export default Axiosapi;